<template>
<div class="resume-dialog d-flex flex-row">
  <j-resume-form-helper class="d-flex flex-column he-100 wi-40 pr-2" title="Comment rédiger vos expériences ?" video="https://player.vimeo.com/video/775855154#t=2m32s" :content="helperContent" :index="carouselIndex"></j-resume-form-helper>
  <div class="d-flex flex-column he-100 wi-60 pl-2">
    <div class="experiences-carousel d-flex flex-column">
      <div class="mb-4 d-flex align-center">
        <img class="icon-img" src="/images/3d/briefcase.png" alt="malette"/>
        <h5 class="mb-0 ml-4 text-grey ">{{adding ? 'Ajout' : 'Modification'}} d'une de vos expériences :</h5>
      </div>
      <div class="pa-4 rounded-t-lg white text-grey flex-grow-1">
        <v-carousel touchless height="100%" v-model="carouselIndex" :show-arrows="false" :hide-delimiters="true">
          <v-carousel-item content-class="he-100 d-flex flex-column">
            <h4 class="mb-0 font-weight-bold text-grey">Quel est l'intitulé de ce poste ?*</h4>
            <p class="mb-0 text-caption">Ex: Chef de projet, Vendeur, ...</p>
            <div class="wi-80 mx-auto d-flex">
              <v-text-field class="wi-50 pr-2" v-model="experience.title" placeholder="Intitulé du poste" clearable hide-details></v-text-field>
              <v-select class="wi-50 pl-2" v-model="experience.contract" :items="lang.contractTypes" placeholder="Type de contrat (optionnel)" clearable hide-details></v-select>
            </div>
            <h4 class="mb-0 mt-8 font-weight-bold text-grey">Dans quelle entreprise ?*</h4>
            <p class="mb-0 text-caption">Ex: Orange, Hermès, ...</p>
            <v-text-field class="wi-80 mx-auto" v-model="experience.company" placeholder="Entreprise" clearable hide-details></v-text-field>
            <h4 class="mb-0 mt-8 font-weight-bold text-grey">Lieu où s'est déroulé l'expérience.</h4>
            <p class="mb-0 text-caption">Ex: Paris, États-Unis ...</p>
            <v-text-field class="wi-80 mx-auto" v-model="experience.location" placeholder="Ville ou pays" clearable hide-details></v-text-field>
            <h4 class="mb-0 mt-8 font-weight-bold text-grey">Secteur d'activité, chiffres clés, résultats significatifs, informations à communiquer au recruteur.</h4>
            <p class="mb-0 text-caption">Ex: - Chiffre d’affaires 2013 : +15% vs N-1 Meilleure progression France<br/>- CA Annuel : 100 millions d’€uros – Marché du Biscuit & de la confiserie ...</p>
            <v-text-field class="wi-80 mx-auto" v-model="experience.subtitle" placeholder="Infos clés (optionneles)" clearable hide-details></v-text-field>
          </v-carousel-item>
          <v-carousel-item content-class="d-flex flex-column">
            <h4 class="mb-0 font-weight-bold text-grey">Quelles missions avez-vous eu à effectuer ?</h4>
            <p class="mb-0 text-caption">Inspirez-vous de votre fiche de poste et utilisez des mots clés en lien avec l'annonce d'emploi. Essayez de les regrouper par catégorie.<br/>Ex: • Recrutement, formation et encadrement de l'équipe de vente dans ses missions quotidiennes (23 personnes : Chefs de Secteur , Promoteurs, Gestionnaires).<br/>• Définition et suivi des objectifs quantitatifs, qualitatifs et des plans commerciaux.</p>
            <j-resume-text-editor class="mt-4" :resumeEdit="this.resumeEdit" :itemIndex="this.itemIndex" section="experiences"></j-resume-text-editor>
          </v-carousel-item>
          <v-carousel-item content-class="d-flex flex-column">
            <h4 class="mb-0 font-weight-bold text-grey">Combien de temps avez-vous occupé ce poste ?*</h4>
            <v-checkbox v-model="experience.current" @change="setCurrent($event)" class="ml-2 checkbox-label" label="J'occupe actuellement ce poste" dense hide-details></v-checkbox>
            <div class="wi-80 mx-auto mt-4">
              <p class="mb-0 text-caption">Date de début :</p>
              <div class="d-flex">
                <v-select class="wi-50 pr-2" placeholder="Mois" :items="lang.months" item-text="txt" item-value="id" v-model="experience.begin.month" clearable dense hide-details>
                  <template v-slot:selection="{ item }">
                      {{item.txt[0].toUpperCase() + item.txt.substring(1)}}
                    </template>
                    <template v-slot:item="{ item }">
                      {{item.txt[0].toUpperCase() + item.txt.substring(1)}}
                    </template>
                </v-select>
                <v-select class="wi-50 pl-2" placeholder="Année" :items="years" v-model="experience.begin.year" clearable dense hide-details></v-select>
              </div>
              <div :class="[{'grey--text current-job':experience.current},'end-selects']">
                <p class="mb-0 text-caption mt-4">Date de fin :</p>
                <div class="d-flex">
                  <v-select :disabled="experience.current" class="wi-50 pr-2" placeholder="Mois" :items="lang.months" item-text="txt" item-value="id" v-model="experience.end.month" clearable dense hide-details>
                    <template v-slot:selection="{ item }">
                      {{item.txt[0].toUpperCase() + item.txt.substring(1)}}
                    </template>
                    <template v-slot:item="{ item }">
                      {{item.txt[0].toUpperCase() + item.txt.substring(1)}}
                    </template>
                  </v-select>
                  <v-select :disabled="experience.current" class="wi-50 pl-2" placeholder="Année" :items="years" v-model="experience.end.year" clearable dense hide-details></v-select>
                </div>
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
    </div>
    <div class="dialog-actions white rounded-b-lg pb-2 d-flex flex-column">
      <v-divider class="my-0" inner></v-divider>
      <v-carousel class="pa-4" touchless height="100%" v-model="carouselIndex" :show-arrows="false" :hide-delimiters="true">
        <v-carousel-item content-class="d-flex">
          <v-btn outlined class="ma-0 ml-auto primary--text j-btn" @click="carouselIndex++">vos missions<v-icon right>mdi-arrow-right</v-icon></v-btn>
        </v-carousel-item>
        <v-carousel-item content-class="d-flex">
          <v-btn outlined class="ma-0 mr-auto grey--text j-btn" @click="carouselIndex--"><v-icon left>mdi-arrow-left</v-icon>informations</v-btn>
          <v-btn outlined class="ma-0 ml-auto primary--text j-btn" @click="carouselIndex++">durée<v-icon right>mdi-arrow-right</v-icon></v-btn>
        </v-carousel-item>
        <v-carousel-item content-class="d-flex">
          <v-btn outlined class="ma-0 mr-auto grey--text j-btn" @click="carouselIndex--"><v-icon left>mdi-arrow-left</v-icon>vos missions</v-btn>
        </v-carousel-item>
      </v-carousel>
      <v-btn :disabled="!validate" @click="$emit('close', true)" depressed class="red-gradient white_ j-btn">valider</v-btn>
      <v-btn text @click="$emit('close', false)" class="mt-2 j-btn grey--text">annuler</v-btn>
    </div>
  </div>    
</div>
</template>

<script>
export default {
  props: ['resumeEdit','adding','itemIndex','lang'],
  name: 'resume-experiences',
  data() {
    return {
      carouselIndex: 0,
      helperContent: [
        '<h6 class="font-weight-bold text-grey">Voici les informations indispensables à renseigner :</h6><li>Toutes les expériences pro ont leur place sur le CV (stage, bénévolat, job étudiant, ...) si elles ont une utilité pour le poste visé.</li><li>Les classer par ord e chronologique (la plus récente en haut).</li><li>Vous pouvez détailler le type de contrat (alternance, stage, CDD  ...).</li><li>Ajouter le lieu où s’est déroulée l’expérience (idéal pour mettre en avant un côté international).</li>',
        '<h6 class="font-weight-bold text-grey">Voici quelques idées pour rédiger cette rubrique :</h6><li>Si vous avez occupé un poste dans une entreprise connue, utiliser du gras pour faire ressortir son nom sur le CV.</li><li>Garder la même logique de puces ou de rédaction pour chacune des expériences.</li><li>Utiliser des phrases courtes avec des verbes d’action idéalement en lien avec l’annonce.</li><li>Vos expériences et vos missions doivent avoir un lien avec le poste visé.</li><li>Indiquer uniquement des informations utiles pour le job ciblé.</li><li>Mettre des résultats pour vous mettre en avant (Chiffres , réalisations, ...).</li><li>Mettre en avant les compétences que vous avez développées par expérience.</li><li>Mettez en avant vos réalisations.</li><li>Inspirez-vous de vos fiches de poste ou de profils LinkedIn de personnes ayant occupé le même poste.</li>',
        '<h6 class="font-weight-bold text-grey">Concernant les dates :</h6><li>Ne pas oublier les dates du contrat.</li>'
      ]
    }
  },
  methods: {
    setCurrent(value) {
      if(value) this.experience.end = {month: '', year: ''}
    }
  },
  computed: {
    experience() {
      return this.resumeEdit.experiences[this.itemIndex]
    },
    validate() {
      return this.experience.title && this.experience.company && (this.experience.begin.month || this.experience.begin.year) && (this.experience.begin.month || this.experience.end.year || this.experience.current)
    },
    years() {
      const years = []
      for (let index = parseInt(new Date().getFullYear()); index >= 1950; index--) { years.push(index.toString()) }
      return years
    }
  }
}
</script>

<style lang="scss">
.experiences-carousel {
  height: 100%!important;
  padding-right: 16px;
  overflow-y: scroll;
  .v-window-item {
    display: flex;
    flex-direction: column;
    .v-image.v-responsive.v-carousel__item {
      height: auto!important;
      flex-grow: 1;
    }
  }
  .icon-img {
    height: 2rem;
  }
  .checkbox-label label {
    margin: 0;
  }
  .end-selects {
    transition: all ease 0.25s;
    &.current-job {
      opacity: 0.5;
    }
  }
}
</style>