<template>
<div class="resume-dialog d-flex flex-row">
  <j-resume-form-helper class="d-flex flex-column he-100 wi-40 pr-2" title="Comment bien rédiger vos compétences ?" video="https://player.vimeo.com/video/775855154#t=1m32s" :content="helperContent" :index="carouselIndex"></j-resume-form-helper>
  <div class="d-flex flex-column he-100 wi-60 pl-2">
    <v-carousel class="skills-form" height="100%" touchless v-model="carouselIndex" :show-arrows="false" :hide-delimiters="true">
      <v-carousel-item content-class="he-100 d-flex flex-column">
        <div class="mb-4 d-flex align-center">
          <img class="icon-img" :src="`/images/3d/skills.png`" alt="Skills"/>
          <h5 class="mb-0 ml-4 text-grey">Modifier vos compétences :</h5>
        </div>
        <div class="pa-4 rounded-t-lg white text-grey d-flex flex-column flex-grow-1">
          <h4 class="mb-0 font-weight-bold text-grey">Personnalisez ici vos compétences en lien avec l'annonce d'emploi.</h4>
          <p class="mb-0 text-caption">Privilégiez ici des hard skills. Ex: Contact client, Management, ...</p>
          <div class="skills-section d-flex flex-column mt-4 wi-80 mx-auto ">
            <p class="mb-0 text-caption">{{resumeEdit.skills.length}}/6 compétences maximum</p>
            <draggable v-model="resumeEdit.skills" handle=".handle">
              <div class="skills-item elevation-2 white mt-2 px-1 d-flex align-center rounded-lg" v-for="(skill, index) in resumeEdit.skills" :key="index">
                <v-icon class="handle cursor-pointer hover-opacity">mdi-arrow-up-down-bold</v-icon>
                <v-divider class="mx-1" vertical></v-divider>
                <div class="wi-100">
                  <v-text-field :ref="`input_${index}`" v-model="resumeEdit.skills[index].title" class="mx-4" placeholder="Compétence" dense hide-details></v-text-field>
                  <v-slider v-model="resumeEdit.skills[index].score" class="mx-2 mt-2" min="0" max="100" step="5" thumb-label color="primary" thumb-color="primary" track-color="lightgrey" hide-details></v-slider>
                </div>
                <v-divider class="mx-1" vertical></v-divider>
                <v-icon @click="removeItem(index)" color="alert">mdi-delete</v-icon>
              </div>
            </draggable>
            <v-btn v-if="resumeEdit.skills.length < 6" @click="pushItem()" depressed small class="secondary j-btn mt-4">ajouter</v-btn>
          </div>
        </div>
      </v-carousel-item>
      <v-carousel-item content-class="he-100 d-flex flex-column">
        <div class="mb-4 d-flex align-center">
          <img class="icon-img" :src="`/images/3d/computings.png`" alt="Computings"/>
          <h5 class="mb-0 ml-4 text-grey">Modifier vos compétences informatiques :</h5>
        </div>
        <div class="pa-4 rounded-t-lg white text-grey d-flex flex-column flex-grow-1">
          <h4 class="mb-0 font-weight-bold text-grey">Afficher les compétences informatiques sur votre CV ?</h4>
          <p class="mb-0 text-caption">Si vous possédez des compétences dans le domaine de l'informatique activez ce bouton. </p>
          <div class="d-flex align-center pr-2">
            <p class="mb-0 mr-2">Afficher les compétences informatiques :</p>
            <v-switch v-model="resumeEdit.style.tech"></v-switch>
          </div>
          <v-expand-transition>
            <div v-if="resumeEdit.style.tech">
              <h4 class="mb-0 font-weight-bold text-grey">Quelles sont vos compétences en informatique ?</h4>
              <p class="mb-0 text-caption">Si vous en avez plusieurs, essayez de les regrouper.<br/>Ex: Développement: html, css. Ventes: Salesforce, ... </p>
              <div class="computings-section d-flex flex-column mt-4">
                <VueSlickCarousel class="carousel-skills" :arrows="true" :initialSlide="0" :key="resumeEdit.computings.length" :draggable="false" :infinite="false" :slidesToShow="2" :slidesToScroll="1" :dots="false">
                  <div class="d-flex" v-for="(group, gIndex) in resumeEdit.computings" :key="gIndex">
                    <div class="pa-2 d-flex flex-column flex-grow-1">
                      <div class="group-header elevation-2 rounded-lg pa-2 pb-4">
                          <span class="text-caption">Catégorie {{gIndex+1}} :</span>
                        <div class="d-flex">
                          <v-text-field v-model="resumeEdit.computings[gIndex].groupTitle" placeholder="Graphisme / Développement (optionnel)" dense hide-details></v-text-field>
                          <v-icon v-if="resumeEdit.computings.length > 1" @click="$store.dispatch('open_dialog',{message:'Voulez-vous vraiment supprimer cette catégorie de compétences ?',button:'Supprimer',color:'BGalert',alt:'Annuler',cb:()=>{removeItem(gIndex)}})" color="alert">mdi-delete</v-icon>
                        </div>
                      </div>
                      <span class="mt-4 text-caption">Compétences :</span>
                      <p v-if="!resumeEdit.computings[gIndex].groupSkills.length" class="ma-0 text-caption grey--text">Aucune compétence</p>
                      <draggable v-else v-model="resumeEdit.computings[gIndex].groupSkills" handle=".handle">
                        <div class="computings-skill elevation-2 white mt-2 px-1 d-flex align-center rounded-lg" v-for="(skill, index) in resumeEdit.computings[gIndex].groupSkills" :key="index">
                          <v-icon class="handle cursor-pointer hover-opacity">mdi-arrow-up-down-bold</v-icon>
                          <v-divider class="mx-1" vertical></v-divider>
                          <div class="skill-form flex-grow-1">
                            <v-text-field v-model="resumeEdit.computings[gIndex].groupSkills[index].title" class="mx-4" :ref="`input_${gIndex}${index}`" placeholder="Office / CRM (nom de logiciel)" dense hide-details></v-text-field>
                            <v-slider v-model="resumeEdit.computings[gIndex].groupSkills[index].score" class="mx-2 mt-2" min="0" max="100" step="5" thumb-label color="primary" thumb-color="primary" track-color="lightgrey" hide-details></v-slider>
                          </div>
                          <v-divider class="mx-1" vertical></v-divider>
                          <v-icon @click="removeGroup(gIndex, index)" color="alert">mdi-delete</v-icon>
                        </div>
                      </draggable>
                      <v-btn @click="pushGroup(gIndex)" depressed small class="mt-4 j-btn secondary">ajouter</v-btn>
                    </div>
                    <v-divider class="my-4 mx-2" vertical></v-divider>
                  </div>
                  <div class="pa-2">
                    <v-btn @click="pushItem()" class="add-group elevation-2 white text-grey rounded-lg"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                  </div>
                </VueSlickCarousel>
              </div>
            </div>
          </v-expand-transition>
        </div>
      </v-carousel-item>
      <v-carousel-item content-class="he-100 d-flex flex-column">
        <div class="mb-4 d-flex align-center">
          <img class="icon-img" :src="`/images/3d/graphic.png`" alt="Graphic"/>
          <h5 class="mb-0 ml-4 text-grey">Modifier l'affichage de vos compétences :</h5>
        </div>
        <div class="pa-4 rounded-t-lg white text-grey d-flex flex-column flex-grow-1">
          <h4 class="mb-0 font-weight-bold text-grey">Ce titre de section vous convient ?</h4>
          <v-text-field v-model="resumeEdit.style.computingsName" class="mx-4 flex-grow-0 mt-4" :placeholder="lang.computings" dense hide-details></v-text-field>
          <h4 class="mb-0 mt-8 font-weight-bold text-grey">Quel style d'infographie préférez-vous pour vos compétences ?</h4>
          <VueSlickCarousel class="carousel-skills mt-4" :arrows="true" :initialSlide="initialSlide" :key="styles.length" :draggable="false" :infinite="false" :slidesToShow="3" :slidesToScroll="1" :dots="false">
            <div class="pa-4" v-for="(style, index) in styles" @click="resumeEdit.style.skills = style.slug" :key="index">
              <div class="style-card elevation-1 rounded-lg">
                <div :class="[{'BGLight-primary':index===activeStyle},'d-flex justify-center py-2']">
                  <v-icon left>{{index===activeStyle?'mdi-radiobox-marked':'mdi-radiobox-blank'}}</v-icon>
                  <p class="font-weight-bold ma-0">{{style.text}}</p>
                </div>
                <img class="wi-100" :src="`/images/resumes/${style.slug}.png`" :alt="style.text"/>
              </div>
            </div>
          </VueSlickCarousel>
          <h4 class="mb-0 mt-8 font-weight-bold text-grey">Et pour les compétences informatiques ?</h4>
          <div class="px-12 mt-4 mx-auto d-flex justify-space-between">
            <div class="pa-4" v-for="(style, index) in computingsStyles" @click="resumeEdit.style.computings = style.slug" :key="index">
              <div class="style-card elevation-1 rounded-lg">
                <div :class="[{'BGLight-primary':index===activeStyleComputings},'d-flex justify-center py-2']">
                  <v-icon left>{{index===activeStyleComputings?'mdi-radiobox-marked':'mdi-radiobox-blank'}}</v-icon>
                  <p class="font-weight-bold ma-0">{{style.text}}</p>
                </div>
                <img class="wi-100" :src="`/images/resumes/${style.slug}.png`" :alt="style.text"/>
              </div>
            </div>
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>
    <div class="dialog-actions white rounded-b-lg pb-2 d-flex flex-column">
      <v-divider class="my-0" inner></v-divider>
      <v-carousel class="pa-4" touchless height="100%" v-model="carouselIndex" :show-arrows="false" :hide-delimiters="true">
        <v-carousel-item content-class="d-flex">
          <v-btn outlined class="ma-0 ml-auto primary--text j-btn" @click="carouselIndex++">Compétences informatiques<v-icon right>mdi-arrow-right</v-icon></v-btn>
        </v-carousel-item>
        <v-carousel-item content-class="d-flex">
          <v-btn outlined class="ma-0 mr-auto grey--text j-btn" @click="carouselIndex--"><v-icon left>mdi-arrow-left</v-icon>Compétences</v-btn>
          <v-btn outlined class="ma-0 ml-auto primary--text j-btn" @click="carouselIndex++">Style<v-icon right>mdi-arrow-right</v-icon></v-btn>
        </v-carousel-item>
        <v-carousel-item content-class="d-flex">
          <v-btn outlined class="ma-0 mr-auto grey--text j-btn" @click="carouselIndex--"><v-icon left>mdi-arrow-left</v-icon>Compétences informatiques</v-btn>
        </v-carousel-item>
      </v-carousel>
      <v-btn @click="$emit('close', true)" depressed class="red-gradient white_ j-btn">valider</v-btn>
      <v-btn text @click="$emit('close', false)" class="mt-2 j-btn grey--text">annuler</v-btn>
    </div>
  </div>
</div>
</template>

<script>
import draggable from 'vuedraggable'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'resume-form-skills',
  props: ['resumeEdit','indexProps','styleInit','lang'],
  components: {
    draggable,
    VueSlickCarousel
  },
  data() {
    return {
      carouselIndex: 0,
      styles: [
        {text: 'Circulaire', slug: 'circular'},
        {text: 'Linéaire', slug: 'linear'},
        {text: 'Points', slug: 'dot'},
        {text: 'Aucun', slug: 'none'}
      ],
      newSkill: {
        title: '',
        score: 5
      },
      helperContent: [
        '<h6 class="font-weight-bold text-grey">Compétences :</h6><li>Changez les compétences à chacune de vos candidatures afin de les adapter à l’annonce d’emploi.</li><li>Aidez vous de l’annonce d’emploi pour comprendre quelles sont les compétences clé qui sont recherchées par le recruteur.</li><li>Indiquez des compétences professionnelles. (hard skill)</li><li>Exemples : management d’équipe, gestion de projet, marketing digital, ...</li><li>Vous pouvez aussi utiliser des compétences personnelles. (soft skill)</li><li>Exemples : excellent relationnel, force de proposition, organisé, ...</li><li>Les compétences doivent rester synthétiques et courtes.</li><li>Utilisez des diagrammes ou autres pour attirer l’œil du recruteur vers vos compétences.</li><li>Si vous utilisez un diagramme, ne jamais trop vous valoriser ni vous dévaloriser.</li><li>N’inventez pas de compétences.</li>',
        '<h6 class="font-weight-bold text-grey">Informatique :</h6><li>Posez-vous la question de l’utilité ou non de cette rubrique en fonction des postes visés.</li><li>Elle n’est pas obligatoire.</li><li>Indiquez les noms des logiciels (cités dans l’annonce d’emploi) que vous avez déjà utilisés.</li><li>Il ne sert à rien d’indiquer des logiciels qui ne serviront pas dans le futur job.</li><li>Ne mentez pas sur votre niveau d’expertise. Lorsque vous incluez des compétences informatiques sur votre CV, assurez vous d’être précis quant à votre niveau de compétence. Si vous surestimez votre niveau de compétence, cela pourrait se retourner contre vous lors d’un entretien d’embauche ou dans votre travail.</li><li>Vous pouvez indiquer des logiciels de bureautique, graphisme, CRM, cloud, site internet, intelligence artificielle, logiciels spécifiques à un secteur d’activité.</li><li>Vous pouvez suivre des MOOC ou des formations gratuites sur Internet pour vous pré-former à un logiciel cité dans l’annonce.</li>',
        '<h6 class="font-weight-bold text-grey">Style :</h6><li>Privilégiez l’affichage linéaire ou via les points si vos compétences se rédigent en plus d’une quinzaine de caractères.</li>'
      ]
    }
  },
  created() {
    this.carouselIndex = this.indexProps
  },
  methods: {
    pushItem() {
      if(this.isComputings) {
        this.resumeEdit.computings.push({groupeTitle: '', groupSkills: []})
      }
      else {
        this.resumeEdit.skills.push({ title: '', score: 5 })
        setTimeout(() => { this.$refs[`input_${this.resumeEdit.skills.length-1}`][0].focus() }, 100)
      }
    },
    pushGroup(index) {
      this.resumeEdit.computings[index].groupSkills.push({ title: '', score: 5 })
      setTimeout(() => { this.$refs[`input_${index}${this.items[index].groupSkills.length-1}`].focus() }, 100)
    },
    removeItem(index) {
      if(!this.isComputings || this.items.length > 1) this.items.splice(index, 1)
    },
    removeGroup(groupIndex, index) {
      this.resumeEdit.computings[groupIndex].groupSkills.splice(index, 1)
    }
  },
  computed: {
    initialSlide() {
      const index = this.styles.findIndex(style => {return style.slug === this.styleInit})
      if(index === 3) return 1
      if(index === 0) return 0
      else return index -1
    },
    activeStyle() {
      return this.styles.findIndex((style) => {return style.slug === this.resumeEdit.style.skills})
    },
    activeStyleComputings() {
      return this.computingsStyles.findIndex((style) => {return style.slug === this.resumeEdit.style.computings})
    },
    computingsStyles() {
      const newStyles = JSON.parse(JSON.stringify(this.styles))
      newStyles.shift()
      return newStyles
    },
    isComputings() {
      return this.carouselIndex === 1
    },
    items() {
      return this.resumeEdit[this.isComputings ? 'computings' : 'skills']
    }
  }

}
</script>

<style lang="scss">
.skills-form {
  height: 100%!important;
  padding-right: 16px;
  overflow-y: scroll;
  .v-window-item {
    display: flex;
    flex-direction: column;
    .v-image.v-responsive.v-carousel__item {
      height: auto!important;
      flex-grow: 1;
    }
  }
  .v-slider .v-slider__thumb-label {
    color: white!important;
  }
  .style-card {
    cursor: pointer;
    overflow: hidden;
    transition: transform 0.25s ease;
    &:hover {
      transform: translateY(-8px);
    }
  }
  .skills-section {
    min-height: 20vh;
    .skills-item {
      border: rgba(100, 100, 100, 0.1) 1px solid;
    }
    label {
      margin-bottom: 0!important;
      margin-right: 2px!important;
    }
  }
  .computings-section {
    min-height: 20vh;
    .computings-group {
        border: rgba(100, 100, 100, 0.1) 1px solid;
    }
    .add-group {
      min-width: 10rem;
    }
    .computings-skill {
      .theme--light.v-divider {
        border-color: rgba(100, 100, 100, 0.1);
      }
    }
  }
  .icon-img {
    height: 2rem;
  }
  .carousel-skills {
    position: relative;
    padding: 0 3rem;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    .group-header {
      .v-icon {
        opacity: 0;
      }
      &:hover .v-icon {
        opacity: 1;
      }
    }
    @include phone{
      padding: 0;
      transform: scale(0.8);
      overflow: visible;
    }
    .slick-track{
      display: flex;
    }
    .slick-slide{
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      height: auto;
      >div{
        display: flex;
        width: 100%;
      }
      @include phone{
        padding: 0.5em;
      }
    }
    .slick-arrow{
      position: absolute;
      left:12px;
      top:40%;
      transform: translate(42%,-50%) rotate(-45deg);
      background: transparent;
      border-radius: 0;
      color:transparent;
      height: 1rem;
      width: 1rem;
      padding: 0;
      font-size: 0;
      transition: all ease 0.3s;
      &.slick-disabled{
        display: none!important;
      }
      @include phone{
        left: 0;
        height:1rem;
        width:1rem;
        top: 50%;
        transform: translate(-150%,-50%) rotate(-45deg);
      }
      &:before{
        content: "";
        display: block;
        position: absolute;
        left:50%;
        top: 50%;
        transform: translate(-57%,-57%);
        height: 200%;
        width: 200%;
        background: rgba(grey,0.4);
        border-radius: 100%;
        @include phone{
          background: rgba(grey,0.4);
        }
      }
      &:after{
        content: "";
        display: block;
        position: absolute;
        left:50%;
        top: 50%;
        transform: translate(-50%,-50%);
        height: 100%;
        width: 100%;
        border: white solid 3px;
        border-bottom: none;
        border-right: none;
      }
      &:hover{
        @include noPhone{
          transform: translate(42%,-50%) rotate(-45deg) scale(1.1);
        }
      }
    }
    .slick-next{
      left: auto;
      right: 12px;
      transform: translate(-42%,-25%) rotate(135deg);
      @include phone{
        right: 0;
        transform: translate(150%,-50%) rotate(135deg);
      }
      &:hover{
        @include noPhone{
          transform: translate(-42%,-25%) rotate(135deg) scale(1.1);
        }
      }
    }
  }
}
</style>