<template>
    <div class="d-flex flex-column flex-grow-1">
            <p>Quelle formule d'abonnement souhaitez-vous ?</p>
            <v-select 
                v-if="!selectedPlan" 
                class="mb-4"
                solo rounded
                hide-details
                :items="plansFilt" 
                v-model="selectedPlan" 
                item-text="text"
                item-value="plans.default"
                return-object
                label="Choix de formule" :disabled="selectedPlan"  
            >
            </v-select>
            <v-text-field v-else class="mb-4" disabled hide-details dense :value="selectedPlan.text"></v-text-field>
        <div class="flex-grow-1"></div>
        <v-btn :disabled="!selectedPlan" @click="reSubscription(selectedPlan)" class="red-gradient j-btn v-size--large white--text ma-auto">Commencer le coaching</v-btn>
    </div>
</template>

<script>
import {plans} from '@/payment/plans/plans.js'

export default {
    name: 'subscriptions-choice',
    props:['reSubscription','currency'],
    data() {
        return {
            selectedPlan: null
        }
    },
    computed: {
        plansFilt() {
            return plans.filter((obj) => obj.currency === this.currency)
        }
    }
}
</script>

<style lang="scss">

</style>