<template>
  <div id="resume" class="resume-v4 white elevation-1 mx-auto my-8 d-flex flex-column" >
    <div class="resume-header d-flex wi-100">
      <div :style="`padding: ${resume.style.headerSize/2}px 0;`" class="wi-35 resume-profile-container grey-bg-color d-flex align-center">
        <j-resume-profile class="wi-100 my-4 second-bg-color" :resume="resume" :name="false" :size="100" background></j-resume-profile>
      </div>
      <div :style="`padding: ${resume.style.headerSize/2}px 0;`" class="wi-65 resume-profile-container d-flex">
        <j-resume-profile class="flex-grow-1 my-4 main-bg-color" :resume="resume" :profile="false" background></j-resume-profile>
      </div>
      <j-resume-header-resize :resume="resume"></j-resume-header-resize>
    </div>
    <div class="resume-content d-flex flex-grow-1">
      <div class="resume-lean grey-bg-color wi-35">
        <j-resume-details :resume="resume" transparent border></j-resume-details>
        <j-resume-lean :lang="lang" :resume="resume" titleClass="main-color" divider dividerClass="main-bg-color"></j-resume-lean>
      </div>
      <div class="resume-large wi-65">
        <div :class="[{'flex-column-reverse align-self-start':resume.style.educationsFirst},'content-container d-flex flex-column']">
          <div class="wi-100 experiences-container">
            <div class="resume-title main-color d-flex align-center">
              <p>{{lang.experiences}}</p>
              <div class="resume-divider main-bg-color"></div>
              <v-btn @click="$emit('changeOrderLarge')" small class="btn-first j-btn white">{{resume.style.educationsFirst?'Monter':'Descendre'}}<v-icon right>mdi-arrow-{{resume.style.educationsFirst?'up':'down'}}</v-icon></v-btn>
            </div>
            <j-resume-timeline :resume="resume" section="experiences" :lang="lang"></j-resume-timeline>
          </div>
          <div class="wi-100 educations-container">
            <div class="resume-title main-color d-flex align-center">
              <p>{{lang.educations}}</p>
              <div class="resume-divider main-bg-color"></div>
              <v-btn @click="$emit('changeOrderLarge')" small class="btn-first j-btn white">{{resume.style.educationsFirst?'Descendre':'Monter'}}<v-icon right>mdi-arrow-{{resume.style.educationsFirst?'down':'up'}}</v-icon></v-btn>
            </div>
            <j-resume-timeline :resume="resume" section="educations" :lang="lang"></j-resume-timeline>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['resume','lang'],
  name: 'resume-v_4'
}
</script>

<style lang="scss">
#resume.resume-v4 {
  .resume-header .resume-profile {
    .resume-picture {
      &:not(.loading-image) {
        overflow: visible!important;
      }
      transform: scale(1.5);
      .v-icon {
        font-size: 24px!important;
      }
    }
  } 
  .resume-large {
    padding: 0 16px;
    .resume-divider {
      flex-grow: 1;
      height: 2px;
      margin-left: 8px;
    }
  }
  .resume-lean {
    .resume-title {
      text-align: center;
    }
  }
}
</style>