<template>
    <div class="quiz">
        <div class="paging">
            <div class="d-flex" >
                <span>{{activeQ+1}}</span>/{{randomizedQuestions.length}}                
            </div>
            <div class="dots">
                <span v-for="(question,i) in randomizedQuestions" :key="i" :class="result[i]" ></span>
            </div>
        </div>
        <b-carousel v-model="activeQ" id="question-carousel" ref='carousel' :interval="0">
            <b-carousel-slide  v-for="(question,i) in randomizedQuestions" :key="question.question" >
                <j-question :scroll="scroll" :question="question" :lastQuestion="i==(randomizedQuestions.length - 1)" @end="handleQuestionEnd" >
                </j-question>
            </b-carousel-slide>
        </b-carousel>
    </div>
</template>

<script>
import Vue from 'vue'
import { CarouselPlugin } from 'bootstrap-vue'
Vue.use(CarouselPlugin)

export default {
  name: 'quiz',
  components: {
  },
  data(){
      return {
          activeQ : 0,
          result:[]
      }
  },
  props:['questions', 'scroll'],
  mounted(){
  },
  methods:{
    handleQuestionEnd: function(result){
      this.result.push(result)
      if(this.activeQ < this.randomizedQuestions.length - 1){
          this.$refs.carousel.next()
      }else{
          this.$emit('end', this.result)
      }
    },
    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          const obj = array[i]
          array[i] = array[j]
          array[j] = obj
      }
      return array
    }
  },
  computed: {
    randomizedQuestions() {
      let randomizedQ = this.shuffle(this.questions)
      randomizedQ.map(q => { 
        const randomAnswers = this.shuffle(q.answers)
        return randomAnswers
      })
      return randomizedQ
    }
  }
}
</script>

<style lang="scss">
.quiz{
    background: white;
    padding: 0.8em;
    border-radius: 1em;
    box-shadow: 0 0 5px 0px rgba(0,0,0,0.1);
    position: relative;
    .paging{
        position: absolute;
        right: 1em;
        top: 0.5em;
        color: black;
        @include phone{
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            padding-left: 1em;
            margin-bottom: 1em;
        }
        span{
            font-weight: 800;
            font-size: 2em;
            @include phone{
                font-size: 1em;
            }
        }
    }
    .dots{
        $dots : 0.5rem;
        $dotsMargin : 0.1rem;
        $numberDots : 5;
        display: flex;
        flex-wrap: wrap;
        width: calc(#{$numberDots} * #{$dots} + #{$numberDots} * #{$dotsMargin} );
        @include phone{
            width: 50%;
            align-items: center;
        }
        span{
            height: $dots;
            width: $dots;
            background: lightgray;
            border-radius: 100%;
            margin: $dotsMargin;
            @include phone{
                height: 0.6em;
                width: 0.6em;
                margin: 0.15em;
            }
            &.OK{
                background: $primary;
            }
            &.KO{
                background:$alert;
            }
            &.INCOMPLETE{
                background:gold;
            }
        }
    }
}

</style>
