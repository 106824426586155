<template>
  <div class="align-center">
    <p class="d-none d-sm-block mb-4">Votre progression dans {{isJourney?'le parcours':'la formation'}} :</p>
    <v-progress-linear v-if="$vuetify.breakpoint.smAndDown" class="mb-2 rounded-pill" :value="percentage" color="primary" height="20" >
      <template v-slot:default="{ value }">
          <p class="ma-0 caption white--text">{{ Math.ceil(value) }}{{isJourney?'% du parcours':'% de la formation'}}</p>
      </template>
    </v-progress-linear>
    <div v-else-if="isJourney" class="nav-progress pt-4">
      <p class="ma-0 text-center font-weight-bold nav-hover-title">{{hoverItemCached}}</p>
      <v-slide-group v-model="activeItem" show-arrows center-active>
        <div class="d-flex" v-for="(step, stIndex) in course.timeline" :key="step._id">
          <v-slide-item>
            <div :class="[{'current':timelineIndex===stIndex},isStepDone(step)?'BGLight-primary':'BGlightgrey-opacity','nav-step my-auto pa-2']" @mouseover="hoverItemCached = step.title || `${stIndex+1}${stIndex===0?'ère':'ème'} étape`" @mouseout="hoverItemCached = null">
              <v-icon :color="isStepDone(step)?'current':'transparent'">mdi-check</v-icon>
            </div>
          </v-slide-item>
          <div :class="[{'done':isStepDone(step)},'nav-timeline px-2 py-1 my-auto']"></div>
          <v-slide-item v-for="session in step.sessions" :key="session._id">
            <div class="d-flex">
              <router-link :class="[{'done':session.state === 'done', 'current': session._id === current, 'active-cursor': session._id === current && hoverItemCached === null },'nav-session my-auto']" @mouseover.native="hoverItemCached = session.title" @mouseout.native="hoverItemCached = null" :to="`/journeys/${course._id}/sessions/${session._id}`">
                <v-btn  small icon class="nav-btn"><v-icon v-if="session.state === 'done'">mdi-check</v-icon></v-btn>
              </router-link>
              <div :class="[{'done':session.state === 'done'},'nav-timeline px-2 py-1 my-auto']"></div>
            </div>
          </v-slide-item>
          <v-slide-item v-if="step.quiz && step.quiz.length">
            <router-link :class="[{'failed':!quizAvarage(step) && typeof step.quiz_result === 'number', 'done':quizAvarage(step), 'cursor-initial': !quizAccess(step)},'nav-quiz my-auto']" @mouseover.native="hoverItemCached = `Évaluation de fin d'étape`" @mouseout.native="hoverItemCached = null" :to="quizAccess(step) ? `/journeys/${course._id}/quiz/${step._id}` : ''">
              <v-btn small icon class="nav-btn"><v-icon>mdi-bullseye</v-icon></v-btn>
            </router-link>
          </v-slide-item>
          <div v-if="step.quiz && step.quiz.length && stIndex+1 !== course.timeline.length" :class="[{'failed':!quizAvarage(step) && typeof step.quiz_result === 'number', 'done':quizAvarage(step)},'nav-timeline px-2 py-1 my-auto']"></div>
        </div>
      </v-slide-group>
    </div>
    <div v-else class="nav-progress pt-4">
      <p class="ma-0 text-center font-weight-bold nav-hover-title">{{hoverItemCached}}</p>
      <v-slide-group show-arrows>
        <v-slide-item v-for="(session, index) in course.list_of_sessions" :key="session._id">
          <div class="d-flex">
            <router-link :class="[{'done':sessionState(session._id), 'current': session._id === current },'nav-session my-2']" @mouseover.native="hoverItemCached = session.title" @mouseout.native="hoverItemCached = null" :to="`/courses/${course._id}/sessions/${session._id}`">
              <v-btn small icon class="nav-btn"><v-icon>mdi-check</v-icon></v-btn>
            </router-link>
            <div v-if="index+1 !== course.list_of_sessions.length" :class="[{'done':sessionState(session._id)},'nav-timeline px-2 py-1 my-auto']"></div>
          </div>
        </v-slide-item>
      </v-slide-group>
    </div>
  </div>
</template>

<script>
import { quizCatchup } from '@/../config'

export default {
  name: 'session-nav-bar',
  components: {
  },
  data(){
    return {
        currentIndex: 0,
        hoverItem: null
    }
  },
  props:['course','current','isJourney','timelineIndex','percentage'],
  mounted(){
    const all_sessions = this.course.list_of_sessions
    this.currentIndex = all_sessions.findIndex(e=>{return e._id === this.current})
  },
  methods: {
    quizAvarage(step) {
      if(step.quiz_result/step.quiz.length*100 >= 50) return true
      else return false
    },
    isStepDone(step) {
      const sessionsDone = step.sessions.filter(s=>s.state === 'done')
      return sessionsDone.length === step.sessions.length
    },
    sessionState(id) {
      const sessions_done = this.$store.state.user.profile.history.sessions_done
      const sessionIndex = sessions_done.findIndex(el=>{return el._id == id})
      return sessionIndex !== -1
    },
    quizAccess(step) {
      if(quizCatchup) return true
      else return typeof step.quiz_result !== 'number' 
    }
  },
  computed: {
    activeItem() {
      let navItems = []
      for (const step of this.course.timeline) {
        navItems.push({_id: step._id})
        navItems = navItems.concat(step.sessions)
        if(step.quiz && step.quiz.length) navItems.push(step.quiz)
      }
      return navItems.findIndex(item => {return item._id === this.current})
    },
    hoverItemCached: {
      get() {
        return this.hoverItem
      },
      set(val) {
        this.hoverItem = val
      }
    }
  }
}
</script>

<style lang="scss">
.nav-progress {
  width: 100%;
  position: relative;
  .nav-hover-title {
    position: absolute;
    top: -12px;
    left: 0;
    right: 0;
    text-align: center;
  }
  .v-slide-group__content {
      justify-content: center;
  }
  .v-slide-group__prev, .v-slide-group__prev--disabled, .v-slide-group__next, .v-slide-group__next--disabled {
    min-width: 0;
  }
  .nav-timeline {
    background:lighten($grey,30%);
    &.done {
      background: $pastelGreen;
    }
    &.failed {
      background: gold;
    }
  }
  .nav-step.current, .nav-session.current .nav-btn {
    border: 2px solid darken($primary, 5);
  }
  .nav-step {
    border-radius: 10em;
    border: 2px solid $grey;
  }
  .nav-quiz.cursor-initial .nav-btn {
    cursor: initial;
  }
  .nav-session, .nav-quiz {
    position: relative;
    &.done .nav-btn {
      background: $pastelGreen;
    }
    &.failed .nav-btn {
      background: gold;
    }
    .nav-btn {
      background:lighten($grey,30%);
      transform: scale(1.05);
    }
    &:hover:not(.cursor-initial) .nav-btn {
      transform: scale(1.2);
    }
    &.current:before {
      border-color: transparent transparent $primary transparent;
    }
    &:hover:before, &.active-cursor:before {
      display: block;
    }
    &:before{
        z-index: 1;
        content: "";
        position: absolute;
        display: none;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0.4em 0.4em 0.4em ;
        border-color: transparent transparent $textGrey transparent;
        top: -10px;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translate(-50%,0);
        transform: rotate(180deg);
    }
  }
}
</style>