<template>
    <div :class="['card-offers text-grey',selected === offer._id&&'selected']" @click="selected===offer._id?$emit('selection', null):$emit('selection',offer._id)">
        <div class="d-flex front">
            <div @click.stop="" class="infos-section wi-70 wi-sm-60 pa-5 d-flex flex-column justify-center">
            <div class="infos-border pa-2">
                <h5 class="mb-4 font-weight-bold text-uppercase">{{offer.company}}</h5>
                <p class="mb-4"><v-icon color="grey lighten-3" class="mr-2 mb-1">mdi-briefcase</v-icon>{{offer.sector}}</p>
                <p class="mb-0"><v-icon color="grey lighten-3" class="mr-2 mb-1">mdi-calendar</v-icon>{{dateFormat(offer.date)}}</p>
            </div>
            </div>
            <div class="card-color clickable-section cursor-pointer wi-30 wi-sm-40 pa-5 d-flex flex-column justify-center">
            <v-icon color="white">mdi-eye</v-icon>
            <span class="white_ text-center">VOIR LE CONTACT</span>
            </div>
        </div>
        <div class="d-flex back d-flex">
            <div @click.stop="" class="wi-70 wi-sm-60 d-flex flex-column justify-center card-color pa-5">
            <div class="contact-border pa-2 white_">
                <h5 class="mb-4 font-weight-bold text-uppercase">{{offer.contact}}</h5>
                <p v-if="offer.mail" class="mb-2"><v-icon color="white" class="mr-2 mb-1">mdi-mail</v-icon>{{offer.mail}}</p>
                <p v-if="offer.phone" class="mb-2"><v-icon color="white" class="mr-2 mb-1">mdi-phone</v-icon>{{offer.phone}}</p>
                <a v-if="(offer.mail && offer.phone) || $vuetify.breakpoint.mobile" class="white_ profil-link" :href="offer.linkedin" target="_blank"><p class="mb-2"><v-icon color="white" class="mr-2 mb-2">mdi-linkedin</v-icon>Profil LinkedIn</p></a>
                <a v-else class="white_" :href="offer.linkedin" target="_blank"><v-btn class="j-btn red-gradient white_"><v-icon left class="mb-1">mdi-linkedin</v-icon>SE CONNECTER SUR LINKEDIN</v-btn></a>
            </div>
            </div>
            <div class="wi-30 wi-sm-40 cursor-pointer clickable-section BGwhitesmoke pa-5 d-flex flex-column justify-center">
            <v-icon>mdi-eye-off</v-icon>
            <span class="text-center">FERMER LE CONTACT</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'offer-card',
  props: ['offer','selected','months'],
  data() {
    return {}
  },
  created() {
  },
  methods: {
    dateFormat(date) {
      const newDate = new Date(date)
      return `${this.months[newDate.getMonth()]} ${newDate.getFullYear()}`
    }
  }
}
</script>

<style lang="scss">
.card-offers, .front, .back {
    width: 100%;
    height: 25vh;
    @include phone {
    height: 35vh;
    }
}
.card-offers {
    position: relative;
    .front, .back {
    box-sizing: border-box;
    position: absolute;
    border-radius: 0.5em;
    top: 0;
    left: 0;
    backface-visibility: hidden;
    overflow: hidden;
    transition: all .8s ease;
    filter: drop-shadow(0px 5px 5px rgba(128, 128, 128, 0.3));
    }
    .back {
    transform: rotateY(180deg);
    .profil-link {
        transition: all ease 0.5s;
    }
    .profil-link:hover {
        color: darken(white,10%)!important;
        .v-icon {
        color: darken(white,10%)!important;
        }
    }
    .contact-border {
        border-left: 3px solid white;
    }
    }
    &.selected .front {
    transform: rotateY(-180deg);
    }
    &.selected .back {
    transform: rotateY(0deg);
    filter: drop-shadow(0px 5px 5px rgba(128, 128, 128, 0.5));
    }
    .infos-section {
    background: whitesmoke;
    .infos-border {
        border-left: 3px solid $secondary;
    }
    }
    .card-color {
    background: darken($secondary,5%);
    }
    .clickable-section:hover {
        .v-icon {
        transform: scale(1.2)!important;
        }
    }
}
</style>