<template>
 <div>
  <j-page-template class="homepage" column vertcenter>
    <template v-slot:content>
      <div class="helper-chat d-flex flex-column">
        <v-expand-x-transition>
          <div v-if="helpChat" class="helper-content rounded-lg pa-2 d-flex">
            <div class="min-wi-content">
              <div class="white rounded-xl py-2 px-3 mb-2">
                <p class="mb-0 font-weight-bold">Découvrez MyJobAcademy en quelques secondes 👇</p>
              </div>
              <div class="pa-2 white rounded-xl intro-video">
                <j-vimeo-embed class="rounded-xl video-placeholder he-100" video="https://player.vimeo.com/video/798829372"></j-vimeo-embed>
              </div>
            </div>
            <v-avatar size="35" class="BGLight-secondary ml-2 ml-md-4">
              <img src="/images/3d/coach_2.png" alt="Coach">
            </v-avatar>
          </div>
        </v-expand-x-transition>
        <v-btn @click="helpChat = !helpChat" fab :class="[{'radiation':!helpChat},'ml-auto mt-4']"><v-icon>mdi-{{helpChat?'close':'help'}}</v-icon></v-btn>
      </div>
      <div class="glare-container flex-grow-1 d-flex flex-column">
        <div class="glare">
          <span v-for="n in 3" :key="n"></span>
        </div>
        <div class="home-content flex-grow-1 d-flex flex-column my-5">
          <div v-if="$store.getters.isAuthenticated" class="d-flex flex-column">
            <div class="d-flex flex-column flex-md-row justify-space-around align-center">
              <div class="wi-80 wi-sm-100 font-weight-bold white_ d-flex flex-column justify-center align-center px-4">
                <h2 class="mb-4">Bienvenue {{$store.state.user.profile.firstname}}</h2>
                <p class="mb-4 text-center">Découvrez l'ensemble de nos outils pour vous accompagner à toutes les étapes de votre recherche d'emploi. Vous pourrez ensuite suivre vos différentes avancées en vous rendant sur votre tableau de bord.</p>
                <v-btn to="user/dashboard" class="j-btn secondary white_">mon tableau de bord</v-btn>
              </div>
            </div>
            <div class="d-flex flex-wrap mx-0 my-4 ma-md-4">
              <div v-for="(tool, index) in tools" :key="index" :class="[{'disabled':tool.disabled},'tool-wrapper']">
                <j-tool-card :tool="tool" :index="index"></j-tool-card>
              </div>
            </div>
          </div>
          <div v-else class="flex-grow-1 d-flex flex-column align-center justify-center px-2 py-10">
            <img v-if="demo" class="wi-75" :src="$store.getters.getDemoPartner.path" alt="MyJobAcademy partner demo"/>
            <img v-else-if="partner" class="wi-75" :src="$store.getters.getPartner.path" alt="MyJobAcademy partner"/>
            <img v-else class="wi-50 wi-sm-75" alt="MyJobAcademy" src="/images/logo/jobacademy.png">
            <p class="mt-10 white_ font-weight-bold">Bienvenue, connectez-vous ci dessous.</p>
            <v-btn to="/login" class="primary white_ j-btn">se connecter</v-btn>
            <div v-if="registerApp" class="mt-10  d-flex flex-column justify-center align-center">
              <p class="white_ font-weight-bold">Si vous ne possédez pas encore de compte inscrivez-vous.</p>
              <v-btn to="/register-infos" class="j-btn white_ red-gradient">s'inscrire</v-btn>
            </div>
          </div>
        </div>
      </div>
    </template>
    </j-page-template>
    <v-footer :fixed="false" class="main-gradient pa-0" >
      <span class="lighter-overlay wi-100 white--text text-center caption">MyJobAcademy &copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
 </div>
</template>

<script>
import { apiURL, partner, demo, course, registerApp, commonQuestions } from '@/../config'
import axios from 'axios'

export default {
  name: 'Home',
  data(){
    return {
      partner: partner,
      demo: demo,
      registerApp: registerApp,
      helpChat: false,
      journey: {
        btn: 'commencer mes parcours',
        link: '/journeys'
      }
    }
  },
  computed : {
    tools() {
      return [
        { title: 'Parcours E-learning', text: 'Découvrez nos parcours e-learning pour vous accompagner dans les différentes étapes du recrutement.', btn: this.journey.btn, link: this.journey.link, img: 'woman-computer' },
        { title: 'Créateur de CV', text: 'Créez en ligne vos CV pour décrocher un maximum d’entretien. Profitez de différents modèles personnalisables.', btn: 'gérer mes cv', link: '/generator/resumes', img: 'design' },
        { title: 'Tableau de suivi des candidatures', text: 'Suivez l’ensemble de vos candidatures grâce à ce tableau de suivi. Ajoutez des notes d’entretien et profitez de mails de relance automatique.', btn: 'suivre mes candidatures', link: `/user/job-applications/${this.$store.getters.getUserId}`, img: 'job-applications' },
        { title: 'Votre présentation en entretien', text: 'Vous ne savez pas comment vous présenter en début d’entretien ? Laissez-vous guider.', btn: 'créer une présentation', link: '/generator/introductions', img: 'interview' },
        { title: 'Les questions posées pour votre Job', text: 'Découvrez les questions fréquemment posées en entretien pour le métier que vous ciblez ! Vous trouverez également des idées de réponses pour vous aider à y répondre.', btn: 'anticiper les questions', link: '/generator/questions', img: 'interview-questions', disabled: !commonQuestions },
        { title: 'Ateliers', text: 'Accédez directement à l’atelier de votre choix pour trouver rapidement la réponse à vos questions.', btn: 'voir mes ateliers', link: '/courses', img: 'searching-bar', disabled: !course }
      ]
    }
  },
  async created(){
    if(this.$store.getters.isAuthenticated && (this.$store.getters.isCustomer || this.$store.getters.isSubscriber)){
      this.$store.dispatch('loading')
      try{
        const res = await axios.get(`${apiURL}auth/courses?type=journey&filter=true`)
        if(res.data.courses.done.length) this.journey = {link:'/journeys', btn:'revoir mes parcours'}
        if(res.data.courses.waiting.length) this.journey = {link:'/journeys', btn:'commencer mes parcours'}
        if(res.data.courses.started.length) this.journey = {link:`/journeys/${res.data.courses.started[0]._id}`, btn:'reprendre mes parcours'}
      }catch(e){
        // do nothing
      }finally{
        this.$store.dispatch('stop_loading')
      }
    }
  }
}
</script>

<style lang="scss" >
.homepage {
  .helper-chat {
    position: fixed;
    z-index: 10;
    bottom : 1rem;
    right: 1rem;
    .helper-content {
      background-color: #e9e9e9;
      .intro-video {
        height: 25vw;
        width: 25vw;
        margin-left: auto;
        @include phone{
          width: 55vw;
          height: 55vw;
        }
      }
    }
    .v-btn {
      background-color: #e9e9e9;
      &:after, &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: white;
        border-radius: 9999px;
        animation : btn-radiation 1.8s ease-in-out infinite;
        display: none;
      }
      &::before{
        animation-delay: 0.4s;
      }
      &.radiation{
        &::after,&::before{
          display: block;
          @include phone {
            display: none;
          }
        }
      }
    }
  }
  .home-content {
    position: relative;
    z-index: 1;
    .tool-wrapper {
      margin: calc(10% / 2 / 2);
      width: 45%;
      @include smallPhone {
        margin: calc(10% / 2);
        width: 90%;
      }
      @include noPhone {
        margin: calc(10% / 3 / 2);
        width: 30%;
      }
      &.disabled {
        display: none;
      }
    }
  }
  .glare-container {
    position: relative;
    .glare{
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
      >span{
        $glareW: 15rem;
        position: absolute;
        height: $glareW;
        width: $glareW;
        background: radial-gradient(transparent, rgba(white, 0.1));
        border-radius: 100%;
        &:first-child{
          top: 0;
          left: 0;
          transform: translate(10%, 50%);
          @include phone{
            transform: translate(-10%, 15%);
          }
        }
        &:nth-child(2){
          bottom: 20%;
          right: 0;
          transform: translate(-50%, 20%);
          @include phone{
            transform: translate(25%, 20%);
          }
        }
        &:nth-child(3){
          bottom: 50%;
          right: 0;
          transform: translate(0%, -40%) scale(0.5);
          @include phone{
            transform: translate(20%, -20%) scale(0.5);
          }
        }
      }
    }
  }
}
</style>