import { env } from "@/../config";

export const plans = [
    { countries: ['fr'],
      text: 'MyJobAcademy - 39.90€/mois',price:39.90,id:1,
      plans: {
        default: env === 'prod' ? 'price_1MmG3XAappgCDnXwIw1leFKF' : 'price_1MlTFoFHQZQ5b1fxmbIXoq1L'
      },
      collectives: true,
      currency: 'eur',
      mode: 'subscription',
      journeys: env === 'prod' ? '639300cd440b4038ebda948f 63932ab5440b4038ebda9719 63932f7e440b4038ebda9943 6426f5d23ad7fc1492337bc3' : '62da95ceff2ad3cd1272d9b9 63877d9f1965e2ae4a4cb9eb 63bc21d21176c03b1637a2ff 637c916c309c5b463eaf8e24'
    },
    {
      countries: ['ch'],
      text: '🇨🇭 MyJobAcademy - 39.90CHF/mois',price:39.90,id:2,
      plans: {
        default: env === 'prod' ? 'price_1MmG3XAappgCDnXwvgWc0q9i' : 'price_1MlTI7FHQZQ5b1fxNcM8Etxf'
      },
      collectives: false,
      currency: 'chf',
      mode: 'subscription',
      journeys: env === 'prod' ? '639300cd440b4038ebda948f 63932ab5440b4038ebda9719 63932f7e440b4038ebda9943 6426f5d23ad7fc1492337bc3' : '62da95ceff2ad3cd1272d9b9 63877d9f1965e2ae4a4cb9eb 63bc21d21176c03b1637a2ff 637c916c309c5b463eaf8e24'
    }
]
