<template>
  <v-stepper class="j-stepper" alt-labels flat>
    <v-stepper-header>
      <template v-for="(step, i) in steps">
        <v-stepper-step @click="$emit('change', step.active)" :step="step.value" :key="i" :class="{'v-stepper__step--active':step.active === active}">
          <p>{{step.text}}<span v-if="!step.validator" class="ml-1 j-badge-small">!</span></p>
        </v-stepper-step>
        <v-divider v-if="i !== steps.length - 1" :key="`divider-${i}`"></v-divider>
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  props: ['steps','active'],
  name: 'stepper-labels'
}
</script>

<style lang="scss">
.j-stepper {
  border-radius: 0px!important;
  .v-stepper__step {
    flex-basis: 185px;
    &:hover:not(.v-stepper__step--active) {
      cursor: pointer;
      .v-stepper__label:after {
        width: 100%;
        background: #aeaeae;
      }
    }
    &.v-stepper__step--active {
      .v-stepper__step__step {
        background-color: $pastelGreen;
        opacity: 1;
      }
      .v-stepper__label:after {
        width: 100%;
        background: $primary;
      }
    }
    .v-stepper__step__step {
      font-size: 1rem;
      height: 32px;
      width: 32px;
      opacity: 0.75;
    }
    .v-stepper__label {
      color: $textGrey!important;
      p {
        margin: 0;
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
      }
      &:after {
        content: "";
        display: block;
        margin-top: 3px;
        height: 3px;
        width: 0;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
      }
    }
  }
  @include phone {
    .v-stepper__step {
      flex-basis: 0px;
    }
    .v-stepper__step__step {
      margin-bottom: 0;
    }
    .v-stepper__header .v-divider {
      align-self: center;
      margin: 0 -16px;
    }
  }
}
</style>