<template>
    <div class="question">
        <p class="font-weight-bold">{{question.question}}</p>
        <div :class="['answers',state,result]">
            <div v-for="answer in question.answers" :key="answer._id" :class="[{checked: answers.includes(answer._id)},{correct: rightAnswers.includes(answer._id) && state=='answered' },'label']">
              <v-checkbox class="ma-0" v-if="answer.text && state=='blank'" @change="answerCheck(answer._id,$event)"  :label="answer.text" ></v-checkbox>
              <p v-else-if="answer.text">{{answer.text}}</p>
            </div>
            <v-btn class="j-btn primary white--text" v-if="state =='blank'" @click="answer()" >Valider ma réponse</v-btn>
            <div v-if="state=='answered'" class="result">
                <p class="mention">{{mention}}</p>
                <p class="explanation text-pre-wrap">{{question.explanation}}</p>
                <v-btn v-if="!lastQuestion" @click="$emit('end',result)" class="j-btn primary white--text">Question<br v-if="$vuetify.breakpoint.mobile"> suivante<i class="fa fa-arrow-right ml-2"></i></v-btn>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'question',
  props:['question','lastQuestion','scroll'],
  data(){
      return {
          state: 'blank',
          answers: []
      }
  },
  mounted(){
  },
  computed:{
      result:function(){
          let result = "OK"
          let bad = 0, correct = 0
          for(let a of this.answers){
            if(this.rightAnswers.includes(a)){correct++}
            else{bad++}
          }
          if(!this.answers.length || bad>0) return "KO"
          if(this.answers.length && correct < this.rightAnswers.length) return 'INCOMPLETE'
        
          return result
      },
      mention:function(){
          let mention = "Bravo !"
          if(this.result == "KO"){
              mention = "Dommage, ce n'est pas exactement ça..."
          }else if(this.result == "INCOMPLETE"){
              mention = "Presque, il vous manque un petit quelque chose."
          }
          return mention
      },
      rightAnswers: function(){
          const right = []
          for(let a of this.question.answers){
              if(a.right){right.push(a._id)}
          }
          return right
      }
  },
  methods:{
      answer: function(){
          this.state='answered'
          if(this.scroll) {
            setTimeout(() => {
              const scrolling = window.scrollY
              window.scrollTo({top: scrolling * 1000, left: scrolling * 1000, behavior: 'smooth' })
            }, 10)
          }
          if(this.lastQuestion){
              this.$emit('end',this.result)
          }
      },
      answerCheck: function(j,val){
          if(val){
              this.answers.push(j)
          }else{
              this.answers.splice(this.answers.indexOf(j),1)
          }
      }
  }
}
</script>

<style lang="scss">
.question{
    text-align: left;
    width: 50vw;
    margin: auto;
    @include phone{
        width: 100%;
    }
    button{
        margin: auto;
    }
    .answers{
        display: flex;
        flex-direction: column;
        margin: auto;
        button{
            width: fit-content;
            margin: auto;
            margin-top: 10px;
        }
        .label{
            border-radius: 5px;
            margin-bottom: 0.5em;
            overflow: hidden;

            .v-input,p{
                margin: 0;
                padding: 0.5em 0.2em;
                transition: all ease 0.3s;
                &:hover{
                    background: rgba(240,240,240,1);
                }
                label{
                    margin:0;
                    margin-top: 0.2em;
                }
                .v-input__slot{
                    margin: 0;
                    align-items: start;
                }
                .v-messages{
                    display: none!important;
                }
            }
            p{
                padding-left: 1em;
            }
 
            &.checked{
                background: lightgray;
                .v-input{
                    &:hover{
                        background: none!important;
                    }
                }
            }
        }
        .mention{
            font-weight: 700;
            text-align: center;
            margin: 0;
            border-radius: 1em;
            padding: 1em;
        }
        &.answered{
            color: grey;
            label{
                pointer-events: none;
            }
            &.OK{
                .mention{
                    background: $primary;
                }
            }
            &.INCOMPLETE{
                .mention{
                    background: gold;
                }
            }
            &.KO{
                .mention{
                    background: $alert;
                }
            }
            .mention {
                color: white;
            }
            .correct{
                color: $primary;
            }
            .checked{
                &:not(.correct){
                    text-decoration: line-through;
                }
            }
        }
        .explanation{
            text-align: left;
            padding: 1em;
        }
    }
    .result{
        display: flex;
        flex-direction: column;
        border: lightgray solid 2px;
        border-radius: 1em;
        padding: 1em;
        background: white;
    }
}
</style>
