<template>
  <v-hover v-slot="{ hover }">
    <div class="resume-section">
      <v-timeline v-if="resume[section].length" :class="[{'has-opposite':opposite},'resume-'+section,'pa-0 mt-4']" dense>
        <draggable v-model="resume[section]" handle=".handle" @end="$parent.$emit('updated')">
          <v-timeline-item class="pa-0" :color="resume.style.secondColor" fill-dot right small v-for="(item, index) in items" :key="index">
            <template v-slot:opposite>
              <span class="grey--text font-italic">{{item.begin.monthText ? ('0'+(item.begin.month+1)).slice(-2) : ''}}{{item.begin.monthText && item.begin.year ? '/' : ''}}{{item.begin.year}} {{item.current ? `${lang.dateCurrent}` : `- ${item.end.monthText ? ('0'+(item.end.month+1)).slice(-2) : ''}${item.end.monthText && item.end.year ? '/' : ''}${item.end.year}`}}</span>
            </template>
            <div v-if="isExperiences">
              <p class="resume-subtitle font-weight-bold"><span class="text-uppercase second-color">{{item.company}}</span><span> - {{item.title}}</span><span v-if="item.contract" class="font-weight-medium"> ({{item.contract}})</span><span v-if="item.location" class="font-weight-light text-no-wrap"> - {{item.location}}</span></p>
              <p v-if="item.subtitle" class="resume-p">{{item.subtitle}}</p>
              <p v-if="!opposite" class="resume-p grey--text font-italic">{{item.begin.monthText}} {{item.begin.year}} {{item.current ? `${lang.dateCurrent}` : `- ${item.end.monthText} ${item.end.year}`}}</p>
              <div :class="[item.listStyle,'resume-textcontent']" v-html="item.tasks"></div>
            </div>
            <div v-else-if="isEducations">
              <p class="resume-subtitle"><span class="font-weight-bold text-uppercase second-color">{{item.school}}</span><span v-if="item.location" class="font-weight-light text-no-wrap"> - {{item.location}}</span></p>
              <p class="resume-p"><span class="font-weight-bold resume-subtitle">{{item.degree}}</span><span v-if="!opposite" class="grey--text font-italic"> {{item.begin.monthText}} {{item.begin.year}} {{item.current ? `${lang.dateCurrent}` : `- ${item.end.monthText} ${item.end.year}`}}</span></p>
              <div :class="[item.listStyle,'resume-textcontent']" v-html="item.description"></div>
            </div>
            <div class="resume-actions">
              <div class="action-tools d-flex pa-1 min-he-content rounded-bl-lg">
                <v-icon title="Modifier" @click="$parent.$emit('openDialog', section, index)" class="hover-opacity pa-1" :color="resume.style.secondColor">mdi-pencil</v-icon>
                <v-icon title="Dupliquer" @click="duplicateItem(index)" class="hover-opacity pa-1">mdi-content-copy</v-icon>
                <v-icon title="Supprimer" @click="$store.dispatch('open_dialog',{message:'Voulez-vous vraiment supprimer cette expérience ?',button:'Supprimer',color:'BGalert',alt:'Annuler',cb:()=>{removeItem(index)}})" class="hover-opacity pa-1" color="alert">mdi-delete</v-icon>
              </div>
              <v-icon title="Déplacer" class="handle cursor-pointer">mdi-arrow-up-down-bold</v-icon>
            </div>
          </v-timeline-item>
        </draggable>
      </v-timeline>
      <v-scroll-y-reverse-transition>
        <v-btn v-if="hover || !resume[section].length" @click="$parent.$emit('openDialog', section, null)"  :class="[{'absolute':resume[section].length},'add-btn j-btn secondary']">Ajouter</v-btn>
      </v-scroll-y-reverse-transition>
    </div>
  </v-hover>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  props: {
    resume: Object,
    lang: Object,
    section: String,
    opposite: Boolean
  },
  name: 'resume-timeline',
  components: {
    draggable
  },
  data() {
    return {
    }
  },
  methods: {
    removeItem(index) {
      this.resume[this.section].splice(index, 1)
      this.$parent.$emit('submit')
    },
    duplicateItem(index) {
      this.resume[this.section].push(this.resume[this.section][index])
      this.$parent.$emit('updated')
    }
  },
  computed: {
    isExperiences() {
      return this.section === 'experiences'
    },
    isEducations() {
      return this.section === 'educations'
    },
    items() {
      return this.resume[this.section].map(item => {
        item.begin.monthText = typeof item.begin.month === 'number' ? this.lang.months.find(d => { return d.id === item.begin.month }).txt : ''
        item.end.monthText = typeof item.end.month === 'number' ? this.lang.months.find(d => { return d.id === item.end.month }).txt : ''
        return {...item}
      })
    }
  }
}
</script>

<style lang="scss">
.resume-experiences, .resume-educations {
  &.has-opposite {
    &:before {
      left: calc(122px - 1px)!important;
    }
    .v-timeline-item__opposite {
      display: flex;
      white-space: nowrap;
      width: 104px;
      max-width: 104px;
      align-self: start;
      span {
        margin: 0 auto;
        padding-top: 2px;
        font-size: 0.7rem;
      }
    }
    .v-timeline-item__divider {
      min-width: 36px;
      .v-timeline-item__dot--small {
        box-shadow: none;
        .v-timeline-item__inner-dot {
          background-color: rgba(0, 0, 0, 0.12)!important;
        }
      }
    }
    .v-timeline-item__body {
      max-width: calc(100% - 140px)!important;
    }
  }
  &:not(.has-opposite) .v-timeline-item__body {
    max-width: none!important;
  }
  .v-timeline-item {
    justify-content: start;
    &:last-child .v-timeline-item__body {
      margin: 0!important;
    }
    .v-timeline-item__divider {
      align-items: start;
      .v-timeline-item__dot--small {
        height: 18px;
        width: 18px;
      }
    }
    .v-timeline-item__body {
      display: flex;
      justify-content: space-between;
      padding-left: 4px;
      margin-bottom: 16px;
      transition: all ease 0.25s;
      &:hover {
        background-color: rgba(197, 197, 197, 0.5)!important;
        .resume-actions {
          display: flex;
          position: absolute;
          height: 100%;
          right: 0;
          .handle {
            background-color: rgb(201, 201, 201);        
          }
          .action-tools {
            background-color: #f5f5f5;
          }
        }
      }
      .resume-actions {
        display: none;
        .action-tools {
          transition: all ease 0.25s;
        }
        .handle {
          transition: color ease 0.25s;
          &:hover {
            color: grey;
          }
          &::after {
            transform: none;
          }
        }
      }
      .resume-tasks {
        margin: 0;
      }
    }
  }
}
.resume-educations .v-timeline-item__dot, .resume-educations .v-timeline-item__inner-dot {
  border-radius: 0;
}
</style>