<template>
  <div class="resume-text-editor">
    <quill-editor :class="[{'list-styles':listStyles},this.item.listStyle,'mt-4']" :value="itemContent" @change="setContent($event.html)"  :options="editorOption"></quill-editor>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  props: ['resumeEdit','section','itemIndex'],
  name: 'text-editor',
  components: {
    quillEditor
  },
  data() {
    return {
      listStyles: false,
      editorOption: {
        modules: {
          toolbar: {
            container:[
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'list': 'bullet' }],
              ['bullet-list','dash-list','triangle-list'],
              ['clean']
            ],
            handlers: {
              'bullet-list': () => { 
                if(this.item.listStyle === 'bullet-list') this.setListStyle('')
                else this.setListStyle('bullet-list')
              },
              'dash-list': () => { 
                if(this.item.listStyle === 'dash-list') this.setListStyle('')
                else this.setListStyle('dash-list') 
              },
              'triangle-list': () => { 
                if(this.item.listStyle === 'triangle-list') this.setListStyle('')
                else this.setListStyle('triangle-list') 
              }
            }
          }
        },
        placeholder: 'Rédigez ici votre liste'
      }
    }
  },
  created() {
    if(this.itemContent.includes('</li>')) this.listStyles = true
  },
  methods: {
    setContent(content) {
      if(this.isEducation) this.item.description = content
      if(this.isExperience) this.item.tasks = content
      if(this.itemContent.includes('</li>')) this.listStyles = true
      else this.listStyles = false
    },
    setListStyle(className) {
      this.item.listStyle = className
    }
  },
  computed: {
    item() {
      return this.resumeEdit[this.section][this.itemIndex]
    },
    itemContent() {
      if(this.isEducation) return this.item.description
      else if(this.isExperience) return this.item.tasks
      else return null
    },
    isEducation() {
      return this.section === 'educations'
    },
    isExperience() {
      return this.section === 'experiences'
    }
  }
}
</script>

<style lang="scss">
.resume-text-editor .quill-editor {
  &:not(.list-styles) .ql-toolbar.ql-snow .ql-formats:nth-child(3) {
    display: none!important;
  }
  &.list-styles .ql-toolbar.ql-snow .ql-formats:nth-child(3) {
    border: 1px #ccc solid;
    border-radius: 3px;
  }
  &.dash-list .ql-dash-list, &.bullet-list .ql-bullet-list, &.triangle-list .ql-triangle-list {
    background: #ccc;
  }
  &.bullet-list li:before {
    content: '◦'!important;
  }
  &.dash-list li:before {
    content: '\002d'!important;
  }
  &.triangle-list li:before {
    content: '▸'!important;
  }
  .ql-toolbar.ql-snow .ql-formats {
    &:nth-child(2) {
      margin: 0;
    }
    .ql-dash-list, .ql-bullet-list, .ql-triangle-list {
      height: 100%;
      display: flex;
    }
    .ql-bullet-list {
      content: url("/public/images/resumes/white-bullet.png");
    }
    .ql-dash-list {
      content: url("/public/images/resumes/list.png");
    }
    .ql-triangle-list {
      content: url("/public/images/resumes/triangle.png");
    }
  }
  .ql-container.ql-snow {
    height: 30vh;
  }
}
</style>