<template>
<div class="resume-dialog d-flex flex-row">
  <j-resume-form-helper class="d-flex flex-column he-100 wi-40 pr-2" title="Quoi mettre en avant concernant vos études ?" video="https://player.vimeo.com/video/775855154#t=2m24s" :content="helperContent" :index="carouselIndex"></j-resume-form-helper>
  <div class="d-flex flex-column he-100 wi-60 pl-2">
    <div class="educations-carousel d-flex flex-column">
      <div class="mb-4 d-flex align-center">
        <img class="icon-img" src="/images/3d/diploma-books.png" alt="Diplôme"/>
        <h5 class="mb-0 ml-4 text-grey ">{{adding ? 'Ajout' : 'Modification'}} d'une de vos formations :</h5>
      </div>
      <div class="pa-4 rounded-t-lg white text-grey flex-grow-1">
        <v-carousel touchless height="100%" v-model="carouselIndex" :show-arrows="false" :hide-delimiters="true">
          <v-carousel-item content-class="he-100 d-flex flex-column">
            <h4 class="mb-0 font-weight-bold text-grey">Dans quelle école avez-vous effectué cette formation ?*</h4>
            <p class="mb-0 text-caption">Ex: université, école privée, lycée, ...</p>
            <v-text-field class="wi-80 mx-auto" v-model="education.school" placeholder="Nom de l'établissement" clearable hide-details></v-text-field>
            <h4 class="mb-0 mt-8 font-weight-bold text-grey">Quel diplôme avez-vous obtenu ou obtiendriez-vous ?*</h4>
            <p class="mb-0 text-caption">Ex: licence, master, BTS, ...</p>
            <v-text-field class="wi-80 mx-auto" v-model="education.degree" placeholder="Diplôme" clearable hide-details></v-text-field>
            <h4 class="mb-0 mt-8 font-weight-bold text-grey">Lieu où s'est déroulée la formation.</h4>
            <p class="mb-0 text-caption">Ex: Paris, États-Unis ...</p>
            <v-text-field class="wi-80 mx-auto" v-model="education.location" placeholder="Ville ou pays" clearable hide-details></v-text-field>
          </v-carousel-item>
          <v-carousel-item content-class="d-flex flex-column">
            <h4 class="mb-0 font-weight-bold text-grey">Si nécessaire, ajoutez des informations complémentaires concernant cette formation.</h4>
            <p class="mb-0 text-caption">Ex: spécialisation commerce international, participation au journal de l'établissement, Erasmus en Italie pendant 6 mois, membre du BDE ... </p>
            <j-resume-text-editor :resumeEdit="this.resumeEdit" :itemIndex="this.itemIndex" section="educations"></j-resume-text-editor>
          </v-carousel-item>
          <v-carousel-item content-class="d-flex flex-column">
            <h4 class="mb-0 font-weight-bold text-grey">Quelle a été la durée de cette formation ?*</h4>
            <div class="wi-80 mx-auto mt-4">
              <p class="mb-0 text-caption">Date de début :</p>
              <div class="d-flex">
                <v-select class="wi-50 pr-2" placeholder="Mois" :items="lang.months" item-text="txt" item-value="id" v-model="education.begin.month" clearable dense hide-details>
                  <template v-slot:selection="{ item }">
                      {{item.txt[0].toUpperCase() + item.txt.substring(1)}}
                    </template>
                    <template v-slot:item="{ item }">
                      {{item.txt[0].toUpperCase() + item.txt.substring(1)}}
                    </template>
                </v-select>
                <v-select class="wi-50 pl-2" placeholder="Année" :items="years" v-model="education.begin.year" clearable dense hide-details></v-select>
              </div>
              <div :class="[{'grey--text current-job':education.current},'end-selects']">
                <p class="mb-0 text-caption mt-4">Date de fin (ou prévue) :</p>
                <div class="d-flex">
                  <v-select :disabled="education.current" class="wi-50 pr-2" placeholder="Mois" :items="lang.months" item-text="txt" item-value="id" v-model="education.end.month" clearable dense hide-details>
                    <template v-slot:selection="{ item }">
                      {{item.txt[0].toUpperCase() + item.txt.substring(1)}}
                    </template>
                    <template v-slot:item="{ item }">
                      {{item.txt[0].toUpperCase() + item.txt.substring(1)}}
                    </template>
                  </v-select>
                  <v-select :disabled="education.current" class="wi-50 pl-2" placeholder="Année" :items="yearsNext" v-model="education.end.year" clearable dense hide-details></v-select>
                </div>
              </div>
            </div>          
          </v-carousel-item>
        </v-carousel>
      </div>
    </div>
    <div class="dialog-actions white rounded-b-lg pb-2 d-flex flex-column">
      <v-divider class="my-0" inner></v-divider>
      <v-carousel class="pa-4" touchless height="100%" v-model="carouselIndex" :show-arrows="false" :hide-delimiters="true">
        <v-carousel-item content-class="d-flex">
          <v-btn outlined class="ma-0 ml-auto primary--text j-btn" @click="carouselIndex++">description<v-icon right>mdi-arrow-right</v-icon></v-btn>
        </v-carousel-item>
        <v-carousel-item content-class="d-flex">
          <v-btn outlined class="ma-0 mr-auto grey--text j-btn" @click="carouselIndex--"><v-icon left>mdi-arrow-left</v-icon>informations</v-btn>
          <v-btn outlined class="ma-0 ml-auto primary--text j-btn" @click="carouselIndex++">durée<v-icon right>mdi-arrow-right</v-icon></v-btn>
        </v-carousel-item>
        <v-carousel-item content-class="d-flex">
          <v-btn outlined class="ma-0 mr-auto grey--text j-btn" @click="carouselIndex--"><v-icon left>mdi-arrow-left</v-icon>vos missions</v-btn>
        </v-carousel-item>
      </v-carousel>
      <v-btn :disabled="!validate" @click="$emit('close', true)" depressed class="red-gradient white_ j-btn">valider</v-btn>
      <v-btn text @click="$emit('close', false)" class="mt-2 j-btn grey--text">annuler</v-btn>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ['resumeEdit','adding','itemIndex','lang'],
  name: 'resume-educations',
  data() {
    return {
      carouselIndex: 0,
      helperContent: [
        '<h6 class="font-weight-bold text-grey">Voici les informations indispensables à renseigner :</h6><li>Nom du diplôme</li><li>Nom de l’école</li><li>Donner le détail sur la spécialisation</li><li>Le lieu</li>',
        '<h6 class="font-weight-bold text-grey">Si vous avez peu d’expériences professionnelles, cette rubrique est d’autant plus stratégique.</h6><p class="mt-2 mb-0">Elle doit vous permettre de remplir votre CV tout en donnant des informations clé sur vous à votre recruteur.</p><p class="mt-2 mb-0">Voici quelques idées pour étoffer cette rubrique :</p><li>Citer les matières clé que vous avez suivi et qui ont un lien avec le poste visé.</li><li>Expérience associative dans l’école.</li><li>Voyage de type Erasmus ou échange académique.</li><li>Thème de votre mémoire.</li><li>Ajouter les certifications ou formations que vous avez suivi en plus de vos études.</li><li>Les accréditations de votre école.</li><li>Vos mentions uniquement si elles sont flatteuses !</li>',
        '<h6 class="font-weight-bold text-grey">Concernant les dates :</h6><li>Les dates de début et de fin sont nécessaires.</li>'
      ]
    }
  },
  created() {
  },
  methods: {
  },
  computed: {
    education() {
      return this.resumeEdit.educations[this.itemIndex]
    },
    validate() {
      return this.education.school && this.education.degree && this.education.begin.year && this.education.end.year
    },
    years() {
      const years = []
      for (let index = parseInt(new Date().getFullYear()+1); index >= 1950; index--) { years.push(index.toString()) }
      return years
    },
    yearsNext() {
      const years = []
      for (let index = parseInt(new Date().getFullYear()+10); index >= 1950; index--) { years.push(index.toString()) }
      return years
    }
  }
}
</script>

<style lang="scss">
.educations-carousel {
  height: 100%!important;
  padding-right: 16px;
  overflow-y: scroll;
  .v-window-item {
    display: flex;
    flex-direction: column;
    .v-image.v-responsive.v-carousel__item {
      height: auto!important;
      flex-grow: 1;
    }
  }
  .icon-img {
    height: 2rem;
  }
  .checkbox-label label {
    margin: 0;
  }
  .end-selects {
    transition: all ease 0.25s;
    &.current-job {
      opacity: 0.5;
    }
  }
}
</style>