import Vue from 'vue';
  
  const state = {
    display: false,
    cb: ()=>{
      console.log('')
    }
  }
  
  
  const actions = {
    open_login_dialog: (context,payload)=>{
        context.commit('open_login_dialog',payload);
    },
    close_login_dialog: context=>{
        context.commit('close_login_dialog');
    }
  };
  
  const mutations = {
    open_login_dialog: (state,payload)=>{
        const dialog ={
            cb: payload.cb || ''
        }
        Vue.set(state,'display',true);
        Vue.set(state,'cb',dialog.cb);
    },
    close_login_dialog: state=>{
        Vue.set(state,'display',false);
    }
  };
  
  export default {
    state,
    actions,
    mutations
  };
  