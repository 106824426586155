<template>
  <div @click.stop="!isActive && $emit('select', index)" :class="[{'active': isActive,'mobile':$vuetify.breakpoint.smAndDown},'introduction-paragraphe mb-4 flex-grow-1']">
    <p class="paragraphe-title text-no-wrap white px-1">{{titles[index]}}<span class="infos"> - {{isActive ? 'Modification' : 'Modifier'}} <i class="fa fa-pen"></i></span></p>
    <v-btn @click="$emit('selectVideo', index)" small :class="[isActive?'primary':'secondary','paragraphe-video ma-2 white_ j-btn']">voir en vidéo</v-btn>
    <quill-editor v-if="isActive" :value="content" @change="setContent($event.html)" :options="editorOption"></quill-editor>
    <div v-else v-html="content"></div>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  props: ['index', 'state', 'field', 'introduction'],
  name: 'introduction-paragraphe',
  components: {
    quillEditor
  },
  data() {
    return {
      editorOption:{
        modules: {
          toolbar: {
            container:[
              ['bold', 'italic', 'underline'],
              ['clean']
            ]
          }
        },
        placeholder: 'Rédaction du paragraphe'
      },
      titles: ['👋 Qui je suis', '🎓 Mes études', '💼 Mes expériences', '🎨 Mes hobbies', '⭐ Pourquoi je postule']
    }
  },
  methods: {
    setContent(content) {
      this.state.saved = false
      this.introduction.content[this.field] = content
    }
  },
  computed: {
    isActive() {
      return this.state.active === this.index
    },
    content() {
      return this.introduction.content[this.field]
    }
  }
}
</script>

<style lang="scss">
.introduction-paragraphe {
  position: relative;
  border: transparent solid 2px;
  &:hover, &.mobile {
    border: $grey dashed 2px;
    .paragraphe-title, &:not(.mobile) .paragraphe-video {
      display: inline;
      color: $grey;
      .infos {
        display: inline;
      }
    }
  }
  &:not(.active) {
    cursor: pointer;
    padding: 8px;
  }
  &.active {
    border: $primary solid 2px;
    .paragraphe-title {
      display: inline;
      color: $primary;
      .infos {
        display: inline;
      }
    }
  }
  .paragraphe-title {
    transform: translateY(-24px) scale(0.75);
    line-height: 16px;
    transform-origin: top left;
    top: 16px;
    left: 16px;
    position: absolute;
    color: $secondary;
    .infos {
      display: none;
    }
  }
  .paragraphe-video {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
  }
  p {
    margin: 0;
  }
  .quill-editor {
    .ql-toolbar.ql-snow {
      border: none;
      border-bottom: 1px  solid #ccc;
    }
    .ql-container.ql-snow {
      height: auto;
      min-height: 10vh;
      border: none;
      font-size: 14px;
    }
  }
}
</style>