<template>
  <div id="resume" class="resume-v5 white elevation-1 mx-auto my-8 d-flex flex-column" >
    <div :style="`padding: ${resume.style.headerSize/2}px 0;`" class="resume-header main-bg-color d-flex wi-100">
      <div class="wi-65 resume-profile-container d-flex">
        <j-resume-profile class="flex-grow-1" :resume="resume" :profile="false" background></j-resume-profile>
      </div>
      <div class="wi-35 resume-profile-container d-flex align-center">
        <j-resume-profile class="flex-grow-1" :resume="resume" :name="false" background :size="150"></j-resume-profile>
      </div>
      <j-resume-header-resize :resume="resume"></j-resume-header-resize>
    </div>
    <div class="resume-content d-flex flex-grow-1">
      <div class="header-bottom"></div>
      <div class="resume-large wi-65">
        <div :class="[{'flex-column-reverse align-self-start':resume.style.educationsFirst},'content-container d-flex flex-column']">
          <div class="wi-100 experiences-container">
            <div class="resume-title main-color d-flex align-center">
              <p>{{lang.experiences}}</p>
              <v-btn @click="$emit('changeOrderLarge')" small class="btn-first j-btn white">{{resume.style.educationsFirst?'Monter':'Descendre'}}<v-icon right>mdi-arrow-{{resume.style.educationsFirst?'up':'down'}}</v-icon></v-btn>
            </div>
            <j-resume-timeline :resume="resume" section="experiences" :lang="lang" opposite></j-resume-timeline>
          </div>
          <div class="wi-100 educations-container">
            <div class="resume-title main-color d-flex align-center">
              <p>{{lang.educations}}</p>
              <v-btn @click="$emit('changeOrderLarge')" small class="btn-first j-btn white">{{resume.style.educationsFirst?'Descendre':'Monter'}}<v-icon right>mdi-arrow-{{resume.style.educationsFirst?'down':'up'}}</v-icon></v-btn>
            </div>
            <j-resume-timeline :resume="resume" section="educations" :lang="lang" opposite></j-resume-timeline>
          </div>
        </div>
      </div>
      <div class="resume-lean second-bg-color wi-35">
        <j-resume-details :resume="resume" transparent border borderMain></j-resume-details>
        <j-resume-lean :lang="lang" :resume="resume" titleClass="main-color" background></j-resume-lean>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['resume','lang'],
  name: 'resume-v_5'
}
</script>

<style lang="scss">
#resume.resume-v5 {
  .resume-large, .resume-profile-container.wi-65 {
    padding-right: 16px;
  }
  .resume-lean, .resume-profile-container.wi-35 {
    margin-left: 16px;
  }
  .resume-large, .resume-lean {
    padding-top: 32px;
  }
  .resume-header .resume-profile {
    padding-bottom: 0;
  }
  .resume-content {
    position: relative;
    .header-bottom {
      position: absolute;
      top: 0;
      height: 32px;
      width: 100%;
      &:after {
        content: "";
        position: absolute;
        z-index: 5;
        height: 0;
        width: 0;
        border-right: 1000px solid var(--resumeMain-color);
        border-bottom: 32px solid transparent;
      }
    }
  }
}
</style>