<template>
  <div class="journey-card rounded-lg d-flex flex-column flex-md-row" @click="$router.push(`/journeys/${journey._id}`)">
    <div class="wi-30 wi-sm-100 pa-4 d-flex align-center">
      <v-img class="journey-thumbnail wi-100 rounded-lg" :src="journey.thumbnail">
        <div v-if="$vuetify.breakpoint.mobile" class="journey-percent pa-2">
          <v-progress-linear class="rounded-pill wi-100 mx-auto mx-md-0" :value="percentage" :color="journey.color" height="10" >
          </v-progress-linear>
        </div>
      </v-img>
    </div>
    <div class="wi-70 wi-sm-100 pa-4 d-flex flex-column">
      <h5 class="text-uppercase font-weight-bold mb-3" :style="{'color': journey.color}">{{journey.title}}</h5>
      <div class="flex-grow-1"></div>
      <div v-if="time.length"  class="mb-3 caption text-left wi-100 grey--text d-flex align-center">
        <v-icon class="caption grey--text mr-1">mdi-clock-outline</v-icon>
        <span>{{time}}</span>
      </div>
      <p class="mb-3 text-pre-wrap">{{journey.description}}</p>
      <div class="flex-grow-1"></div>
      <v-progress-linear v-if="!$vuetify.breakpoint.mobile" class="mb-3 rounded-pill wi-70 mx-0" :value="percentage" :color="journey.color" height="10"></v-progress-linear>
      <div class="flex-grow-1"></div>
      <router-link :style="{'color': journey.color}" class="btn-begin mt-1" @click.stop="" :to="`/journeys/${journey._id}`">
        <span v-if="state === 'done'">Revoir la formation</span>
        <span v-else-if="state === 'started'">Continuer la formation</span>
        <span v-else>Commencer la formation</span>
        <v-icon :style="{'color': journey.color}">mdi-chevron-right</v-icon>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'journey-card',
  props: ['journey','state','percentage'],
  data() {
    return {}
  },
  computed: {
    time(){
      if(!this.journey.list_of_sessions.length) return ''
      const time = this.journey.list_of_sessions.reduce((total,current)=>{
          return typeof current.time === "undefined" ? total : total + current.time
      }, 0)
      if(time > 60) {
        const hour = Math.floor(time / 60)
        const min = time % 60
        return `${hour}h${min}`
      } 
      else return `${time}min`
    }
  }
}
</script>

<style lang="scss">
.journey-card {
  transition: all ease 0.5s;
  box-shadow: 0px 5px 5px rgba(128, 128, 128, 0.3);
  background: white;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 5px 5px rgba(128, 128, 128, 0.5);
    .btn-begin {
      transform: translatex(0.5em);
    }
  }
  .journey-thumbnail {
    .journey-percent {
      width: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .btn-begin {
    text-align: left;
    font-weight: 700;
    font-size: 1.2rem;
    text-transform: uppercase;
    transition: transform 1s;
    &:hover {
      transform: translatex(0.5em);
    }
  }
  .btn-details {
    transition: all ease 0.5s;
  }
}
</style>