<template>
  <div class="account-section wi-100 he-100 white rounded-lg d-flex flex-column flex-md-row">
    <div class="wi-40 wi-sm-auto main-gradient-pastel d-flex align-center justify-center rounded-lg pa-2 ma-4">
      <img class="wi-50" :src="`/images/3d/${image}.png`" alt="Section image"/>
    </div>
    <div class="wi-60 wi-sm-auto d-flex flex-column justify-space-between ma-4 py-4 ml-md-0">
      <p v-if="text.length === 3" class="mb-0">{{text[0]}} <strong class="secondary--text">{{text[1]}}</strong>{{text[2]}}</p>
      <p v-else class="mb-0">{{text[0]}}</p>
      <v-btn v-if="active && btn" @click="$router.push(cb)" :class="[cbClass,'j-btn mt-2']">{{cbText}}</v-btn>
      <router-link v-else-if="active" :to="cb" class="mb-0 mt-2 secondary--text">{{cbText}} <v-icon color="secondary">mdi-arrow-right</v-icon></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "account-section",
  props: {
    active: Boolean,
    text: Array,
    cb: String,
    cbText: String,
    image: String,
    cbClass: String,
    btn: {type: Boolean, default: () => {return false}}
  }
}
</script>