<template>
  <div :class="[{'has-description':description,'has-details':details,'has-background':background,'has-border':border,'border-main':borderMain},'resume-details resume-section flex-grow-1 d-flex flex-column justify-center']">
    <v-hover v-slot="{ hover }">
      <div class="details-container d-flex flex-column justify-center">
        <div v-if="description && resume.description" :class="[{'main-bg-color':!transparent},'resume-details-description']">
          <p class="resume-p">{{resume.description}}</p>
        </div>
        <div v-if="details" :class="[{'second-bg-color':!transparent},'resume-details-list']">
          <table>
            <tr v-for="(item, index) in items" :key="index">
              <th v-if="item.th && (item.default || resume[item.slug])" class="pr-1 resume-p"><v-icon small>mdi-{{item.icon}}</v-icon> :</th>
              <th v-else-if="resume[item.slug]"></th>
              <td v-if="item.default || resume[item.slug]" class="resume-p">{{item.slug==='city'&&resume.zip?resume.zip:''}} {{resume[item.slug] ? resume[item.slug] : item.default}}</td>
            </tr>
          </table>
        </div>
        <v-scroll-y-reverse-transition>
          <v-btn v-if="hover" @click="$parent.$emit('openDialog', 'details', null)"  class="absolute add-btn j-btn secondary">Modifier</v-btn>
        </v-scroll-y-reverse-transition>
      </div>
    </v-hover>
  </div>
</template>

<script>
export default {
  props: {
    resume: {
      required: true,
      type: Object
    },
    description: {
      required: false,
      type: Boolean,
      default: ()=> {return true}
    },
    details: {
      required: false,
      type: Boolean,
      default: ()=> {return true}
    },
    background: {
      required: false,
      type: Boolean,
      default: ()=> {return false}
    },
    border: {
      required: false,
      type: Boolean,
      default: ()=> {return false}
    },
    borderMain: {
      required: false,
      type: Boolean,
      default: ()=> {return false}
    },
    transparent: {
      required: false,
      type: Boolean,
      default: ()=> {return false}
    }
  },
  name: 'resume-details',
  data() {
    return {
    }
  },
  computed: {
    items(){
      return[
        {th: true, icon: 'home-outline', slug: 'address'},
        {th: false, slug: 'city'},
        {th: true, icon: 'phone-outline', slug: 'phone'},
        {th: true, icon: 'email-outline', slug: 'email', default: this.$store.getters.getEmail},
        {th: true, icon: 'web', slug: 'website'}
      ]
    } 
  }
}
</script>

<style lang="scss">
.resume-details {
  padding: 16px 0 16px 16px;
  .details-container:hover .resume-details-description, .details-container:hover .resume-details-list {
    color: black!important;
    background-color: rgba(197, 197, 197, 0.5)!important;
  }
  &.has-background .resume-details-description, &.has-background .resume-details-list {
    color: white!important;
  }
  &.has-border:not(.border-main) .resume-details-description {
    border: 2px solid var(--resumeSecond-color);
    border-right: none;
  }
  &.has-border.border-main .resume-details-description {
    border: 2px solid var(--resumeMain-color);
    border-right: none;
  }
  &:not(.has-description) {
    padding: 16px 16px 8px 16px;
  }
  .resume-details-description {
    padding: 8px 16px;
    margin-bottom: 8px;
    transition: all ease 0.25s;
  }
  .resume-details-list {
    padding: 8px 16px;
    margin-left: 16px;
    transition: all ease 0.5s;
    th {
      white-space: nowrap;
    }
    .v-icon {
      color: inherit;
      line-height: 0!important;
    }
  }
}
</style>