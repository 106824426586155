<template>    
<div class="picture-dialog">
  <div class="picture-content text-grey d-flex flex-column">
    <div class="mb-4 d-flex align-center">
      <img class="icon-img" src="/images/3d/picture.png" alt="Picture"/>
      <h5 class="mb-0 ml-4 text-grey ">Modification de votre photo :</h5>
    </div>
    <div class="pa-4 white rounded-t-lg flex-grow-1">
      <input class="d-none" type="file" accept="image/*" ref="picture" @change="inputChange($event.target.files)"/>
      <div v-if="cropping && pictureFile" class="d-flex flex-column justify-center">
        <span @click="cancel()" class="cursor-pointer hover-opacity mb-2 mr-auto"><i class="fa fa-arrow-left mr-2"></i>Retour</span>
        <cropper :src="pictureUrl" stencil-component="j-CircleStencil" @change="cropPicture($event.canvas)"></cropper>
      </div>
      <div v-else class="d-flex flex-column">
        <h4 v-if="pictureFile" class="mb-0 text-grey font-weight-bold">Valider cette photo de profil ?</h4>
        <h4 v-else class="mb-0 text-grey">Privilégiez un <span class="font-weight-bold">format carré</span> ainsi qu'une image de <span class="font-weight-bold">moins de 1 mo</span>.</h4>
        <p class="mb-0 text-caption">Cette photo servira pour votre compte ainsi que vos CV.</p>
        <div class="mt-8 d-flex flex-column align-center justify-center">
          <v-hover v-slot:default="{ hover }">
            <v-avatar @click="$refs.picture.click()" size="150px" class="cursor-pointer">
              <img v-if="getPicture" class="BGLight-primary" :src="getPicture" alt="Photo de profil"/>
              <div v-else class="initials">
                <span v-if="$store.getters.getFirstname && $store.getters.getLastname">
                  {{$store.getters.getFirstname[0].toUpperCase()}}{{$store.getters.getLastname[0].toUpperCase()}}
                </span>
              </div>
              <v-fade-transition>
                <v-overlay v-if="hover" absolute color="grey">
                  <v-icon large>mdi-pencil</v-icon>
                </v-overlay>
              </v-fade-transition>
            </v-avatar>
          </v-hover>
          <p class="mb-2 mt-2 text-caption grey--text">Cliquez ci-dessus pour sélectionner une image.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-actions white rounded-b-lg pb-2 d-flex flex-column">    
    <v-divider></v-divider>
    <div v-if="cropping" class="d-flex flex-column">
      <v-btn @click="validateCrop()" class="j-btn primary">Rogner l'image</v-btn>
      <v-btn @click="$refs.picture.click()" small class="j-btn secondary mt-2">Autre image</v-btn>
    </div>
    <v-btn v-else :disabled="!accessRegister" @click="submit()" class="mt-2 j-btn white_ red-gradient">enregistrer</v-btn>
    <v-btn @click="() => {close()}" text class="mt-2 j-btn grey--text">annuler</v-btn>
    <v-expand-transition>
      <v-alert v-if="errorText" class="mt-8 my-4 text-center red lighten-2 white_">
        {{errorText}}
      </v-alert>
    </v-expand-transition>
  </div>
</div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import axios from 'axios'
import { apiURL } from '@/../config'
import { USER_UPDATE_PROFILE } from '@/assets/jobtimise-common/store/actions/user'

export default {
  name: 'resume-picture',
  components: {
    Cropper
  },
  data() {
    return {
      accessRegister: false,
      cropping: false,
      pictureFile: null,
      pictureCropped: null,
      errorText: null
    }
  },
  methods: {
    validateCrop() {
      this.accessRegister = true
      this.cropping = false
    },
    cancel() {
      this.$refs.picture.value = null
      this.pictureFile = null
      this.pictureCropped = null
      this.cropping = false
    },
    close() {
      this.$emit('close')
      this.cancel()
    },
    inputChange(file) {
      if (file.length>0) {
        let imageFile = file[0]
        if (!imageFile.type.match('image.*')) {
          this.errorText = 'Choisissez une image (png, jpg, ...)'
          setTimeout(() => {this.errorText = null}, 6000)
        } else if ((imageFile.size / 1024 / 1024) > 1) {
          this.errorText = 'Veuillez choisir une image de moins d’1MO.'
          setTimeout(() => {this.errorText = null}, 6000)
        } else {
          this.pictureFile = imageFile
          this.cropping = true
        }
      }
    },
    cropPicture(canvas) {
      this.pictureCropped = canvas
    },
    async submit() {
      try {
        this.$store.dispatch('loading')
        const formData = new FormData()
        const dataPicture = await new Promise(
          (resolve) => this.pictureCropped.toBlob((blob) => resolve(blob),'image/png')
        )
        formData.append('picture', dataPicture, this.$store.getters.getFullName)
        const recaptcha = await this.$recaptcha('picture_upload')
        formData.append('recaptcha',recaptcha)
        const res = await axios.put(`${apiURL}auth/me/picture`,formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        this.$store.dispatch(USER_UPDATE_PROFILE, {user: res.data.user})
        this.close()
        this.$emit('updatePicture')
      } catch(e) {
        this.errorText = 'Erreur lors de l’enregistrement de l’image.'
        setTimeout(() => {this.errorText = null}, 6000)
      } finally {
        this.$store.dispatch('stop_loading')
      }
    }
  },
  computed: {
    getPicture() {
      if(this.pictureCropped) return this.pictureCroppedUrl
      else if(this.$store.getters.getPicture) return this.$store.getters.getPicture
      else return null
    },
    pictureUrl() {
      return URL.createObjectURL(this.pictureFile)
    },
    pictureCroppedUrl() {
      return this.pictureCropped.toDataURL()
    }
  }
}
</script>

<style lang="scss">
.picture-dialog {
  padding: 16px 0 16px 16px;
  background: $mainGradientPastel;
  height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .dialog-actions {
    margin-right: calc(16px + 0.5rem);
  }
  .picture-content {
    height: 100%!important;
    padding-right: 16px;
    overflow-y: scroll;
  }
  .icon-img {
    height: 2rem;
  }
  .initials{
    background: rgba($pastelLightBlue, 0.5);
    border-radius: 100%;
    width: 150px;
    height: 150px;
    position: relative;
    margin: auto;
    span{
      font-weight: 600;
      display: block;
      color: $secondary!important;
      font-size: 4rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .vue-advanced-cropper {
    max-height: 600px;
    .vue-advanced-cropper__stretcher {
      max-height: 600px;
    }
    .vue-advanced-cropper__image-wrapper {
      background: white!important;
     }
    .vue-advanced-cropper__image {
      background: $primary;
    }
    .vue-circle-stencil__preview {
      border: 1px rgba(255, 255, 255, 0.3) solid;
    }
  }
}
</style>