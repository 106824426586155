<template>
    <v-card class="session-progress-container align-center">
        <div class="pa-2">
          <i class="closeIcon zi-10" @click="$emit('close')"></i>
          <v-img :src="course.thumbnail" :alt="course.title" class="rounded-lg course-thumbnail">
            <div v-if="isJourney" class="container-btn pa-2 zi-1 d-flex">
              <v-btn class="j-btn red-gradient white--text mx-auto" @click="$router.push(`/journeys/${course._id}`)">voir les détails</v-btn>
            </div>
          </v-img>
          <p class="caption my-3 text-center">Votre progression dans {{isJourney?'le parcours':'la formation'}} <strong>{{course.title}}</strong> :</p>
          <div class="btn-progress d-flex justify-space-between">
              <router-link v-if="get_session_index() > 1" :to="(isJourney?`/journeys/`:`/courses/`)+`${course._id}/sessions/${course.list_of_sessions[get_session_index() - 2]._id}`" class="previous text-center pa-1 d-flex align-center justify-center">
                  <i class="fa fa-arrow-left mr-2"></i>
                  <span>Atelier précédent</span>
              </router-link>
              <router-link v-if="get_session_index() < course.list_of_sessions.length" :to="(isJourney?`/journeys/`:`/courses/`)+`${course._id}/sessions/${course.list_of_sessions[get_session_index()]._id}`" class="next text-center pa-1 d-flex align-center justify-center">
                  <span>Atelier suivant</span>
                  <i class="fa fa-arrow-right ml-2"></i>
              </router-link>
          </div>
        </div>
        <v-timeline v-if="!isJourney" class="mt-3 session-progress" align-top dense >
            <v-timeline-item v-for="(session, index) in course.list_of_sessions" :key="session._id" :class="[{'past':index < currentIndex, 'current': index === currentIndex },'item']" small>
                <router-link :to="`/courses/${course._id}/sessions/${session._id}`" :class="[index === currentIndex ? 'session-link-active' : 'session-link','d-flex']">
                    <div class="text-grey content">
                        <p class="secondary--text ma-0 font-weight-normal">
                            <strong>{{session.title}}</strong> 
                        </p>
                        <div>{{session.objective}}</div>
                    </div>
                    <v-icon v-if="index !== currentIndex" color="secondary" class="ml-4">mdi-chevron-right</v-icon>
                </router-link>
            </v-timeline-item>
        </v-timeline>
        <div v-if="isJourney" class="mt-3 px-2 timeline-progress">
          <ul>
          <li v-for="(step, stepIndex) in course.timeline" :key="stepIndex" class="py-2">
            <input v-if="get_timeline_index() === stepIndex" type="checkbox">
            <input v-else type="checkbox" checked>
            <i class="mt-4"></i>
            <h3 class="font-weight-bold secondary--text mx-0 my-2 pl-2">ÉTAPE {{stepIndex+1}} {{step.title ? ' - '+step.title : ''}}</h3>
            <div class="timeline-sessions ml-4 pl-4">
                <p class="mb-2 caption">{{course.timeline[stepIndex].sessions.length}} atelier(s)</p>
                <span v-for="(session, sIndex) in course.timeline[stepIndex].sessions" :key="sIndex">
                    <router-link :to="`/journeys/${course._id}/sessions/${session._id}`" :class="[session.state === 'done'?'primary--text':'text-grey',current === session._id?'current-session':'other-session',' d-flex py-2']">
                      <v-icon v-if="session.state === 'done'" color="primary">mdi-checkbox-marked</v-icon>
                      <v-icon v-else>mdi-check-box-outline</v-icon>
                      <p class="ma-0 pl-1">{{session.title}}</p>
                      <v-icon v-if="session._id !== current" :color="session.state === 'done'?'primary':''">mdi-chevron-right</v-icon>
                    </router-link>
                </span>
                <div v-if="step.quiz && step.quiz.length" class="d-flex">
                  <router-link v-if="quizDone(step)" :to="quizAccess(step) ? `/journeys/${course._id}/quiz/${step._id}` : ''" :class="[quizAvarage(step)?'primary--text':'gold--text',{'cursor-initial':!quizAccess(step)},' d-flex py-2 other-session']">
                    <v-icon :color="(quizAvarage(step)?'primary':'gold')">mdi-bullseye-arrow</v-icon>
                    <p class="ma-0 pl-1">Évaluation de fin d'étape {{`${step.quiz_result}/${step.quiz.length}`}}</p>
                    <v-icon v-if="quizAccess(step)" class="chevron" :color="quizAvarage(step)?'primary':'gold'">mdi-chevron-right</v-icon>
                  </router-link>
                  <router-link v-else :to="`/journeys/${course._id}/quiz/${step._id}`" class="text-grey d-flex py-1 other-session">
                    <v-icon >mdi-bullseye</v-icon>
                    <p class="ma-0 pl-1">Évaluation de fin d'étape {{`(${step.quiz.length} question${step.quiz.length > 1 ?'s':''})`}}</p>
                    <v-icon class="chevron">mdi-chevron-right</v-icon>
                  </router-link>
                </div>
            </div>
          </li>
          </ul>
        </div>
    </v-card>
</template>

<script>
import { quizCatchup } from '@/../config'

export default {
  name: 'session-progress',
  props:['course','current','get_timeline_index','get_session_index','isJourney'],
  data(){
    return {
        currentIndex:0,
    }
  },
  mounted(){
    const all_sessions = this.course.list_of_sessions
    this.currentIndex = all_sessions.findIndex(e=>{return e._id === this.current})
  },
  methods:{
    quizDone(step) {
      return typeof step.quiz_result === 'number'
    },
    quizAvarage(step) {
      if(step.quiz_result/step.quiz.length*100 >= 50) return true
      else return false
    },
    quizAccess(step) {
      if(quizCatchup) return true
      else return typeof step.quiz_result !== 'number'
    }
  }
}
</script>

<style lang="scss">
.session-progress-container {
    overflow-x: hidden;
    min-height: 100vh;
    @include closeIcon(white, 2rem, 0.1rem,1rem,1rem);
    .closeIcon{
      @include phone{
        top: 5px;
        right: 5px;
      }
    }
    .course-thumbnail {
      max-height: 10rem;
      width: 100%;
      object-fit: cover;
      object-position: center top;
      .container-btn {
        width: 50%;
        border-top-left-radius: 1em;
        @include phone{
          width: 100%;
        }
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
    .btn-progress {
        a {
          background-color: $secondary;
          color: white;
          width: 47%;
            &.previous{ 
                border-radius: 10rem 0 0 10rem;
            }
            &.next{ 
                margin-left: auto;
                border-radius: 0 10rem 10rem 0;
            }
            &:hover {
                opacity: 0.8;
            }
        }
    }
    .session-progress {
        .item {
            @include noPhone { padding-right: 1.5em; }
            .v-timeline-item__inner-dot {
                    background-color: grey!important;
                }
            &.past{
                .v-timeline-item__inner-dot {
                    background-color: $secondary!important;
                }
            }
            &.current{
                padding-right: 0;
                .v-timeline-item__inner-dot {
                    background-color: $secondary!important;
                }
                .content {
                    transform: translateX(-1.5em);
                    background: lighten($pastelBlue, 25%);
                    width: 100%;
                    padding: 0.5em;
                    border-radius: 0.5em;
                }
            }
            .session-link {
                transition: transform 1s;
                .v-icon {
                    visibility: hidden;
                }
                &:hover {
                    transform: translatex(1em);
                }
                &:hover .v-icon {
                    visibility: visible;
                }
            }
        }
    }
}
.timeline-progress {
  .gold--text {
    color: gold!important;
  }
  .transition, .timeline-sessions, ul li i:before, ul li i:after {
    transition: all 0.25s ease-in-out; 
  }
  .flipIn, h1, ul li {
    animation: flipdown 0.5s ease both;
  }
  .no-select, h2 {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  ul {
    list-style: none;
    perspective: 900;
    padding: 0;
    margin: 0;
    &:last-child {
      border-bottom: 1px solid rgba(71, 71, 71, 0.25);
    }
    li {
      position: relative;
      padding: 0;
      margin: 0;
      border-top: 1px solid rgba(71, 71, 71, 0.25);
      .timeline-sessions {
        position: relative;
        overflow: hidden;
        opacity: 1;
        transform: translate(0, 0);
        border-left: 4px solid $pastelBlue;
        z-index: 2;
        .current-session {
            background: lighten($pastelBlue, 25%);
        }
        .other-session {
            transition: transform 1s;
            &:hover:not(.cursor-initial) {
              transform: translatex(1em);
            }
        }
      }
      i:not(.v-icon) {
        position: absolute;
        right: 1rem; 
        &:before, &:after {
          content: "";
          position: absolute;
          background-color: $secondary;
          width: 3px;
          height: 9px; }
        &:before {
          transform: translate(-2px, 0) rotate(45deg); }
        &:after {
          transform: translate(2px, 0) rotate(-45deg); }
      }
      .v-icon {
        margin-bottom: auto;
      }
      input[type=checkbox] {
        position: absolute;
        cursor: pointer;
        width: 100%;
        height: 100%;
        top:0;
        z-index: 1;
        opacity: 0;
      }
      input[type=checkbox]:checked ~ .timeline-sessions {
        margin-top: 0;
        max-height: 0;
        opacity: 0;
        transform: translate(0, 50%);
      }
      input[type=checkbox]:checked ~ i:before {
        transform: translate(2px, 0) rotate(45deg);
      }
      input[type=checkbox]:checked ~ i:after {
        transform: translate(-2px, 0) rotate(-45deg);
      }
    }
  }
}
</style>
