<template>
  <div class="header-resize">
    <div class="header-input"></div>
  </div>
</template>

<script>
export default {
  props: ['resume'],
  name:'header-resize',
  data() {
    return {
      originalY: 0,
      originalHeight: 0
    }
  },
  mounted() {
    document.querySelector('#resume .resume-header .header-resize').addEventListener('mousedown', (e) => {
      e.preventDefault()
      this.original_height = this.resume.style.headerSize
      this.originalY = e.pageY
      window.addEventListener('mousemove', this.resize)
      window.addEventListener('mouseup', this.stopResize)
    })
  },
  methods: {
    resize(e) {
      const height = this.original_height + (e.pageY - this.originalY)
      if (height >= -1) { this.resume.style.headerSize = height }
    },
    stopResize() {
      this.$parent.$emit('updated')
      window.removeEventListener('mousemove', this.resize)
      window.removeEventListener('mouseup', this.stopResize)
    }
  }
}
</script>

<style lang="scss">
</style>