<template>
  <div class="personnal-infos-dialog rounded-lg pa-4">
    <div class="pa-4 rounded-lg white d-flex flex-column align-center justify-center text-grey">
      <img class="wi-30 wi-sm-50" src="/images/3d/contract.png" alt="Contrat"/>
      <h4 class="mt-5 mb-4 text-center">Nous avons mis à jour nos conditions d'utilisation.</h4>
      <p class="text-center mb-2">Merci de les accepter afin de pouvoir continuer à utiliser <span class="font-weight-bold primary--text">MyJobAcademy</span>.</p>
      <j-user-form @focus="errorsPresents = false" @validate="validate" :usage="['optinMJA']" :submitWord="'Valider'" :button="{color: 'red-gradient', size: 'large'}"></j-user-form>
      <v-expand-transition>
        <v-alert v-if='errorsPresents' class="mt-5 mb-0 red lighten-2 white_ text-center">{{errorMessage}}</v-alert>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { apiURL } from '@/../config'

export default {
  name: 'optin-dialog',
  data() {
    return {
      errorsPresents: false,
      errorMessage: "Une erreur est survenue, veuillez réessayer.",
    }
  },
  methods: {
    async validate({user}) {
      try {
        this.$store.dispatch('loading')
        const recaptcha = await this.$recaptcha('picture_upload')
        await axios.post(`${apiURL}auth/me/optin`, {optin: user.optin, recaptcha})
        this.$store.dispatch('close_optin')
      } catch(e){
        this.errorsPresents = true
        this.errorMessage = "Une erreur est survenue, veuillez réessayer."
      } finally {
        this.$store.dispatch('stop_loading')
      }
    }
  }
}
</script>

<style>

</style>