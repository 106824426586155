<template>
<div class="ressources-content">
  <j-content-loader v-if="loading" message="Chargement des fichiers ..."></j-content-loader>
    <div v-if="ressources.length">
        <VueSlickCarousel class="ressources-carousel mb-5 mb-md-10 px-md-10" :arrows="true" :initialSlide="carouselIndex" @afterChange="carouselIndex = $event,$vuetify.breakpoint.mobile?selectedIndex = $event:null" :key="ressources.length" :draggable="false" :infinite="false" :slidesToShow="5" :slidesToScroll="2" :dots="false" :responsive="[{breakpoint:960,settings:{infinite:false,slidesToShow:1,slidesToScroll:1,dots:false}}]">
          <div v-for="(ressource, index) in ressources" :key="ressource._id" :class="['cursor-pointer ressource-card',selectedIndex === index&&'selected']"  @click="selectedIndex = index">
            <img :src="ressource.thumbnail">
          </div>
        </VueSlickCarousel>
        <div v-if="selected" class="d-flex flex-column flex-md-row">
          <div class="wi-40 wi-sm-100">
            <transition-group name="selected" tag="div">
              <div :key="selected._id" class="selected-card">
                <div v-for="banner in ['banner-top', 'banner-bottom']" :key="banner" :class="[banner,'d-flex justify-center white_']"><img src="/images/logo/jobacademy-white.png" alt="Logo MyJobAcademy"/><p>©</p></div>
                <img class="wi-100" :src="selected.thumbnail"/>
              </div>
            </transition-group>
          </div>
          <div class="wi-60 wi-sm-100 d-flex justify-center align-center">
            <transition-group name="selected" tag="div">
              <div :key="selected._id" v-if="isCv" class="d-flex flex-column justify-center pa-5 pa-md-10">
                <h4 class="mb-2 font-weight-bold">Téléchargez et modifiez votre CV</h4>
                <p class="mb-4">Personnalisez votre CV à l'aide de PowerPoint ou d'un logiciel équivalent.</p>
                <v-btn class="j-btn primary white_ py-6"  @click="downloadFile(selected._id)"><v-icon>mdi-download</v-icon><span>Télécharger<br>ce modèle</span></v-btn>
                <p class="text-caption text-center mb-0">Télechargement en format ppt.</p>
              </div>
              <div :key="selected._id" v-if="isCl" class="d-flex flex-column justify-center pa-5 pa-md-10">
                <div v-if="selected.style === 'colored'">
                  <h5 class="mb-4 font-weight-bold">Modèle avec couleurs pour modifications rapides</h5>
                  <p class="mb-2"><span class="font-weight-bold">Description : </span>Jaune : à modifier une fois Orange: à personnaliser à chaque fois</p>
                </div>
                <h4 class="mb-2 mt-10 font-weight-bold text-center">Téléchargez et modifiez votre lettre de motivation</h4>
                <v-btn class="j-btn primary white_ py-6" @click="downloadFile(selected._id)"><v-icon>mdi-download</v-icon><span>Télécharger<br>ce modèle</span></v-btn>
                <p class="text-caption text-center mb-0">Télechargement en format docx.</p>
              </div>
            </transition-group>
          </div>
        </div>
    </div>
</div>
</template>

<script>
import { apiURL } from '@/../config'
import axios from 'axios'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { USER_DOWNLOADED_FILE } from "@/assets/jobtimise-common/store/actions/user"

export default {
    name: 'ressources-carousel',
    props: ['isCv','isCl'],
    components: {
        VueSlickCarousel
    },
    data() {
        return {
            ressources: [],
            carouselIndex: 0,
            selectedIndex: 0,
            loading: true
        }
    },
    computed: {
        selected() {
            return this.ressources[this.selectedIndex]
        },
        fileName() {
          return decodeURIComponent(this.selected.file).split('/').pop().substring(13)
        }
    },
    created() {
        this.onCreated()
    },
    methods: {
    async onCreated() {
      try {
        const res = await axios.get(apiURL+'auth/'+(this.isCv&&'cv'||this.isCl&&'coverletters'))
        this.ressources = res.data[this.isCv&&'cv'||this.isCl&&'coverLetters'].sort((a,b)=>{
          return a.order - b.order
        })
      } catch (err) {
        this.$store.dispatch('open_dialog',{message:'Une erreur de requête est survenue',button:'Retour à l’accueil',cb:()=>{this.$router.push(`/`)}})
      } finally {
        this.loading = false
      }
    },
    async downloadFile(id) {
      const blob = await fetch(this.selected.file).then(r => r.blob())
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = this.fileName
      document.body.appendChild(a)
      a.click()
      URL.revokeObjectURL(url)
      await this.$store.dispatch(USER_DOWNLOADED_FILE,{iscv:this.isCv,iscl:this.isCl,id})
    }
  }
}
</script>

<style lang="scss">
.ressources-content {
  position: relative;
  .ressources-carousel{
      position: relative;
      padding: 0 2rem;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      @include phone{
        padding: 0;
        transform: scale(0.8);
        overflow: visible;
      }
      .slick-track{
        display: flex;
      }
      .slick-slide{
        padding: 1em;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        height: auto;
        >div{
          display: flex;
          width: 100%;
          .ressource-card {
            transition: all ease 0.7s;
            filter: drop-shadow(0px 5px 5px rgba(128, 128, 128, 0.3));
            &.selected {
              filter: drop-shadow(0px 5px 5px rgba(128, 128, 128, 0.5));
              @include noPhone {
                transform: scale(1.1,1.1);
              }
            }
            img {
              width: 100%;
            }
          }
        }
        @include phone{
          padding: 0.5em;
        }
      }
      .slick-arrow{
        position: absolute;
        left:12px;
        top:40%;
        transform: translate(42%,-50%) rotate(-45deg);
        background: transparent;
        border-radius: 0;
        color:transparent;
        height: 1rem;
        width: 1rem;
        padding: 0;
        font-size: 0;
        transition: all ease 0.3s;
        &.slick-disabled{
          display: none!important;
        }
        @include phone{
          left: 0;
          height:1rem;
          width:1rem;
          top: 50%;
          transform: translate(-150%,-50%) rotate(-45deg);
        }
        &:before{
          content: "";
          display: block;
          position: absolute;
          left:50%;
          top: 50%;
          transform: translate(-57%,-57%);
          height: 250%;
          width: 250%;
          background: rgba(grey,0.4);
          border-radius: 100%;
          @include phone{
            background: rgba(grey,0.4);
          }
        }
        &:after{
          content: "";
          display: block;
          position: absolute;
          left:50%;
          top: 50%;
          transform: translate(-50%,-50%);
          height: 100%;
          width: 100%;
          border: white solid 3px;
          border-bottom: none;
          border-right: none;
        }
        &:hover{
          @include noPhone{
            transform: translate(42%,-50%) rotate(-45deg) scale(1.1);
          }
        }
      }
      .slick-next{
        left: auto;
        right: 12px;
        transform: translate(-42%,-25%) rotate(135deg);
        @include phone{
          right: 0;
          transform: translate(150%,-50%) rotate(135deg);
        }
        &:hover{
          @include noPhone{
            transform: translate(-42%,-25%) rotate(135deg) scale(1.1);
          }
        }
      }
  }
  .selected-card {
    min-height: 50vh;
    position: relative;
    box-shadow: 0px 5px 5px rgba(128, 128, 128, 0.5);
    .banner {
      &-top {
        transform-origin: left bottom;
        top: 67.5px;
        -webkit-clip-path: polygon(25% 0%, 75% 0%, 100% 100%, 0% 100%);
        clip-path: polygon(25% 0%, 75% 0%, 100% 100%, 0% 100%);
      }
      &-bottom {
        transform-origin: right top;
        bottom: 67.5px;
        right: 0;
        -webkit-clip-path: polygon(0% 0%, 100% 0%, 75% 100%, 25% 100%);
        clip-path: polygon(0% 0%, 100% 0%, 75% 100%, 25% 100%);
      }
      &-top, &-bottom {
        background-color: $secondary;
        z-index: 1;
        width: 150px;
        position: absolute;
        transform: rotate(-45deg);
        p {
            margin: 0;
            text-align: center;
            font-size: 0.75rem;
            font-weight: bold;
            align-self: center;
            padding: 10px 0 10px 5px;
        }
        img {
          height: 0.8rem;
          margin: auto 0;
        }
      }
    }
  }
  .selected-enter-active, .selected-leave-active {
    transition-property: opacity;
    transition-duration: 0.5s;
  }
  .selected-enter-active {
    transition-delay: 0.5s;
  }
  .selected-enter, .selected-leave-active {
    opacity: 0
  }
}
</style>