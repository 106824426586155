<template>
  <div class="resume-tools-bar elevation-1 white rounded-b-xl text-grey">
    <v-breadcrumbs class="px-4 pt-4 pb-0" :items="[{text: 'Vos CV', href: '/generator/resumes'},{text: editing?`Modification CV`:'Nouveau CV', disabled: true}]" divider="/"></v-breadcrumbs>
    <div class="pa-4 pt-2 d-flex align-center justify-space-between">
      <div class="d-flex">
        <div v-for="language in languages" :key="language.slug" :class="[{'active':resume.style.language===language.slug},'flag']" @click="changeLang(language.slug)">
          <img :src="`/images/flags/${language.slug}.png`" :alt="language.alt"/>
        </div>
      </div>
      <v-text-field v-model="resume.title" @keydown="$emit('updated')" class="input-title flex-grow-0" placeholder="CV sans titre" prepend-inner-icon="mdi-pencil" outlined dense hide-details></v-text-field>
      <v-btn :disabled="saved" @click="$emit('submit')" depressed class="j-btn primary ma-0"><v-icon left>mdi-content-save</v-icon>{{saved ? 'CV enregistré' : 'Enregistrer'}}</v-btn>
    </div>
    <div class="btn-tools pa-4 d-flex">
      <v-menu bottom :close-on-content-click="false" v-model="colorMenu" nudge-bottom="50">
        <template v-slot:activator="{ on }">
          <div class="d-flex align-center">
            <v-btn text v-on="on" :class="[{'active':colorMenu},'j-btn action-btn']">Choix des couleurs <v-icon>mdi-chevron-down</v-icon></v-btn>
          </div>      
        </template>
        <div class="colorpicker-menu">
          <v-tabs v-model="tab" centered>
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-for="colorItem in colorItems" :key="colorItem.title">{{colorItem.title}}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="colorItem in colorItems" :key="colorItem.title">
              <p class="my-4 text-center text-grey font-weight-bold">{{colorItem.text}}</p>
              <v-color-picker class="mx-auto" v-model="resume.style[colorItem.slug]" @input="$emit('updated')" width="350" hide-mode-switch mode="hexa" :swatches="colorChoices" show-swatches swatches-max-height="200"></v-color-picker>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-menu>
      <div class="d-flex align-center">
        <v-btn text @click="templateMenu = !templateMenu" :class="[{'active':templateMenu},'j-btn action-btn']">Choix de modèle <v-icon>mdi-chevron-down</v-icon></v-btn>
      </div>
      <div class="d-flex align-center">
        <v-btn text @click="pictureMenu = !pictureMenu" :class="[{'active':pictureMenu},'j-btn action-btn']">Style de photo <v-icon>mdi-chevron-down</v-icon></v-btn>
      </div>
      <v-menu bottom v-model="fontMenu" :close-on-content-click="false" nudge-bottom="50">
        <template v-slot:activator="{ on }">
          <div class="d-flex align-center">
            <v-btn text v-on="on" :class="[{'active':fontMenu},'j-btn action-btn']"><v-icon>mdi-format-size</v-icon> <v-icon>mdi-chevron-down</v-icon></v-btn>
          </div>      
        </template>
        <div class="fontpicker-menu white pa-2">
          <v-btn :disabled="resume.style.fontSize <= 0.5" @click="setFont(-0.05)" icon><v-icon color="black">mdi-minus</v-icon></v-btn>
          <v-btn @click="resume.style.fontSize = 1" class="j-btn" text>défaut</v-btn>
          <v-btn :disabled="resume.style.fontSize >= 1.5" @click="setFont(0.05)" icon><v-icon color="black">mdi-plus</v-icon></v-btn>
        </div>
      </v-menu>
      <v-btn :disabled="loading" @click="$emit('generatePDF')" depressed class="j-btn white_ red-gradient mr-0">Télécharger en PDF<v-icon right>mdi-download</v-icon></v-btn>
    </div>
    <v-expand-transition>
      <div v-if="templateMenu || pictureMenu" class="resume-styles pa-4 pt-0">
        <v-divider class="ma-0"></v-divider>
        <v-slide-group v-if="templateMenu" multiple show-arrows>
          <v-slide-item v-for="(template, index) in ['v_1','v_2','v_3','v_4','v_5']" :key="template"  :class="[{'active': resume.style.version === template},'style-template ma-4 rounded-sm elevation-4']">
            <img @click="resume.style.version !== template && setStyle('template', template)" :src="`/images/resumes/resume-${template}.jpg`" :alt="`Modèle ${index+1}`"/>
          </v-slide-item>
        </v-slide-group>
        <v-slide-group v-else-if="pictureMenu" multiple show-arrows>
          <v-slide-item v-for="(style, index) in [{slug:'square',text:'Carré'},{slug:'rounded',text:'Rond'}]" :key="style.slug"  :class="[{'active': resume.style.picture === style.slug},'style-picture ma-4 mb-0']">
            <div>
              <v-avatar @click="resume.style.picture !== style.slug && setStyle('picture', style.slug)" size="125" :class="[{'rounded-lg':style.slug==='square'},'elevation-4 main-gradient-pastel']">
                <img :src="`/images/3d/coach_${index+1}.png`" :alt="`Coach ${index+1}`"/>
              </v-avatar>
              <p class="mb-0 mt-2 caption text-center">{{style.text}}</p>
            </div>
          </v-slide-item>
        </v-slide-group>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'resume-toolsbar',
  props: ['resume','editing','saved','loading'],
  data() {
    return {
      languages: [{slug: 'french', alt: 'Drapeau français'},{slug: 'english', alt: 'Drapeau anglais'}],
      typingTimeout: null,
      colorMenu: false,
      colorItems: [{title: 'Principale', text: 'Changer la couleur principale', slug: 'mainColor'},{title: 'Secondaire', text: 'Changer la couleur secondaire', slug: 'secondColor'}],
      tab: null,
      colorChoices: [['#054950','#539ACA'],['#0D5295','#A2E7DA'],['#E06665','#F4CCCC'],['#F7B16B','#FDE5CD'],['#42181A','#FFD200'],['#000000','#FFFFFF']],
      templateMenu: false,
      pictureMenu: false,
      fontMenu: false,
      fontChoices: [{slug: 0.75, text: 'Petite taille'},{slug: 1, text: 'Moyenne taille'},{slug: 1.25, text: 'Grande taille'}]
    }
  },
  methods: {
    setStyle(section, value) {
      if(section === 'template') { this.resume.style.version = value }
      else if(section === 'picture') { this.resume.style.picture = value }
      this.$emit('updated')
    },
    setFont(value) {
      this.resume.style.fontSize = this.resume.style.fontSize + value
      this.$emit('updated')
    },
    changeLang(value) {
      if(this.resume.style.language !== value) {
        this.resume.style.language = value 
        this.$emit('updated')
      }
    }
  },
  watch: {
    'colorMenu'() {
      if(this.colorMenu) {
        this.templateMenu = this.pictureMenu = this.fontMenu = false
      }
    },
    'templateMenu'() {
      if(this.templateMenu) {
        this.colorMenu = this.pictureMenu = this.fontMenu = false
      }
    },
    'pictureMenu'() {
      if(this.pictureMenu) {
        this.colorMenu = this.templateMenu = this.fontMenu = false
      }
    },
    'fontMenu'() {
      if(this.fontMenu) {
        this.colorMenu = this.templateMenu = this.pictureMenu = false
      }
    }
  }
}
</script>

<style lang="scss">
.resume-tools-bar {
  .flag {
    border-bottom: transparent 2px solid;
    padding: 2px;
    cursor: pointer;
    &.active {
      border-color: $primary;
    }
    &:hover:not(.active) {
      border-color: rgba($pastelGreen, 0.75);
    }
    &:not(.active) img {
      filter: grayscale(0.75);
    }
    &:not(:first-child) {
      margin-left: 8px;
    }
    img {
      height: 1.5rem;
    }
  }
  .input-title {
    background: rgba(0, 0, 0, 0.1);
    input {
      text-align: center;
      color: $textGrey;
      font-size: 1.5rem;
      font-weight: bold;
    }
    &:not(:hover) fieldset {
      border-color: rgba(0, 0, 0, 0);
    }
  }
  .btn-tools {
    border-top: 1px darkgray solid;
    .action-btn.active {
      color: $primary;
      .v-icon:last-child {
        transform: rotate(180deg);
      }
    }
  }
  .resume-styles {
    .style-template {
      height: 30vh;
      transition: transform ease 0.25s;
      cursor: pointer;
      &:hover:not(.active) {
        transform: scale(1.05);
      }
      &.active {
        border: $primary solid 5px;
      }
    }
    .style-picture {
      transition: transform ease 0.25s;
      cursor: pointer;
      &:hover:not(.active) {
        transform: scale(1.05);
      }
      &.active .v-avatar {
        border: $primary solid 3px;
      }
    }
  }
}
.colorpicker-menu {
  .v-color-picker__controls {
    padding-bottom: 0;
  }
  .v-color-picker .v-color-picker__swatch:last-child .v-color-picker__color:last-child {
    border: 1px #343a4088 solid;
  }
  .v-color-picker .v-color-picker__swatch .v-color-picker__color {
    height: 28px;
    max-height: 28px;
  }
}
.fontpicker-menu {
  p {
    margin: 0;
    font-size: calc(16px * var(--resumeFont-size)); 
  }
}
</style>