<template>
    <v-card
    @click="$router.push(`${link}`)"
        :title="title"
        :ripple="false"
        elevation="0"
        :img-src="displayThumbnail ? thumbnail : ''"
        tag="article"
        :class="[{'done':isDone},'session-miniature text-center rounded-lg']">
          <div class="size-contain">
            <div class="size-focus"></div>
            <div class="header">
                <div class="behind" :style="[isDone ? {'background-color': 'grey'} : {'background-color': color}]"></div>
                <div class="triangle-up-right"></div>
            </div>
          </div>
        <v-img
            v-if="displayThumbnail ? thumbnail : ''"
            :src="thumbnail"
        ></v-img>
        <div class="content d-flex flex-grow-1 d-flex flex-column py-3 px-1 align-center">
            <v-card-title class="text-uppercase font-weight-bold" :style="[isDone ? {'color': 'grey'} : {'color': color}]">{{title}}</v-card-title>
            <div v-if="objective" class="px-3 caption text-left wi-100 grey--text">
                <v-icon class="caption grey--text">mdi-bullseye</v-icon>
                {{objective}}
            </div>
            <div v-if="time" class=" px-3 caption text-left wi-100 grey--text">
                <v-icon class="caption grey--text">mdi-clock-outline</v-icon>
                {{time}}min
            </div>
            <div v-if="has_video || has_quiz" class=" px-3 caption text-left wi-100 grey--text">
                <v-icon class="caption grey--text">mdi-arrow-right-drop-circle-outline</v-icon>
                {{has_video ? "Vidéo" : ''}}
                {{has_video && has_quiz ? " & " : ''}}
                {{has_quiz ? "Quiz" : ''}}
            </div>
            <v-card-text class="py-2 text-left">
            {{shorten_description}}
            </v-card-text>
          <hr :style="[isDone ? {'background-color': 'grey'} : {'background-color': color}]" class="divider">
            <div v-if="tags && tags.length" class="mb-2 wi-100 d-flex px-3 py-1 flex-wrap">
              <span v-for="tag in tags" :key="tag">
                <v-chip v-if="index < 4" class="mr-1" x-small >{{tag}}</v-chip>
              </span>
            </div>
            <div class="flex-grow-1"></div>
            <router-link :style="[isDone ? {'color': 'grey'} : {'color': color}]" :class="[{done:isDone},'wi-90 btn-begin']" :to="link||'#'" >{{button}}<v-icon :style="[isDone ? {'color': 'grey'} : {'color': color}]">mdi-chevron-right</v-icon></router-link>
        </div>
    </v-card>
</template>

<script>

export default {
  name: 'session-miniature',
  created(){
    const sessions_done = this.$store.state.user.profile.history.sessions_done
    const sessionIndex = sessions_done.findIndex(el=>{return el._id == this._id})
    if(sessionIndex != -1){this.isDone = true}
  },
  data(){
    return {
      isDone:false
    }
  },
  props: {
    _id: String,
    title: String,
    thumbnail: String,
    description: String,
    link:String,
    time: Number,
    objective:String,
    tags:Array,
    has_video:Boolean,
    has_quiz: Boolean,
    index: {type:Number,default:0},
    displayThumbnail:{type:Boolean,default:false},
    color: String
  },
  computed:{
    button:function(){
      return this.isDone ? 'Revoir' : 'Commencer'
    },
    shorten_description(){
      return this.description.length > 120 ? this.description.slice(0,120)+"..." : this.description
    }
  }
}
</script>

<style lang="scss">
.v-card--link:before {
  background: none;
}
.session-miniature{
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: transparent!important;
  filter: drop-shadow(0px 5px 5px rgba(128, 128, 128, 0.3));
  &:hover {
    filter: drop-shadow(0px 5px 5px rgba(128, 128, 128, 0.5))!important;
    cursor: pointer;
      .btn-begin {
        transform: translatex(0.5em);
      }
  }
  .content {
    background-color: white;
  }
  .v-card{
      &__title{
        padding: 0;
        min-height: 4em;
        line-height: 1.1;
      }
  }
  .size-contain {
    position: relative;
  }
  .size-focus {
    padding-top: 20%;
  }
  .header {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .behind {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 5px 0 0 0;
    clip-path: polygon(0 0, 85% 0, 100% 76%, 100% 100%, 0 100%);
  }
  .triangle-up-right {
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    right: 0;
    padding-left: 15%;
    padding-top: 15%;
    overflow: hidden;
    border-radius: 0 15px;
    -webkit-box-shadow: -5px 5px 7px -2px rgba(0,0,0,0.3); 
    box-shadow: -5px 5px 7px -2px rgba(0,0,0,0.3);
  }
  .triangle-up-right:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    margin-left:-500px;
    margin-top:-500px;
    border-left: 500px solid color;
    border-top: 500px solid transparent;
  }
  .divider {
    height: 1px; 
    width: 80%; 
    border: none;
  }
}
</style>



