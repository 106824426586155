<template>
  <div id="resume" class="resume-v3 white elevation-1 mx-auto my-8 d-flex flex-column" >
    <div :style="`padding: ${resume.style.headerSize/2}px 0;`" class="resume-header d-flex">
      <div :class="[resume.description?'wi-65':'wi-100','resume-profile-container']">
        <j-resume-profile :resume="resume"></j-resume-profile>
      </div>
      <div class="resume-details-container wi-35 d-flex flex-column">
        <j-resume-details :resume="resume" :details="false" background></j-resume-details>
      </div>
      <j-resume-header-resize :resume="resume"></j-resume-header-resize>
    </div>
    <div class="resume-content d-flex flex-grow-1">
      <div class="resume-large wi-65">
        <div :class="[{'flex-column-reverse align-self-start':resume.style.educationsFirst},'content-container d-flex flex-column']">
          <div :class="[{'first-content':!resume.style.educationsFirst},'wi-100 experiences-container']">
            <div class="resume-title main-color d-flex align-center">
              <p>{{lang.experiences}}</p>
              <v-btn @click="$emit('changeOrderLarge')" small class="btn-first j-btn white">{{resume.style.educationsFirst?'Monter':'Descendre'}}<v-icon right>mdi-arrow-{{resume.style.educationsFirst?'up':'down'}}</v-icon></v-btn>
            </div>
            <j-resume-timeline :resume="resume" section="experiences" :lang="lang" opposite></j-resume-timeline>
          </div>
          <div :class="[{'first-content':resume.style.educationsFirst},'wi-100 educations-container']">
            <div class="resume-title main-color d-flex align-center">
              <p>{{lang.educations}}</p>
              <v-btn @click="$emit('changeOrderLarge')" small class="btn-first j-btn white">{{resume.style.educationsFirst?'Descendre':'Monter'}}<v-icon right>mdi-arrow-{{resume.style.educationsFirst?'down':'up'}}</v-icon></v-btn>
            </div>
            <j-resume-timeline :resume="resume" section="educations" :lang="lang" opposite></j-resume-timeline>
          </div>
        </div>
      </div>
      <div class="resume-lean wi-35">
        <j-resume-details :resume="resume" :description="false" transparent></j-resume-details>
        <j-resume-lean :lang="lang" :resume="resume" titleClass="main-color"></j-resume-lean>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['resume','lang'],
  name: 'resume-v_3'
}
</script>

<style lang="scss">
#resume.resume-v3 {
  .resume-large {
    padding-right: 24px;
    border-top: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
    .content-container .first-content {
      border-bottom: 1px solid #E0E0E0;
    }
  }
  .resume-lean {
    padding-left: 8px;
    border-top: 1px solid #E0E0E0;
  }
}
</style>