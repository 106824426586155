<template>
<div class="content-card-course">
    <div :class="[{finished:isDone},'coursePreview rounded-lg overflow-hidden']">
        <div class="size-contain">
            <div class="size-focus"></div>
            <div class="header">
                <v-icon v-if="isDone" class="mobile" color="white">mdi-check</v-icon>
                <div :style="[isDone ? {'background-color': 'grey'} : {'background-color': course.color}, {'filter' : 'brightness(0.8)'}]" class="behind"></div>
                <div class="mid-first"></div>
                <div class="mid-second"></div>
                <div :style="[isDone ? {'background-color': 'grey'} : {'background-color': course.color}]" class="ahead"></div>
            </div>
        </div>
        <div class="content flex-grow-1 d-flex flex-column">
            <div class="cardClickable" @click="$router.push(`/courses/${course._id}/sessions/${get_next_session(course)._id}`)">
                <header :style="[isDone ? {'color': 'grey'} : {'color': course.color}]" class="d-flex flex-column pa-2 text-center">
                    <img class="thumbnail rounded-lg" :src="course.thumbnail"/>
                    <h2 class="pt-2">{{course.title}}</h2>
                    <h3 class="pt-2">{{course.tagline}}</h3>
                </header>
                <div class="extract flex-grow-1 d-flex flex-column px-5 py-2" >
                    <div v-if="course.list_of_sessions.length"  class="caption text-left wi-100 grey--text">
                        <v-icon class="caption grey--text">mdi-check-circle-outline</v-icon>
                        {{course.list_of_sessions.length}} ateliers
                    </div>
                    <div v-if="time>0"  class="caption text-left wi-100 grey--text">
                        <v-icon class="caption grey--text">mdi-clock-outline</v-icon>
                        {{time}}min
                    </div>
                    <p class="mt-2 mb-0 text-left wi-100 caption">
                        {{course.short_description}}
                    </p>
                    <hr :style="[isDone ? {'background-color': 'grey'} : {'background-color': course.color}]" class="divider">
                    <div class="mb-2 d-flex flex-wrap wi-100">
                        <span v-for="(tag,index) in course.tags" :key="tag+index">
                            <v-chip  v-if="index < 6" color="lightgrey" class="mr-1 mb-1" x-small >{{tag}}</v-chip>
                        </span>
                    </div>
                </div>
            </div>
            <div class="flex-grow-1"></div>
            <div class="courseBtns px-5 pt-2 pb-3">
                <div v-if="course.list_of_sessions.length"  class="d-flex wi-100 justify-center align-items-center flex-column-reverse flex-md-row">
                    <router-link :style="[isDone ? {'color': 'grey'} : {'color': course.color}]" class="btn-begin mt-1" :to="`/courses/${course._id}/sessions/${get_next_session(course)._id}`">
                        <span v-if="!course_started(course)">Commencer la formation</span>
                        <span v-else-if="course_started(course) < course.list_of_sessions" >Continuer la formation</span>
                        <span v-else >Revoir la formation</span>
                        <v-icon :style="[isDone ? {'color': 'grey'} : {'color': course.color}]">mdi-chevron-right</v-icon>
                    </router-link>
                    <v-hover v-slot="{ hover }">
                    <button @click="$emit('preview',course)" :style="[hover ? {'color': 'white', 'background-color': course.color} : {'color': course.color}, {'border': '2px solid ' + course.color}]" class="py-1 px-3 j-btn ma-0 ml-md-auto btn-details">Détails</button>
                    </v-hover>
                </div>
                <p class="red" v-else>Bientôt disponible</p>
            </div>
            <v-icon v-if="isDone" class="desktop" color='white' :style="`background-color : ${course.color}`">
                mdi-check
            </v-icon>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'course-card',
  props: ['course'],
  created(){
  },
  data(){
    return {
        showSession: false,
        isDetailsMouseOver: false
    }
  },
  methods:{
    get_next_session(course){
        const list_of_sessions = course.list_of_sessions
        const all_done = this.$store.state.user.profile.history.sessions_done
        if(this.course_started(course) && this.course_started(course) < course.list_of_sessions){
            let loop = true, i=1, next_session = list_of_sessions[0]
            while(loop == true && i < list_of_sessions.length){

                next_session = list_of_sessions[i]
                if(all_done.filter(el=>{return el._id == list_of_sessions[i]._id }).length == 0){
                    loop = false
                }
                i++
            }
            return next_session
        }else{
            return list_of_sessions[0]
        }
    },
    course_started(course){
        const all_done = this.$store.state.user.profile.history.sessions_done
        const done_in_course = all_done.filter(el=>{return el.course == course._id})
        const res = done_in_course.length == 0 ? false : done_in_course
        return res 
    },
  },
  computed:{
      time(){
        if(!this.course.list_of_sessions.length)return 0
        return this.course.list_of_sessions.reduce((total,current)=>{
            return typeof current.time === "undefined" ? total : total + current.time
        },0)
      },
      isDone(){
          return this.course_started(this.course) >= this.course.list_of_sessions
      },
      isDetailsMouseOverFct() {
          return this.isDetailsMouseOver
      }
  }
}
</script>

<style lang="scss" >
[aria-expanded=false]{
    .open{
        display: none;
    }
}
[aria-expanded=true]{
    .collapsed{
        display: none;
    }
}

.coursePreview{ 
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    filter: drop-shadow(0px 5px 5px rgba(128, 128, 128, 0.3));
    @include phone{
        height: auto;
    }
    &:hover {
      filter: drop-shadow(0px 5px 5px rgba(128, 128, 128, 0.5))!important;
        .mid-first {
            visibility: visible;
            height: 90%; 
        }
        .mid-second {
            visibility: visible;
            height: 70%;
        }
    }
    .cardClickable:hover {
        cursor: pointer;
        &~.courseBtns .btn-begin {
                transform: translatex(0.5em);
            }
    }
    .content {
        background-color: white;
    }
    .btn-details {
        transition: all ease 0.5s;
    }
    header{
        position: relative;
        img.thumbnail {
            max-height: 10rem;
            width: 100%;
            object-fit: cover;
            object-position: center top;
        }
        .bg-color {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 0;
            pointer-events: none;
        }
        .bg-color{
            opacity: 0.8;
        }
        *{
            z-index: 1;
        }
        h2{
            font-weight: 200;
            text-transform: uppercase;
            margin: 0;
        }
        h3{
            margin:0;
            font-weight: 200;
            font-size: 1rem!important;
        }
        p{
            margin:0;
            font-size: 1rem!important;
            font-weight: 200;
        }
    }
    .extract{
        p{
            max-height: 3.2em;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    i.done{
        display: none;
    }
    
    .v-icon.mdi-check {
        &.desktop {
            position: absolute;
            display: flex;
            align-items: center;
            top: 0;
            left: 0;
            color: white;
            font-size: 2em;
            z-index: 0;
            height: 100%;
            padding: 0 0.2em;
            @include phone{
                display: none;
            }
        }
        &.mobile {
            display: none;
            @include phone{
                display: flex;
                position: absolute;
                z-index: 10;
                top: 0;
                left: 0;
                color: white;
                font-size: 2em;
                padding: 0 0.2em;
            }
        }
    }
    &.finished{        
        .btn{
            background: grey!important;
            border-color:grey!important;
        } 
        @include noPhone{
            .extract, header, .courseBtns{
                padding-left: 4em!important;
            }   
        }
    }
    .size-contain {
    position: relative;
    }
    .size-focus {
        padding-top: 20%;
    }
    .header {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .behind {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 5px 0 0 0;
    clip-path: polygon(0 0, 30% 0, 45% 35%, 90% 40%, 90% 100%, 0 100%);
    }
    .mid-first, .mid-second {
    visibility: hidden;
    position: absolute;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    transition: height 1s, visibility 1s;
    bottom: 0;  
    width: 95%;
    border-radius: 5px 5px 0 0;
    }
    .mid-first {
    background: rgba(216,229,216);
    height: 0em;
    }
    .mid-second {
    background: rgba(240,255,240);
    height: 0em;
    }
    .ahead {
    position: absolute;                 
    bottom:0;                         
    height: 65%;
    width: 100%;
    border-radius: 0 10px 0 0;
    clip-path: polygon(0 50%, 30% 50%, 45% 0, 100% 0, 100% 100%, 0 100%);
    }
}

.coursesList{
    padding: 2em ;
    padding-bottom: 1em;
    display: flex;
    width: 100%;
    overflow-y: hidden ;
    height: auto;
    overflow-x: auto;
    flex-wrap: nowrap;

    @include phone {
        flex-wrap: wrap;
    }
    .owl-theme .owl-dots .owl-dot{
        border: none;
        outline: none;
        span{
            background: white;
        }
        &.active{
            span{
                background: $secondary;
            }
        }
    } 

    .owl-stage{
        display: flex;
    }

    .owl-item{
        display: flex;
        flex-direction: column;
        height: auto;
        max-height: none;
        margin: 0 1em;
        &:first-child{
            @include noPhone{
                margin-left: 0;
            }
        }
    }

}
.owl-theme .owl-dots .owl-dot:not(.active) span{
    background: white!important;
}


</style>


