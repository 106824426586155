import Vue from 'vue';
import store from '@/store'
import {apiURL} from '@/../config';
import axios from 'axios';

  const state = {
    display: false,
    content: {
        message:'',
        alt: ''
    },
    cb: ()=>{
      console.log('Im a callback')
    },
    altCb: ()=>{
      console.log('Im an alt callback')
    }
  }
  
  
  const actions = {
    open_collectives_infos: async (context,payload)=>{
        if (store.getters.isAuthenticated && (store.getters.isCustomer || store.getters.isPremium)) {
          let isDisplayed = false
          const now = new Date()
          if (localStorage.getItem('seen-collectives-infos')) {
              const seen = new Date(localStorage.getItem('seen-collectives-infos'))
              if(seen.getTime() <= (now.getTime() - 604800000)) isDisplayed = true
          } else isDisplayed = true
          if (isDisplayed) {
            var weekBegin = new Date()
            weekBegin.setDate(weekBegin.getDate() - (weekBegin.getDay()-1))
            weekBegin.setHours(0,0,0,0);
            const resCollective = await axios.get(apiURL+'auth/collectives')
            const cFounded = resCollective.data.collectives.find(c => {
              const d = new Date(c.date)
              return d.getTime() >= (now.getTime() - 86400000) && d.getTime() < (weekBegin.getTime() + 604800000)
            })
            if(cFounded) setTimeout(()=>{context.commit('open_collectives_infos',payload)},5000)
          }
        }
    },
    close_collectives_infos: context=>{
        context.commit('close_collectives_infos');
    }
  };
  
  const mutations = {
    open_collectives_infos: (state,payload)=>{
      const collectivesInfos ={
            content:{
                message: payload.message || '',
                alt: payload.alt || ''
            },
            cb: payload.cb || '',
            altCb: payload.altCb || ''
        }
        Vue.set(state,'display',true);
        Vue.set(state,'content',collectivesInfos.content);
        Vue.set(state,'cb',collectivesInfos.cb);
    },
    close_collectives_infos: state=>{
        Vue.set(state,'display',false);
    }
  }
  
  export default {
    state,
    actions,
    mutations
  }