<template>
<div class="ads-banner d-flex flex-column flex-md-row">
    <div class="wi-50 wi-sm-100">
        <div class="BGlightgrey-opacity wi-100 pa-5 rounded-xl rounded-bl-0">
            <h3 class="mb-0 text-center font-weight-medium text-grey">{{message}}</h3>
        </div>
        <div class="triangle"></div>
    </div>
    <div class="wi-50 wi-sm-100 d-flex justify-center align-center">
        <div class="wi-80 d-flex flex-column align-center">
            <h4 class="mb-4 text-grey text-center">{{infoCta}}</h4>
            <a class="white_" :href="link" target="_blank"><v-btn class="j-btn red-gradient white_">{{cta}}</v-btn></a>
        </div>
    </div>
</div>  
</template>

<script>
export default {
  name: 'ads-banner',
  props:['message','infoCta','cta','link'],
  data(){
    return {}
  }
}
</script>

<style lang="scss">
.ads-banner {
  .triangle {
    width: 0;
    height: 0;
    margin-right: auto;
    padding-right: 7%;
    padding-top: 7%;
    overflow: hidden;
  }
  .triangle:after {
    content: "";
      display: block;
      width: 0;
      height: 0;
      margin-right:-500px;
      margin-top:-500px;
      border-right: 500px solid transparent;
      border-top: 500px solid rgba(211, 211, 211, 0.5);
  }
}
</style>