<template>
  <div class="lean-content">
    <div v-for="(section, index) in order" :key="section">
      <div v-if="section === 'languages' && !onlySkills" class="languages-container">
        <div :class="[titleClass, 'resume-title']">
          <p>{{lang.languages}}</p>
          <div class="btn-first he-100">
            <v-btn v-if="index !== 0" @click="changeOrder('languages', -1, index)" x-small class="wi-100 j-btn white my-auto">Monter <v-icon class="ml-auto" right>mdi-arrow-up</v-icon></v-btn>
            <v-btn v-if="index !== order.length-1" @click="changeOrder('languages', 1, index)" x-small class="wi-100 j-btn white my-auto">Descendre <v-icon right>mdi-arrow-down</v-icon></v-btn>
          </div>  
        </div>
        <div v-if="divider" :class="[dividerClass, 'resume-divider']"></div>
        <j-resume-list :resume="resume" section="languages"></j-resume-list>
      </div>
      <div v-if="section === 'skills' && (isSkills || onlySkills)" class="skills-container">
        <div v-if="!onlySkills" :class="[titleClass, 'resume-title']">
          <p>{{lang.skills}}</p>
          <div class="btn-first he-100">
            <v-btn v-if="index !== 0" @click="changeOrder('skills', -1, index)" x-small class="wi-100 j-btn white my-auto">Monter <v-icon class="ml-auto" right>mdi-arrow-up</v-icon></v-btn>
            <v-btn v-if="index !== order.length-1" @click="changeOrder('skills', 1, index)" x-small class="wi-100 j-btn white my-auto">Descendre <v-icon right>mdi-arrow-down</v-icon></v-btn>
          </div>            
        </div>
        <div v-if="divider" :class="[dividerClass, 'resume-divider']"></div>
        <j-resume-list :resume="resume" section="skills" :large="onlySkills" :background="background"></j-resume-list>
      </div>
      <div v-if="section === 'computings' && !onlySkills && resume.style.tech" class="computings-container">
        <div :class="[titleClass, 'resume-title']">
          <p>{{!!resume.style.computingsName ? resume.style.computingsName : lang.computings}}</p>
          <div class="btn-first he-100">
            <v-btn v-if="index !== 0" @click="changeOrder('computings', -1, index)" x-small class="wi-100 j-btn white my-auto">Monter <v-icon class="ml-auto" right>mdi-arrow-up</v-icon></v-btn>
            <v-btn v-if="index !== order.length-1" @click="changeOrder('computings', 1, index)" x-small class="wi-100 j-btn white my-auto">Descendre <v-icon right>mdi-arrow-down</v-icon></v-btn>
          </div>
        </div>
        <div v-if="divider" :class="[dividerClass, 'resume-divider']"></div>
        <j-resume-list :resume="resume" section="computings" :background="background"></j-resume-list>
      </div>
      <div v-if="section === 'interests' &&!onlySkills" class="interests-container">
        <div :class="[titleClass, 'resume-title']">
          <p>{{lang.interests}}</p>
          <div class="btn-first he-100">
            <v-btn v-if="index !== 0" @click="changeOrder('interests', -1, index)" x-small class="wi-100 j-btn white my-auto">Monter <v-icon class="ml-auto" right>mdi-arrow-up</v-icon></v-btn>
            <v-btn v-if="index !== order.length-1" @click="changeOrder('interests', 1, index)" x-small class="wi-100 j-btn white my-auto">Descendre <v-icon right>mdi-arrow-down</v-icon></v-btn>
          </div>
        </div>
        <div v-if="divider" :class="[dividerClass, 'resume-divider']"></div>
        <j-resume-list :resume="resume" section="interests"></j-resume-list>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    resume: Object,
    lang: Object,
    titleClass: String,
    dividerClass: String,
    background: {
      type: Boolean,
      default: ()=>{return false}
    },
    divider: {
      type: Boolean,
      default: ()=>{return false}
    },
    isSkills: {
      type: Boolean,
      default: ()=>{return true}
    },
    onlySkills: {
      type: Boolean,
      default: ()=>{return false}
    }
  },
  name: 'resume-lean',
  computed: {
    isTech() {
      return this.resume.style.tech
    },
    order() {
      const order = JSON.parse(JSON.stringify(this.resume.style.order))
      if(!this.isSkills) {
        const index = order.findIndex(i => { return i === 'skills'})
        order.splice(index, 1)
      }
      if(!this.isTech) {
        const index = order.findIndex(i => { return i === 'computings'})
        order.splice(index, 1)
      }
      return order
    }
  },
  methods: {
    changeOrder(item, to, index) {
      const newOrder = this.order
      newOrder.splice(index, 1)
      newOrder.splice(index + to, 0, item)
      if(!this.isSkills) { newOrder.push('skills') }
      if(!this.isTech) { newOrder.push('computings') }
      this.resume.style.order = JSON.parse(JSON.stringify(newOrder))
      this.$parent.$emit('updated')
    }
  }
}
</script>

<style lang="scss">
.lean-content {
  .resume-divider {
    margin: auto!important;
    height: 2px;
    width: 80%;
  }
  >div:last-child .resume-list {
    padding-bottom: 0!important;
  }
}
</style>