<template>
  <div class="d-flex resume-profile flex-grow-1">
    <v-dialog v-model="pictureDialog" persistent width="50%">
      <j-picture @close="pictureDialog = false" @updatePicture="getBase64()"></j-picture>
    </v-dialog>
    <v-hover v-if="profile" v-slot:default="{ hover }">
      <v-avatar @click="pictureDialog = true" :size="`${size}px`" :class="[{'loading-image':loadingImage,'rounded-lg':resume.style.picture==='square'},'ma-auto resume-picture cursor-pointer']">
        <img v-if="!loadingImage" :src="base64 || '/images/3d/profile.png'" alt="Photo de profil">
        <v-skeleton-loader v-else type="image" class="main-gradient-pastel wi-100"></v-skeleton-loader>
        <v-fade-transition>
          <v-overlay v-if="hover" absolute color="grey">
            <v-icon large>mdi-pencil</v-icon>
          </v-overlay>
        </v-fade-transition>
      </v-avatar>
    </v-hover>
    <v-hover v-if="name" v-slot="{ hover }">
      <div :class="[{'has-background':background},'resume-section d-flex flex-column justify-center flex-grow-1']">
        <div class="wi-auto mx-auto">
          <p :class="[getFirstname.length + getLastname.length < 16 && 'text-center', 'resume-names']">{{getFirstname}}<br v-if="getFirstname.length + getLastname.length > 16"/> {{getLastname}}</p>
          <p v-if="resume.subtitle" class="resume-profile-title text-center font-weight-bold">{{resume.subtitle}}</p>
          <p v-if="resume.softskills.length" class="resume-softskills resume-p text-center">
            <span v-for="(skill, index) in resume.softskills" :key="index">
              {{index > 0 ? ' - ' : ''}}{{skill}}
            </span>
          </p>
        </div>
        <v-scroll-y-reverse-transition>
          <v-btn v-if="hover" @click="$parent.$emit('openDialog', 'profile', null)"  class="absolute add-btn j-btn secondary">Modifier</v-btn>
        </v-scroll-y-reverse-transition>
      </div>
    </v-hover>
  </div>
</template>

<script>
export default {
  props: {
    resume: {
      type: Object
    },
    background: {
      type: Boolean,
      default: ()=> {return false}
    },
    name: {
      type: Boolean,
      default: ()=> {return true}
    },
    profile: {
      type: Boolean,
      default: ()=> {return true}
    },
    size: {
      type: Number,
      default: ()=> {return 150}
    }
  },
  name: 'resume-profile',
  data() {
    return {
      loadingImage: false,
      pictureDialog: false,
      base64: null
    }
  },
  async created() {
    this.getBase64()
  },
  methods: {
    async getBase64() {
      this.loadingImage = true
      this.$parent.$emit('loading')
      if(this.$store.getters.getPicture) {
        const data = await fetch(this.$store.getters.getPicture, {cache: 'no-store'})
        const blob = await data.blob()
        this.base64 = await new Promise((resolve) => {
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onloadend = () => { resolve(reader.result) }
        })
      }
      this.loadingImage = false
      this.$parent.$emit('stop_loading')
    }
  },
  computed: {
    getFirstname() {
      return this.resume.firstname ? this.resume.firstname : this.$store.getters.getFirstname
    },
    getLastname() {
      return this.resume.lastname ? this.resume.lastname : this.$store.getters.getLastname
    }
  }
}
</script>

<style lang="scss">
.resume-profile {
  padding: 16px;
  .resume-section {
    margin-left: 8px;
    padding: 0 8px;
    transition: all ease 0.25s;
    &:hover {
      background-color: rgba(197, 197, 197, 0.5)!important;
    }
    &.has-background {
      .resume-names, .resume-softskills {
        color: white;
      }
    }
    &:not(.has-background) {
      .resume-names {
        color: var(--resumeMain-color);
      }
    }
    .resume-names {
      font-size: calc(42.5px * var(--resumeFont-size));
      margin: 0;
      font-weight: bold;
      line-height: 1!important;
    }
    .resume-profile-title {
      color: var(--resumeSecond-color);
      font-size: calc(20px * var(--resumeFont-size));
      margin: 0;
      padding: 0;
    }
  }
}
</style>