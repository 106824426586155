<template>
  <div class="resume-helper">
    <div class="mb-4 d-flex align-center">
      <img class="icon-img" src="/images/3d/question.png" alt="question"/>
      <h5 class="mb-0 ml-4 text-grey">{{title}}</h5>
    </div>
    <div class="flex-grow-1 helper-content rounded-lg pa-4">
      <h4 class="mb-4 font-weight-bold text-grey">Nos conseils</h4>
      <v-carousel v-if="content && content.length" touchless height="auto" v-model="index" :show-arrows="false" :hide-delimiters="true">
        <v-carousel-item v-for="(item, i) in content" :key="i" content-class="d-flex flex-column border-grey pa-2 rounded-lg">
          <div class="text-grey" v-html="content[index]"></div>
        </v-carousel-item>
      </v-carousel>
      <div v-if="video" class="container--video">
        <j-vimeo-embed class="he-100" :video="video"></j-vimeo-embed>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title','video','content','index'],
  name: 'resume-form-helper'
}
</script>

<style lang="scss">
.resume-helper {
  height: 100%!important;
  overflow-y: scroll;
  padding-right: 16px!important;
  .icon-img {
    height: 2rem;
    background: white;
    border-radius: 9999px;
    padding: 2px;
  }
  .helper-content {
    background: rgba(255, 255, 255, 0.75);
  }
}
</style>