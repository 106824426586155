<template>
  <div class="mail-edit pa-4 main-gradient-pastel rounded-lg">
    <div class="pa-4 text-grey text-center white rounded-lg">
      <div class="d-flex justify-space-between align-center mb-4">
        <h4 class="mb-0 font-weight-bold">Modifier le mail :</h4>
        <v-icon @click="$emit('close')" large>mdi-close</v-icon>
      </div>
      <quill-editor :content="bodyHtml" @change="setContent($event.text)" :options="editorOption"></quill-editor>
      <a :href="`mailto:?subject=${subject}&body=${bodyMailto}`" target="_blank" rel="noopener"><v-btn class="j-btn primary white_ mt-4">Ouvrir votre<br v-if="$vuetify.breakpoint.smAndDown"/> application de mail</v-btn></a>
      <p class="my-2 mb-md-0 text-caption">ou</p>
      <v-btn text class="j-btn secondary--text" @click="copyMail()">Copier l'objet<br v-if="$vuetify.breakpoint.smAndDown"/> du mail<v-icon right>mdi-content-copy</v-icon></v-btn>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor, Quill } from 'vue-quill-editor'
import BlotFormatter from 'quill-blot-formatter'
Quill.register("modules/blotFormatter", BlotFormatter)

export default {
  name: 'mail-edit',
  props: ['body','subject'],
  components: {
    quillEditor
  },
  data() {
    return {
      editorOption:{
        modules: {
          toolbar: {
            container:[
              ['clean']
            ]
          },
          blotFormatter: {}
        },
        placeholder: 'Rédaction de mail'
      },
      bodyHtml: null,
      bodytext: null,
      bodyMailto: null
    }
  },
  mounted() {
    this.bodyHtml = this.body
  },
  methods: {
    copyMail: async function(){
      try {
        await navigator.clipboard.writeText(this.bodyText)
        alert('Mail copié')
      } catch(err) {
        alert('La copie du mail a échoué')
      }
    },
    setContent: function(text){
      this.bodyMailto = text.replace(/\n/g,'%0D%0A').replace(/\r/g,'%0D%0A');
      this.bodyText = text
    }
  }
}
</script>

<style lang="scss">
.mail-edit {
  .ql-container.ql-snow {
    height: 35vh!important;
  }
}
</style>