import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: { 
        light: true ,
        themes :{
            light: {
                primary: '#66C0A6',
                secondary: '#55BAE8',
                grey: '#808080',
                accent: '#7E5899',
                error: '#E66C9B',
                alert: '#fd8f9e',
            }
        }
    }
});
