import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import { registerApp, coach, collective, partnerapp, course, hiddenOffer, commonQuestions, logbookAccess } from '@/../config'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next, cb) => {
  if (store.getters.isAuthenticated) {
    if(cb) return cb(to, from, next)
    next()
    return
  }
  store.dispatch('set_pending', to.fullPath)
  next('/login')
}

const checkCustomer = (to, from, next) => {
  if (store.getters.isCustomer) {
      next()
      return
  }
  next('/')
}

const checkPremium = (to, from, next) => {
  const url = to.path.split('/')
  if (store.getters.isCustomer || store.getters.isPremium) {
      next()
      return
  }
  next('/register-infos/'+url[1])
}

const checkSubscriber = (to, from, next) => {
  const url = to.path.split('/')
  if (store.getters.isCustomer || store.getters.isSubscriber) {
    next()
    return
  }
  next('/register-infos/'+url[1])
}

const checkNoCustomer = (to, from, next) => {
  if (!store.getters.isCustomer) {
    next()
    return
  }
  next('/')
}

const kick = (to, from, next) => next('/')

function meta(title){
  const hyphen = title ? ' - ':''
  return{
    title: `${title}${hyphen} MyJobAcademy`,
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: meta('Accueil')
  },
  {
    path: '/courses',
    name: 'Courses',
    component: () => import('../views/courses/all/All.vue'),
    beforeEnter: (to, from, next) => course ? ifAuthenticated(to, from, next, checkCustomer) : kick(to, from, next),
    meta: meta('Formations')
  },
  {
    path: '/courses/:courseId/sessions/:sessionId',
    name: 'Course Session',
    component: () => import('../views/sessions/One.vue'),
    beforeEnter: (to, from, next) => course ? ifAuthenticated(to, from, next, checkCustomer) : kick(to, from, next),
    meta: meta('Atelier')
  },
  {
    path: '/sessions/:sessionId',
    name: 'Single Session',
    component: () => import('../views/sessions/One.vue'),
    beforeEnter: (to, from, next) => course ? ifAuthenticated(to, from, next, checkCustomer) : kick(to, from, next),
    meta: meta('Atelier')
  }, 
  {
    path: '/journeys',
    name: 'Journeys',
    component: () => import('../views/journeys/All.vue'),
    beforeEnter: (to, from, next) => ifAuthenticated(to, from, next, checkSubscriber),
    meta: meta('Parcours')
  },
  {
    path: '/journeys/:courseId/sessions/:sessionId',
    name: 'Journey Session',
    component: () => import('../views/sessions/One.vue'),
    beforeEnter: (to, from, next) => ifAuthenticated(to, from, next, checkSubscriber),
    props: {isJourney: true},
    meta: meta('Parcours')
  },
  {
    path: '/journeys/:courseId',
    name: 'Journey',
    component: () => import('../views/journeys/One.vue'),
    beforeEnter: (to, from, next) => ifAuthenticated(to, from, next, checkSubscriber),
    meta: meta('Parcours')
  },
  {
    path: '/journeys/:courseId/quiz/:stepId',
    name: 'Quiz',
    component: () => import('../views/journeys/Quiz.vue'),
    beforeEnter: (to, from, next) => ifAuthenticated(to, from, next, checkSubscriber),
    meta: meta('Quiz')
  },
  {
    path: '/collectives',
    name: 'Collectives',
    component: () => import('../views/Collectives.vue'),
    beforeEnter: (to, from, next) => collective === true ? ifAuthenticated(to, from, next, checkPremium) : kick(to, from, next),
    meta: meta('Ateliers collectifs')
  },
  {
    path: '/coaches',
    name: 'Coaches',
    component: () => import('../views/Coaches.vue'),
    beforeEnter: coach === false && kick,
    meta: meta('Coachs')
  },
  {
    path: '/hiddenoffers',
    name: 'Hidden Offers',
    component: () => import('../views/HiddenOffers.vue'),
    beforeEnter: (to, from, next) => hiddenOffer ? ifAuthenticated(to, from, next, checkSubscriber) : kick(to, from, next),
    meta: meta('Offres cachées')
  },
  {
    path: '/cv',
    name: 'CV',
    component: () => import('../views/Ressources.vue'),
    beforeEnter: (to, from, next) => ifAuthenticated(to, from, next, checkSubscriber),
    props: {isCv: true},
    meta: meta('CV')
  },
  {
    path: '/coverletters',
    name: 'Cover Letters',
    component: () => import('../views/Ressources.vue'),
    beforeEnter: (to, from, next) => ifAuthenticated(to, from, next, checkSubscriber),
    props: {isCl: true},
    meta: meta('Lettres de motivation')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register/Form.vue'),
    beforeEnter: registerApp === true ? ifNotAuthenticated : kick,
    meta: meta('Inscription')
  },
  {
    path: '/partnership/:partner',
    name: 'Partnership',
    component: () => import('../views/Partnership.vue'),
    beforeEnter: partnerapp === true ? ifNotAuthenticated : kick,
    meta: meta('Partenaire')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    beforeEnter: ifNotAuthenticated,
    meta: meta('Connexion')
  },
  {
    path: '/register-infos',
    name: 'Register Infos',
    component: () => import('../views/Register.vue'),
    beforeEnter: registerApp === true ? ifNotAuthenticated : kick,
    meta: meta('Informations')
  },
  {
    path: '/register-infos/:product',
    name: 'Register Infos Product',
    component: () => import('../views/Product.vue'),
    beforeEnter: registerApp === true ? (to, from, next) => ifAuthenticated(to, from, next, checkNoCustomer) : kick,
    meta: meta('Infos produit')
  },
  {
    path: '/activate-account/:activationToken',
    name: 'Activate',
    component: () => import('../views/Activate.vue'),
    meta: meta('Activation')
  },
  {
    path: '/forgot-password',
    name: 'Forgotpassword',
    props: {activate: false},
    component: () => import('../views/Forgotpassword.vue'),
    meta: meta('Mot de passe oublié')
  },
  {
    path: '/send-activation',
    name: 'Send activation',
    component: () => import('../views/Forgotpassword.vue'),
    props: {activate: true},
    meta: meta('Activation de compte')
  },
  {
    path: '/forgot-password/:forgotPasswordToken',
    name: 'Resetpassword',
    component: () => import('../views/Resetpassword.vue'),
    meta: meta('Réinitialisation')
  },
  {
    path: '/user/me',
    name: 'My Account',
    component: () => import('../assets/jobtimise-common/views/user/Account.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Mon Compte')
  },
  {
    path: '/user/dashboard',
    name: 'My Dashboard',
    component: () => import('../views/Dashboard.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Mon Tableau de Bord')
  },
  {
    path: '/user/job-applications/:userId',
    name: 'My Logbook',
    component: () => import('../assets/jobtimise-common/views/myjobacademy/candidatures/Logbook.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Mon Tableau de bord')
  },
  {
    path: '/user/job-applications/:userId/new',
    name: 'New Candidature',
    component: () => import('../assets/jobtimise-common/views/myjobacademy/candidatures/Candidature.vue'),
    beforeEnter: logbookAccess === true ? ifAuthenticated : kick,
    meta: meta('Nouvelle Candidature')
  },
  {
    path: '/user/job-applications/:userId/edit/:candidatureId',
    name: 'My Candidature',
    component: () => import('../assets/jobtimise-common/views/myjobacademy/candidatures/Candidature.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Ma Candidature')
  },
  {
    path: '/user/documents',
    name: 'My Documents',
    component: () => import('../views/Documents.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Mes documents')
  },
  {
    path: '/generator/resumes',
    name: 'Resume Creator',
    component: () => import('../views/generator/resumes/All.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Créateur de CV')
  },
  {
    path: '/generator/resumes/new',
    name: 'New resume',
    component: () => import('../views/generator/resumes/New.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Création de CV')
  },
  {
    path: '/generator/resumes/new/:duplicatedId',
    name: 'Duplicated resume',
    component: () => import('../views/generator/resumes/New.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Création de CV')
  },
  {
    path: '/generator/resumes/edit/:resumeId',
    name: 'Edit resume',
    component: () => import('../views/generator/resumes/New.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Modification de CV')
  },
  {
    path: '/generator/introductions',
    name: 'All introductions',
    component: () => import('../views/generator/introductions/All.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Vos présentations')
  },
  {
    path: '/generator/introductions/new',
    name: 'New introduction',
    component: () => import('../views/generator/introductions/New.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Nouvelle présentation')
  },
  {
    path: '/generator/introductions/edit/:introductionId',
    name: 'Edit introduction',
    component: () => import('../views/generator/introductions/Edit.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Modification de présentation')
  },
  {
    path: '/generator/questions',
    name: 'All common questions',
    component: () => import('../views/generator/questions/CommonQuestions.vue'),
    beforeEnter: commonQuestions === true ? ifAuthenticated : kick,
    meta: meta('Les questions fréquentes en entretien')
  },
  {
    path: '/user/me/subscription',
    name: 'Update my Subscription',
    component: () => import('../views/SubscriberAccount.vue'),
    beforeEnter: registerApp === true ? ifAuthenticated : kick,
    meta: meta('Modifier mon compte')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
