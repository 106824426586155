<template>
    <div class="pa-2">
        <p class="wi-100 text-center text-h4 text-md-h3 secondary--text font-weight-bold text-uppercase ma-0 py-4 pt-md-0">{{session.title}}</p>
        <v-divider></v-divider>
        <div class="pa-md-8">
            <j-video-carousel class="mx-auto mb-5 wi-90" v-if="session.video_link.length" :videos="session.video_link"></j-video-carousel>
            <div class="mx-auto mb-5 wi-90 d-flex" v-if="session.support && session.support.length > 0">
                <j-pdf-viewer :key="session.support" :src="session.support"></j-pdf-viewer>
            </div>
            <div v-if="session.content" class="courseContent" v-html="session.content">
            </div>
            <div v-if="session.file" class="wi-100 d-flex flex-md-row flex-column BGlightgrey-opacity rounded-lg pa-5">
                <div class="wi-50 wi-sm-100">
                    <p class="ma-0">{{session.text_file}}</p>
                </div>
                <div class="wi-50 wi-sm-100 d-flex">
                    <v-btn class="j-btn primary white_ py-6"><a class="white_ d-flex align-center" :href="session.file"><v-icon>mdi-download</v-icon><span>Télécharger<br>le fichier</span></a></v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'course-content',
  props:['session','course'],
  methods : {
  }
}
</script>

<style lang="scss" >

    .embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video{
        margin:0!important;
    }
    .courseContent{
        text-align: left;
        padding: 2em;
        p{
            margin-bottom: 0;
        }

        img{
            max-width: 100%;
            height: auto;
        }
        @include phone{
            padding: 2em 0;
        }
        h5{
            font-size: 2em!important;
            font-weight: 700;
        }
    }

    .ql-align-center{
        text-align: center;
    }

    .ql-align-right{
        text-align: right!important;
    }

    @for $i from 1 through 10{
    .ql-indent-#{$i}{
        padding-left: #{$i*4}em; 
        @include phone{
            padding-left: #{$i}em; 
        }
    }
}

</style>
