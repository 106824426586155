<template>
  <div id="resume" class="resume-v2 white elevation-1 mx-auto my-8 d-flex flex-column" >
    <div :style="`padding: ${resume.style.headerSize/2}px 0;`" class="resume-header d-flex main-bg-color">
      <div :class="[resume.description?'wi-65':'wi-100','resume-profile-container']">
        <j-resume-profile :resume="resume" background></j-resume-profile>
      </div>
      <div v-if="resume.description" class="resume-details-container wi-35 d-flex flex-column">
        <j-resume-details :resume="resume" :details="false" background border></j-resume-details>
      </div>
      <j-resume-header-resize :resume="resume"></j-resume-header-resize>
    </div>
    <div class="resume-content d-flex flex-grow-1">
      <div class="resume-large wi-70">
        <div class="skills-container">
          <p class="resume-title main-color">{{lang.skills}}</p>
          <j-resume-lean :resume="resume" onlySkills background></j-resume-lean>
        </div>
        <div :class="[{'flex-column-reverse align-self-start':resume.style.educationsFirst},'content-container d-flex flex-column']">
          <div class="wi-100 experiences-container">
            <div class="resume-title main-color d-flex align-center">
              <p>{{lang.experiences}}</p>
              <div class="resume-divider main-bg-color"></div>
              <v-btn @click="$emit('changeOrderLarge')" small class="btn-first j-btn white">{{resume.style.educationsFirst?'Monter':'Descendre'}}<v-icon right>mdi-arrow-{{resume.style.educationsFirst?'up':'down'}}</v-icon></v-btn>
            </div>
            <j-resume-timeline :resume="resume" section="experiences" :lang="lang" opposite></j-resume-timeline>
          </div>
          <div class="wi-100 educations-container">
            <div class="resume-title main-color d-flex align-center">
              <p>{{lang.educations}}</p>
              <div class="resume-divider main-bg-color"></div>
              <v-btn @click="$emit('changeOrderLarge')" small class="btn-first j-btn white">{{resume.style.educationsFirst?'Descendre':'Monter'}}<v-icon right>mdi-arrow-{{resume.style.educationsFirst?'down':'up'}}</v-icon></v-btn>
            </div>
            <j-resume-timeline :resume="resume" section="educations" :lang="lang" opposite></j-resume-timeline>
          </div>
        </div>
      </div>
      <div class="resume-lean second-bg-color wi-30">
        <j-resume-details :resume="resume" :description="false"></j-resume-details>
        <j-resume-lean :lang="lang" :resume="resume" :isSkills="false" titleClass="main-color" background></j-resume-lean>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['resume','lang'],
  name: 'resume-v_2'
}
</script>

<style lang="scss">
#resume.resume-v2 {
  .resume-large {
    padding-right: 16px;
    .resume-divider {
      flex-grow: 1;
      height: 2px;
      margin-left: 8px;
    }
  }
  .resume-lean {
    margin-left: 16px;
  }
}
</style>