<template>
<div class="resume-dialog d-flex flex-row">
  <j-resume-form-helper class="d-flex flex-column he-100 wi-40 pr-2" title="Comment bien rédiger vos informations ?" video="https://player.vimeo.com/video/775855154#t=40" :content="helpercontent" :index="carouselIndex"></j-resume-form-helper>
  <div class="d-flex flex-column he-100 wi-60 pl-2">
    <div class="details-carousel d-flex flex-column">
      <div class="mb-4 d-flex align-center">
        <img class="icon-img" src="/images/3d/details.png" alt="details"/>
        <h5 class="mb-0 ml-4 text-grey ">Modification de vos informations :</h5>
      </div>
      <div class="pa-4 rounded-t-lg white text-grey flex-grow-1">
        <v-carousel touchless height="auto" v-model="carouselIndex" :show-arrows="false" :hide-delimiters="true">
          <v-carousel-item content-class="d-flex flex-column">
            <h4 class="mb-0 font-weight-bold text-grey">Indiquez votre prénom et nom.</h4>
            <div class="wi-80 mx-auto d-flex mt-4">
              <div class="wi-50 pr-4">
                <span class="text-caption">Prénom :</span>
                <v-text-field v-model="resumeEdit.firstname" :placeholder="$store.getters.getFirstname" dense hide-details></v-text-field>
              </div>
              <div class="wi-50 pl-4">
                <span class="text-caption">Nom :</span>
                <v-text-field v-model="resumeEdit.lastname" :placeholder="$store.getters.getLastname" dense hide-details></v-text-field>
              </div>
            </div>
            <h4 class="mb-0 mt-8 font-weight-bold text-grey">Titre du CV : indiquez le nom du poste auquel vous postulez.</h4>
            <p class="mb-0 text-caption">Ex: Expert comptable, Commercial ...</p>
            <v-text-field v-model="resumeEdit.subtitle" class="mt-4 wi-80 mx-auto" placeholder="Nom du poste visé" clearable dense hide-details></v-text-field>
            <h4 class="mb-0 mt-8 font-weight-bold text-grey">Choisissez 3 qualités en lien avec le poste visé à mettre en avant.</h4>
            <p class="mb-0 text-caption">Tapez les soft skills de votre choix ou utilisez celles du menu déroulant.</p>
            <div class="d-flex wi-80 mx-auto pb-2">
              <v-combobox v-for="index in 3" :key="index" v-model="resumeEdit.softskills[index-1]" class="mt-4 mr-2" placeholder="Une qualité" :items="lang.softskillsEx" dense hide-details></v-combobox>
            </div>
          </v-carousel-item>
          <v-carousel-item content-class="d-flex flex-column">
            <h4 class="mb-0 font-weight-bold text-grey">Indiquez vos coordonnées.</h4>
            <div class="mt-8 wi-80 mx-auto">
              <v-text-field class="mb-8" v-model="resumeEdit.address" placeholder="1 boulevard" label="Adresse postale :" persistent-placeholder dense hide-details></v-text-field>
              <div class="mb-8 d-flex justify-space-between">
                <v-text-field class="wi-60 flex-grow-0" v-model="resumeEdit.city" placeholder="Paris" label="Ville :" persistent-placeholder dense hide-details></v-text-field>
                <v-text-field class="wi-35 flex-grow-0" v-model="resumeEdit.zip" placeholder="75000" label="Code postale :" persistent-placeholder dense hide-details></v-text-field>
              </div>
              <v-text-field class="mb-8" v-model="resumeEdit.phone" placeholder="06.00.00.00.00" label="Téléphone :" persistent-placeholder dense hide-details></v-text-field>
              <v-text-field class="mb-8" v-model="resumeEdit.email" :placeholder="$store.getters.getEmail" label="Email :" persistent-placeholder dense hide-details></v-text-field>
              <v-text-field v-model="resumeEdit.website" placeholder="mon-portfolio.fr" label="LinkedIn / Site web professionnel :" persistent-placeholder dense hide-details></v-text-field>
            </div>
            <h4 class="mb-0 mt-8 font-weight-bold text-grey">Rédigez en quelques mots une accroche qui résume votre profil.</h4>
            <p class="mb-0 text-caption">Ex: + 10 ans d’expérience dans la vente sur des missions de négociation et de management dans des secteurs variés.</p>
            <v-textarea class="mt-4 wi-80 mx-auto" v-model="resumeEdit.description" placeholder="Description pour vous mettre en avant ou faire une phrase d'accroche" outlined hide-details></v-textarea>
          </v-carousel-item>
        </v-carousel>
      </div>
    </div>
    <div class="dialog-actions white rounded-b-lg pb-2 d-flex flex-column">
      <v-divider class="my-0" inner></v-divider>
      <v-carousel class="pa-4" touchless height="100%" v-model="carouselIndex" :show-arrows="false" :hide-delimiters="true">
        <v-carousel-item content-class="d-flex">
          <v-btn outlined class="ma-0 ml-auto primary--text j-btn" @click="carouselIndex++">coordonnées<v-icon right>mdi-arrow-right</v-icon></v-btn>
        </v-carousel-item>
        <v-carousel-item content-class="d-flex">
          <v-btn outlined class="ma-0 mr-auto grey--text j-btn" @click="carouselIndex--"><v-icon left>mdi-arrow-left</v-icon>nom, prénom</v-btn>
        </v-carousel-item>
      </v-carousel>
      <v-btn @click="$emit('close', true)" depressed class="red-gradient white_ j-btn">valider</v-btn>
      <v-btn text @click="$emit('close', false)" class="mt-2 j-btn grey--text">annuler</v-btn>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ['resumeEdit', 'lang', 'indexProps'],
  name: 'resume-form-details',
  data() {
    return {
      carouselIndex: 0, 
      helpercontent: [
        '<h6 class="font-weight-bold text-grey">Entête : Titre de l’annonce & 3 soft skills</h6><li>Nous vous recommandons d’utiliser le nom de l’annonce d’emploi comme titre de votre CV. Cela montre à votre recruteur que vous avez personnalisé votre CV. Dans le cas d’une candidature spontanée, mettre un titre correspond au type de poste que vous recherchez.</li><li>Concernant les 3 qualités, inspirez vous de l’annonce d’emploi et de son décryptage. Trouvez des qualités qui font écho à ce que recherche le recruteur et qui sont des atouts pour le poste visé.</li>',
        '<h6 class="font-weight-bold text-grey">Mini Pitch :</h6><li>Pour cette partie, vous pouvez résumer votre parcours en quelques mots. Utilisez des mots clé en lien avec le secteur d’activité où le poste dans lequel vous postulez.</li>'    
      ]
    }
  },
  created() {
    this.carouselIndex = this.indexProps
  }
}
</script>

<style lang="scss">
.details-carousel {
  height: 100%!important;
  padding-right: 16px;
  overflow-y: scroll;
  .icon-img {
    height: 2rem;
  }
}
</style>