import Vue from 'vue'
  
const state = {
  display: false
}


const actions = {
  open_optin: context => {
      context.commit('open_optin')
  },
  close_optin: context => {
      context.commit('close_optin')
  }
}

const mutations = {
  open_optin: state => {
      Vue.set(state,'display', true)
  },
  close_optin: state =>{
      Vue.set(state,'display', false)
  }
}

export default {
  state,
  actions,
  mutations
}
  