<template>
  <div class="chat-engine-injector" ref="chat">
    <v-dialog v-model="helpDialog" :width="$vuetify.breakpoint.smAndDown ? '90%' : '50%'">
      <div class="main-gradient-pastel pa-4 text-grey rounded-lg">
        <div class="mb-4 d-flex align-center">
          <img class="icon-img-2" src="/images/3d/details.png" alt="details"/>
          <h5 class="mb-0 ml-4 text-grey">Comment répondre aux questions lors de l'entretien :</h5>
        </div>
        <div class="pa-4 white rounded-lg d-flex flex-column">
          <div class="container--video">
            <j-vimeo-embed class="he-100" video="https://player.vimeo.com/video/779287789"></j-vimeo-embed>
          </div>
          <v-btn class="mt-4 j-btn red-gradient white_" to="/sessions/6176cb45d3ccd90018072596">Voir l'atelier</v-btn>
          <v-btn @click="helpDialog = false" class="mt-2 j-btn text-grey" text>retour</v-btn>
        </div>
      </div>
    </v-dialog>
    <div class="messages-list">
      <span v-for="(message, index) in messages" :key="index" :class="[`${message.role} message-container message-animation`]">
        <div v-if="message.content" :class="[{'alert-type':message.alert}, 'message-content message-animation text-grey elevation-1']">
          <span v-if="message.alert">⚠️</span>
          <p v-html="message.content" class="text-pre-wrap"></p>
          <v-btn v-if="message.helper" @click="helpDialog = true" icon>
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-btn>
        </div>
        <typing-bubble v-else class="message-animation"></typing-bubble>
        <div class="profile">
          <profile-bubble :image="message.role === 'user' ? $store.getters.getPicture && $store.getters.getPicture : `/images/3d/coach_${coachIndex}.png`" class="BGLight-secondary"></profile-bubble>
        </div>
      </span>
    </div>
    <div class="injector-input message-animation" v-if="!loading">
      <div v-if="active.type === 'boolean' || active.type === 'end'" class="input-container boolean-type">
        <p v-if="active.helper" class="mb-2 text-center">{{active.helper}}</p>
        <v-btn v-if="active.btn" @click="next()" class="j-btn secondary white_">{{active.btn}}</v-btn>
        <v-btn v-if="active.alt" @click="active.altCb()" class="j-btn text-grey mt-2" small text>{{active.alt}}</v-btn>
        <v-btn v-if="active.sendQuestionOption" @click="sendQuestion = true" class="j-btn text-grey mt-2" small text>Rédiger ma propre question</v-btn>
      </div>
      <div v-else-if="active.type === 'textarea'" class="input-container textarea-type">
        <p v-if="active.helper" class="mb-2 text-center">{{active.helper}}</p>
        <v-form @submit.prevent="next()" class="input-form">
          <v-textarea v-model="input" :label="active.label" :placeholder="active.placeholder" :rules="[v => v.length <= 300 || 'Max 300 caractères']" counter maxlength="300" rows="2" rounded dense no-resize></v-textarea>
          <v-btn class="input-btn" :disabled="!textareaValidator" type="submit" color="secondary" fab small><v-icon color="white">mdi-send</v-icon></v-btn>
        </v-form>
      </div>
      <v-form v-else @submit.prevent="next()" class="input-container input-form string-type">
        <v-text-field v-model="input" :label="active.label" :placeholder="active.placeholder" :rules="[v => v.length <= 60 || 'Max 60 caractères']" counter maxlength="60" rounded dense></v-text-field>
        <v-btn class="input-btn" :disabled="!inputValidator" type="submit" color="secondary" fab small><v-icon color="white">mdi-send</v-icon></v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
import TypingBubble from '@/assets/jobtimise-common/components/chat/TypingBubble'
import ProfileBubble from '@/assets/jobtimise-common/components/chat/ProfileBubble'

export default {
  props: ['scenariActive','messages','loading'],
  name : 'chat-engine-injector',
  components: {TypingBubble,ProfileBubble},
  data() {
    return {
      helpDialog: false,
      coachIndex: 0,
      input: '',
      sendQuestion: false
    }
  },
  created () {
    this.coachIndex = Math.floor(Math.random()*4+1)
  },
  watch: {
    'messages'() {
      if(this.sendQuestion) this.sendQuestion = false
    }
  },
  methods: {
    next() {
      if(this.active.type === 'end') this.$emit('changeChat')
      else {
        this.$emit('next', {input: this.input, slug: this.active.slug})
        this.input = ''
      }
      if(this.sendQuestion) this.sendQuestion = false
    },
    scroll(behavior) {
      try {
        const bhvr = behavior || 'smooth'
        this.$refs.chat.scroll({ top: this.$refs.chat.scrollHeight, behavior: bhvr })
        window.scrollTo(0, document.body.scrollHeight)
      } catch(e) {
        // do nothing
      }
    }
  },
  computed : {
    inputValidator() {
      return this.input.length > 2 && this.input.length <= 60
    },
    textareaValidator() {
      return this.input.length > 2 && this.input.length <= 300
    },
    active() {
      if(this.sendQuestion) return {type: 'textarea', slug: 'send-question', helper: '💡 Obtenez un exemple de réponse à votre question d’entretien.', label: 'Redigez votre question d’entretien', placeholder: 'Ex : "Comment travaillez-vous en équipe et collaborez-vous avec d’autres membres d’une équipe de projet ?"'}
      return this.scenariActive
    }
  }
}
</script>

<style lang="scss">
.chat-engine-injector {
  position: relative;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  @include noScrollbar();
  @include shinyContainer(white);
  @include roundTopBorders(30px);
  background: rgba(white, 0.5);
  border: white solid 5px;
  border-bottom: none;
  .messages-list {
    padding: 3rem 3rem 156px;
    @include phone {
      padding: 3rem 0.5rem 156px;
    }
    >span{
      display: block;
      margin-top: 2rem;
      &:first-child{
        margin-top: 0;
      }
      &.user + .user{
        margin-top: 0.5rem;
      }
      &.assistant + .assistant {
        margin-top: 0.5rem;
      }
    }
    .message-container {
      display: flex;
      .message-content {
        height: fit-content;
        max-width: 70%;
        padding: 1rem 1.5rem;
        border-radius: 2.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        @include phone {
          max-width: calc(100% - 4rem);
          border-radius: 2rem;
          padding: 0.5rem 1.25rem;
        }
        &.alert-type {
          border: #ffb02e 2px solid;
          span {
            margin-right: 1rem;
            font-size: 1.5rem;
          }
        }
        &.pa-help {
          padding-right: 2rem!important;
        }
        p {
          margin: 0;
        }
        .message--help {
          cursor: pointer;
          position: absolute;
          top: calc(1.5em/4);
          right: calc(3em/4);
          @include phone {
            top: calc(1em/4);
            right: calc(1em/4);
          }
        }
      }
      .profile{
        height: auto;
        display: flex;
        padding: 0.6rem 0.5em 0;
        @include phone{
          padding: 0 0.5rem 0 0;
          &-bubble{
            height: 1.5rem;
            width: 1.5rem;
            span{
              font-size: 0.7rem;
            }
          }
        }
      }
      &.assistant {
        flex-direction: row-reverse;
        justify-content: start;
      }
      &.user {
        justify-content: end;
      }
    }
    .message-animation {
      animation: inBottom 0.5s;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .typing-bubble {
      padding: 16px;
      >div {
        background: white;
      }
      span {
        background: grey;
      }
    }
  }
  .injector-input {
    position: sticky;
    margin-top: auto;
    width: 100%;
    bottom: 0;
    left: 0;
    .input-container {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 16px;
      border-top: #e9e9e9 2px solid;
      background-color: #ffffff;
      &.boolean-type, &.textarea-type {
        display: flex;
        flex-direction: column;
      }
      &.input-form, .input-form {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        .v-text-field {
          margin-right: 1rem;
          font-weight: 300;
          caret-color: $secondary!important;
          &.v-input--is-focused .v-label {
            color: $secondary!important;
          }
          .v-label {
            background-color: #ffffff;
          }
          .v-input__control {
            min-height: 38px;
          }
          .v-input__slot{
            box-shadow: 0px 0px 7px 0 rgba($textGrey,0.2)!important;
          }
          .v-text-field__details{
            margin: 4px 0 0 0;
          }
        }
        .input-btn {
          margin-top: 4px;
          box-shadow: 0px 0px 7px 0 rgba($textGrey,0.2)!important;
        }
      }
    }
  }
}
</style>