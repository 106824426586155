<template>
  <v-hover v-slot="{ hover }">
    <div :class="[{'has-large':large,'interests':isInterests},'resume-section-list']">
      <div v-if="hasItems" class="resume-list">
        <div v-if="isLanguages">
          <p class="resume-p resume-list-item" v-for="(item, index) in items" :key="index">
            <span class="font-weight-bold">{{item.title}} :</span> {{item.level}}
          </p>
        </div>
        <div v-else-if="isSkills && large" :class="[{'flex-wrap':!circularStyle},'d-flex justify-space-between']">
          <div :class="[{'wi-30':linearStyle || dotStyle,'wi-15 d-flex flex-column align-center circular-style':circularStyle},'resume-list-item']" v-for="item in items" :key="item.id">
            <v-progress-circular v-if="circularStyle" :rotate="90" :size="75" :width="15" :value="item.score" :color="background?resume.style.mainColor:resume.style.secondColor"></v-progress-circular>
            <p :class="[{'mt-2 text-center':circularStyle,'mb-2':linearStyle || dotStyle},'resume-p font-weight-bold']">{{item.title}}</p>
            <v-progress-linear v-if="linearStyle" :color="background?resume.style.mainColor:resume.style.secondColor" :value="item.score"></v-progress-linear>
            <v-rating v-else-if="dotStyle" :value="rating(item.score)" half-increments>
              <template v-slot:item="props">
                <i :class="[{'fa fa-circle':props.isFilled},{'fa fa-circle-half-stroke':props.isHalfFilled && !props.isFilled},{'fa-regular fa-circle':!props.isHalfFilled && !props.isFilled},background?'main-color':'second-color','mr-2']"></i>
              </template>
            </v-rating>
          </div>
          <div v-if="linearStyle && (items.length === 2 || items.length === 5)" class="wi-30"></div>
        </div>
        <div v-else-if="isSkills && !large" :class="{'d-flex flex-wrap justify-space-between':circularStyle}">
          <div :class="[{'circular-style d-flex flex-column align-center':circularStyle, 'wi-50':circularStyle && items.length === 4, 'wi-30':circularStyle && items.length !== 4},'resume-list-item']" v-for="item in items" :key="item.id">
            <v-progress-circular v-if="circularStyle" :rotate="90" :size="75" :width="15" :value="item.score" :color="background?resume.style.mainColor:resume.style.secondColor"></v-progress-circular>
            <p :class="[{'mt-2 text-center':circularStyle,'mb-2':linearStyle || dotStyle,'text-no-wrap':circularStyle && item.title.length <= 14},'resume-p font-weight-bold']">{{item.title}}</p>
            <v-progress-linear v-if="linearStyle" class="wi-75" :color="background?resume.style.mainColor:resume.style.secondColor" :value="item.score"></v-progress-linear>
            <v-rating v-else-if="dotStyle" :value="rating(item.score)" half-increments>
              <template v-slot:item="props">
                <i :class="[{'fa fa-circle':props.isFilled},{'fa fa-circle-half-stroke':props.isHalfFilled && !props.isFilled},{'fa-regular fa-circle':!props.isHalfFilled && !props.isFilled},background?'main-color':'second-color','mr-2']"></i>
              </template>
            </v-rating>
          </div>
          <div v-if="circularStyle && items.length === 5" class="wi-30"></div>
        </div>
        <div v-else-if="isComputings">
          <div class="resume-list-item computings" v-for="group in items" :key="group.id">
            <p v-if="group.groupTitle" :class="[background?'main-color':'second-color','resume-p']">{{group.groupTitle}} :</p>
            <div class="resume-list-item" v-for="(item, index) in group.groupSkills" :key="index">
              <p class="resume-p font-weight-bold mb-2">{{item.title}}</p>
              <v-progress-linear v-if="linearStyle" class="wi-75" :color="background?resume.style.mainColor:resume.style.secondColor" :value="item.score"></v-progress-linear>
              <v-rating v-else-if="dotStyle" :value="rating(item.score)" half-increments>
                <template v-slot:item="props">
                  <i :class="[{'fa fa-circle':props.isFilled},{'fa fa-circle-half-stroke':props.isHalfFilled && !props.isFilled},{'fa-regular fa-circle':!props.isHalfFilled && !props.isFilled},background?'main-color':'second-color','mr-2']"></i>
                </template>
              </v-rating>
            </div>
          </div>
        </div>
        <div v-else-if="isInterests">
          <p class="resume-p resume-list-item" v-for="(item, index) in items" :key="index">
            <span class="font-weight-bold">{{item.title}}</span>{{item.description ? `: ${item.description}` : ''}}
          </p>
        </div>
      </div>
      <v-scroll-y-reverse-transition>
        <v-btn v-if="hover || !hasItems" @click="$parent.$parent.$emit('openDialog', section, null)"  :class="[{'absolute':hasItems},'add-btn j-btn secondary']">{{items.length ? 'Modifier' : 'Ajouter'}}</v-btn>
      </v-scroll-y-reverse-transition>
    </div>
  </v-hover>
</template>

<script>
export default {
  props: {
    resume: Object,
    section: String,
    large: Boolean,
    background: Boolean
  },
  name:'resume-list',
  data() {
    return {
    }
  },
  methods: {
    rating(value) {
      return value*5/100
    }
  },
  computed: {
    currentStyle() {
      return this.resume.style[this.isComputings?'computings':'skills']
    },
    circularStyle() {
      return this.currentStyle === 'circular'
    },
    linearStyle() {
      return this.currentStyle === 'linear'
    },
    dotStyle() {
      return this.currentStyle === 'dot'
    },
    isLanguages() {
      return this.section === 'languages'
    },
    isSkills() {
      return this.section === 'skills'
    },
    isComputings() {
      return this.section === 'computings'
    },
    isInterests() {
      return this.section === 'interests'
    },
    items() {
      return this.resume[this.section]
    },
    hasGroupItems() {
      let hasItems = false
      for(const group of this.items) {
        if(group.groupSkills.length)  hasItems = true
      }
      return hasItems
    },
    hasItems() {
      return this.isLanguages && this.items.length || this.isSkills && this.items.length || this.isComputings && (this.items.length && this.hasGroupItems) || this.isInterests && this.items.length 
    }
  }
}
</script>

<style lang="scss">
.resume-section-list {
  padding: 8px;
  &:hover .resume-list {
    background-color: rgba(197, 197, 197, 0.5)!important;
  }
  &.has-large {
    padding-right: 0;
    .resume-list {
      padding-top: 0;
      padding-right: 0;
      .resume-list-item:not(.circular-style):nth-child(-n+3) {
        margin-bottom: 16px;
      }
    }
  }
  &:not(.has-large) .resume-list {
    padding-bottom: 24px;
    .resume-list-item {
      &:not(.computings,.circular-style) {
        margin-left: 16px!important;
      }
      &:not(:last-child) {
        margin-bottom: 16px!important;
      }
      &.circular-style:nth-last-child(-n+3) {
        margin: 0!important;
      }
    }
  }
  // .resume-list:last-of-type {
  //   background: red;
  // }
  // &.interests .resume-list { 
  //   padding-bottom: 0;
  // }
  .resume-list {
    padding: 8px;
    transition: all ease 0.25s;
    &.computings .resume-list-item {
      margin-left: 16px;
    }
  }
}
</style>