import {apiURL} from '@/../config';
import {AUTH_SUCCESS} from "../actions/auth";
import {
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_ERROR
  } from "../actions/register";
import { USER_SUCCESS } from "../actions/user";
import axios from 'axios';
  
const state = {
    status: "",
    hasLoadedOnce: false
};

  
const actions = {
    [REGISTER_REQUEST]: ({commit,dispatch},user) => {
        return new Promise ((resolve,reject) => {
            commit(REGISTER_REQUEST);
            axios.post(apiURL + 'public/register',user).then(res=>{
                localStorage.setItem("user-token",res.data.token);
                axios.defaults.headers.common['Authorization'] = res.data.token;
                setTimeout(()=>{
                    commit(REGISTER_SUCCESS,res);    
                    commit(AUTH_SUCCESS,res.data.token);       
                    commit(USER_SUCCESS,res.data.profile);
                    dispatch('open_dialog',{message:`Bienvenue ${res.data.profile.firstname}, vous êtes maintenant connecté(e).`,button:'Continuer'})
                    resolve(res);
                },1000);
            }).catch(err=> {
                commit(REGISTER_ERROR,err);
                localStorage.removeItem("user-token");
                reject(err.response || err);
            })
        });
    }
};

const mutations = {
    [REGISTER_REQUEST]: (state) => {
        state.status = "loading";
    },
    [REGISTER_SUCCESS]: (state) => {
        state.status = "success";
        state.hasLoadedOnce = true;
    },
    [REGISTER_ERROR]: state => {
        state.status = "error";
        state.hasLoadedOnce = true;
    }
};
  
  export default {
    state,
    actions,
    mutations
  };
  