import '@fortawesome/fontawesome-free/css/all.min.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Jcomponents from "@/assets/jobtimise-common"
import Mcomponents from "@/components"
import vuetify from './plugins/vuetify'
import {hotjar} from './plugins/hotjar'
import '@mdi/font/css/materialdesignicons.css'

(async () => {
    axios.defaults.withCredentials = true
    if (store.getters.isAuthenticated) {
      axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');
      await store.dispatch('USER_REQUEST')
    }
    hotjar
    
    Vue.use(Jcomponents)
    Vue.use(Mcomponents)
    
    Vue.config.productionTip = false
    
    new Vue({
      store,
      router,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
})()
