<template>
    <v-card class="course-detail">
        <div class="header-detail wi-100">
          <i class="closeIcon zi-10" @click="$emit('close')"></i>
          <v-img :height='$vuetify.breakpoint.mobile ? "16rem" : "15rem"'  class="d-flex justify-center text-center position-relative" :src="course.thumbnail">
            <div class="container-btn pa-2 zi-1">
              <v-btn class="j-btn red-gradient white--text mx-auto" :to="`/courses/${course._id}/sessions/${get_next_session._id}`">
                  <span v-if="!course_started">Commencer la formation</span>
                  <span v-else-if="course_started < course.list_of_sessions" >Continuer la formation</span>
                  <span v-else >Revoir la formation</span>
              </v-btn>
            </div>
          </v-img>
        </div>
        <v-card-text>
          <v-card-title class="text-grey">
              <p class="h3 mb-1 wi-100 text-center text-uppercase">{{course.title}}</p>
              <p class="caption wi-100 text-center ma-0">{{course.short_description}}</p>
          </v-card-title>
        <div v-if="course.list_of_sessions.length"  class=" caption text-left wi-100 grey--text">
        <div class="font-weight-bold ml-md-8 mt-2">Description de la formation</div>
        <div class=" ml-md-8 mb-2">{{course.description}}</div>
        <div class="font-weight-bold ml-md-8 mb-2 mt-5">Liste des séances</div>
        <v-icon class=" ml-md-8 caption grey--text">mdi-check-circle-outline</v-icon>
            {{course.list_of_sessions.length}} ateliers
        </div>
        <div v-if="time>0"  class="ml-md-8  caption text-left wi-100 grey--text">
            <v-icon class="caption grey--text">mdi-clock-outline</v-icon>
            {{time}}min
        </div>

        <v-timeline class="mt-3" align-top dense >
            <v-timeline-item v-for="session in course.list_of_sessions" :key="session._id" color="secondary" small>
            <div>
                <router-link :to="`/courses/${course._id}/sessions/${session._id}`" class="font-weight-normal secondary--text">
                  <strong>{{ session.title }}</strong> 
                </router-link>
                <div v-if="session.objective" class="caption text-left wi-100 grey--text">
                    <v-icon class="caption grey--text">mdi-bullseye</v-icon>
                    {{session.objective}}
                </div>
                <div v-if="session.time" class="caption text-left wi-100 grey--text">
                    <v-icon class="caption grey--text">mdi-clock-outline</v-icon>
                    {{session.time}}min
                </div>
                <div v-if="session.has_video || session.has_quiz" class="caption text-left wi-100 grey--text">
                    <v-icon class="caption grey--text">mdi-arrow-right-drop-circle-outline</v-icon>
                    {{session.has_video ? "Vidéo" : ''}}
                    {{session.has_video && session.has_quiz ? " & " : ''}}
                    {{session.has_quiz ? "Quiz" : ''}}
                </div>
                <div>{{ session.description }}</div>
            </div>
            </v-timeline-item>
        </v-timeline>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
  name: 'course-detail',
  props: ['course'],
  created(){
  },
  data(){
    return {

    }
  },
  methods:{
  },
  computed:{
    course_started(){
        const all_done = this.$store.state.user.profile.history.sessions_done
        const done_in_course = all_done.filter(el=>{return el.course == this.course._id})
        const res = done_in_course.length == 0 ? false : done_in_course
        return res 
    },
    get_next_session(){
        const list_of_sessions = this.course.list_of_sessions
        const all_done = this.$store.state.user.profile.history.sessions_done
        if(this.course_started && this.course_started < this.course.list_of_sessions){
            let loop = true, i=1, next_session = list_of_sessions[0]
            while(loop == true && i < list_of_sessions.length){

                next_session = list_of_sessions[i]
                if(all_done.filter(el=>{return el._id == list_of_sessions[i]._id }).length == 0){
                    loop = false
                }
                i++
            }
            return next_session
        }else{
            return list_of_sessions[0]
        }
    },
    time(){
        if(!this.course.list_of_sessions.length)return 0
        return this.course.list_of_sessions.reduce((total,current)=>{
            return typeof current.time === "undefined" ? total : total + current.time
        },0)
      },
    
  }
}
</script>

<style lang="scss">
  .course-detail{
    overflow-x: hidden;
    .container-btn {
      width: 40%;
      border-top-left-radius: 1em;
      @include phone{
        width: 100%;
      }
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.5);
    }
    @include closeIcon(white, 2rem, 0.1rem,1rem,1rem);
    .closeIcon{
      @include phone{
        top: 5px;
        right: 5px;
      }
    }
  }
</style>
