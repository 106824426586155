<template>
  <div id="resume" class="resume-v1 white elevation-1 mx-auto my-8 d-flex flex-column" >
    <div :style="`padding: ${resume.style.headerSize/2}px 0;`" class="resume-header d-flex">
      <div class="resume-profile-container wi-65 d-flex align-center">
        <j-resume-profile :resume="resume"></j-resume-profile>
      </div>
      <div class="resume-details-container wi-35 d-flex flex-column">
        <j-resume-details :resume="resume" background></j-resume-details>
      </div>
      <j-resume-header-resize :resume="resume"></j-resume-header-resize>
    </div>
    <div class="resume-content d-flex flex-grow-1">
      <div class="resume-large wi-65">
        <div :class="[{'flex-column-reverse align-self-start':resume.style.educationsFirst},'content-container d-flex flex-column']">
          <div class="wi-100 experiences-container">
            <div class="resume-title main-bg-color">
              <p>{{lang.experiences}}</p>
              <v-btn @click="$emit('changeOrderLarge')" small class="btn-first j-btn white">{{resume.style.educationsFirst?'Monter':'Descendre'}}<v-icon right>mdi-arrow-{{resume.style.educationsFirst?'up':'down'}}</v-icon></v-btn>
            </div>
            <j-resume-timeline :resume="resume" section="experiences" :lang="lang"></j-resume-timeline>
          </div>
          <div class="wi-100 educations-container">
            <div class="resume-title main-bg-color">
              <p>{{lang.educations}}</p>
              <v-btn @click="$emit('changeOrderLarge')" small class="btn-first j-btn white">{{resume.style.educationsFirst?'Descendre':'Monter'}}<v-icon right>mdi-arrow-{{resume.style.educationsFirst?'down':'up'}}</v-icon></v-btn>
            </div>
            <j-resume-timeline :resume="resume" section="educations" :lang="lang"></j-resume-timeline>
          </div>
        </div>
      </div>
      <j-resume-lean :lang="lang" class="resume-lean wi-35" :resume="resume" titleClass="main-bg-color"></j-resume-lean>
    </div>
  </div>
</template>

<script>
export default {
  props: ['resume','lang'],
  name: 'resume-v_1'
}
</script>

<style lang="scss">
#resume.resume-v1 {
  .resume-large {
    padding-right: 16px;
  }
  .resume-lean {
    padding-left: 16px;
  }
  .resume-title {
    color: white!important;
  }
}
</style>