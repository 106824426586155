<template>
  <div class="animated-loader">
    <svg id="animated-resume" width="441" height="441" viewBox="0 0 441 441" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55 22C55 19.2386 57.2386 17 60 17H370C372.761 17 375 19.2386 375 22V429C375 431.761 372.761 434 370 434H60C57.2386 434 55 431.761 55 429V22Z" fill="black" fill-opacity="0.25"/>
      <path id="paper" d="M60 17C60 14.2386 62.2386 12 65 12H375C377.761 12 380 14.2386 380 17V424C380 426.761 377.761 429 375 429H65C62.2386 429 60 426.761 60 424V17Z" fill="white" stroke="#E0E0E0" stroke-width="1"/>
      <path id="text6" d="M97 361H319" stroke="#93CAB1" stroke-width="5" stroke-linecap="round"/>
      <path id="text5" d="M97 341H342" stroke="#93CAB1" stroke-width="5" stroke-linecap="round"/>
      <path id="text4" d="M97 321H342" stroke="#93CAB1" stroke-width="5" stroke-linecap="round"/>
      <path id="text3" d="M97 301H342" stroke="#93CAB1" stroke-width="5" stroke-linecap="round"/>
      <path id="text2" d="M97 281H342" stroke="#93CAB1" stroke-width="5" stroke-linecap="round"/>
      <path id="text1" d="M97 261H342" stroke="#93CAB1" stroke-width="5" stroke-linecap="round"/>
      <path class="line" d="M248 96H248" stroke="#93CAB1" stroke-width="5" stroke-linecap="round"/>
      <path class="line" d="M248 77H248" stroke="#93CAB1" stroke-width="5" stroke-linecap="round"/>
      <path class="line" d="M248 58H248" stroke="#93CAB1" stroke-width="5" stroke-linecap="round"/>
      <g id="picture">
        <circle cx="123.5" cy="75.5" r="43.5" fill="#C9E5D8"/>
        <path d="M94.9846 108.351C98.031 97.753 107.798 90 119.375 90H126.625C138.452 90 148.389 98.0905 151.203 109.04C143.679 115.261 134.026 119 123.5 119C112.591 119 102.62 114.984 94.9846 108.351Z" fill="#539ACA"/>
        <circle cx="123.5" cy="71.5" r="15.5" fill="#E5BDAB"/>
      </g>
      <path class="skillsSection" d="M92 231H92" stroke="#D9D9D9"/>
      <path class="skill" d="M324 190C324 195.057 322.132 199.945 318.738 203.764C315.344 207.584 310.653 210.079 305.527 210.791C300.401 211.503 295.184 210.384 290.835 207.64C286.486 204.897 283.296 200.712 281.853 195.855C280.409 190.998 280.808 185.796 282.977 181.204C285.146 176.612 288.938 172.938 293.658 170.858C298.377 168.778 303.707 168.432 308.667 169.882" stroke="#539ACA" stroke-width="5" stroke-linecap="round"/>
      <path class="skill" d="M241 190C241 195.057 239.132 199.945 235.738 203.764C232.344 207.584 227.653 210.079 222.527 210.791C217.401 211.503 212.184 210.384 207.835 207.64C203.486 204.897 200.296 200.712 198.853 195.855C197.409 190.998 197.808 185.796 199.977 181.204C202.146 176.612 205.938 172.938 210.658 170.858C215.377 168.778 220.707 168.432 225.667 169.882C230.627 171.333 234.885 174.483 237.657 178.754" stroke="#539ACA" stroke-width="5" stroke-linecap="round"/>
      <path class="skill" d="M158 189.074C158 194.114 156.132 198.984 152.738 202.79C149.344 206.596 144.653 209.082 139.527 209.791C134.401 210.501 129.184 209.386 124.835 206.652C120.486 203.918 117.296 199.748 115.853 194.909C114.409 190.069 114.808 184.885 116.977 180.309C119.146 175.733 122.938 172.073 127.658 170" stroke="#539ACA" stroke-width="5" stroke-linecap="round"/>
      <path class="skillsSection" d="M92 149H92" stroke="#D9D9D9"/>
    </svg>
    <svg id="animated-target" width="441" height="441" viewBox="0 0 441 441" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="target">
        <circle cx="209" cy="221" r="182" fill="#AD2020"/>
        <circle cx="218" cy="217" r="180" fill="#E75B5B"/>
        <circle cx="218" cy="217" r="140" fill="white"/>
        <circle cx="218" cy="217" r="100" fill="#E75B5B"/>
        <circle cx="218" cy="217" r="60" fill="white"/>
        <circle cx="218" cy="217" r="20" fill="#A43F3F"/>
        <path id="shadow" d="M397.773 207.875L395.695 208.142L388.011 196.307L221.317 217.738L220.622 212.332L387.316 190.901L391.756 177.507L393.601 177.27C395.835 187.186 397.25 197.413 397.773 207.875Z" fill="black" fill-opacity="0.5"/>
        <g id="arrow">
          <path d="M376 142.48L401.816 133L400.463 147.046L374.647 156.526L376 142.48Z" fill="#539ACA"/>
          <path d="M380.264 140.915L380.496 140.829L379.144 154.875L378.911 154.961L380.264 140.915Z" fill="#55BAE8"/>
          <path d="M388.792 137.783L389.024 137.698L387.671 151.743L387.439 151.829L388.792 137.783Z" fill="#55BAE8"/>
          <path d="M397.319 134.652L397.552 134.567L396.199 148.612L395.967 148.698L397.319 134.652Z" fill="#55BAE8"/>
          <path d="M386.647 171.475L412.464 161.995L402.342 152.163L376.526 161.643L386.647 171.475Z" fill="#539ACA"/>
          <path d="M398.078 153.729L397.846 153.814L407.967 163.646L408.199 163.561L398.078 153.729Z" fill="#55BAE8"/>
          <path d="M389.55 156.86L389.318 156.945L399.439 166.777L399.672 166.692L389.55 156.86Z" fill="#55BAE8"/>
          <path d="M381.023 159.991L380.79 160.077L390.911 169.909L391.144 169.824L381.023 159.991Z" fill="#55BAE8"/>
          <rect x="216.882" y="214.46" width="201.68" height="5.45081" transform="rotate(-20.1641 216.882 214.46)" fill="#55BAE8"/>
          <path d="M203.324 222.342L219.18 204.906L219.669 216.34L226.696 225.373L203.324 222.342Z" fill="#539ACA"/>
        </g>
        <path d="M215.533 197.15C205.651 198.366 198 206.789 198 217C198 228.045 206.954 237 218 237C223.862 237 229.135 234.478 232.793 230.46L215.533 197.15Z" fill="#A43F3F"/>
      </g>
    </svg>
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  name: 'animated-loader',
  data() {
    return {

    }
  },
  mounted() {
    this.initResume()
    this.initTarget()
  },
  methods: {
    initContainer() {
      const container = document.querySelector('.animations-container');
      const tl = gsap.timeline({paused: true, repeat: -1, repeatDelay: 8});
      gsap.set(container, {tansformOrigin: 'center bottom', transform: 'rotate(0deg)'});
      tl
        .to(container, {duration: 1, transform: 'rotate(180deg)'}, '8')
      ;
      tl.play();
    },
    initResume() {
      const picture = document.getElementById('picture');
      const lines = document.querySelectorAll('.line');
      const skillsSection = document.querySelectorAll('.skillsSection');
      const skills = document.querySelectorAll('.skill');

      gsap.set(picture, {y: 50, x: 20});
      gsap.set(skills, {scale: 2, y: 50, x: 20, opacity: 0})
      gsap.set([lines, skillsSection], {transformOrigin: "0% 50%", opacity: 0});

      const tl = gsap.timeline({paused: true, yoyo: true, repeat: -1, repeatDelay: 2});

      tl
        .to(lines[2], {duration: .75, attr: {d: "M248 58H355"}, opacity: 1}, '0')
        .to(lines[1], {duration: .75, attr: {d: "M248 77H355"}, opacity: 1}, '0.75')
        .to(lines[0], {duration: .75, attr: {d: "M248 96H314"}, opacity: 1}, '1.5')
        .to(picture, {duration: 1, y: 0, x: 0}, '2.25')
        .to(skillsSection[0], {duration: 1, attr: {d: "M92 231H347.5"}, opacity: 1}, '3.25')
        .to(skillsSection[1], {duration: 1, attr: {d: "M92 149H347.5"}, opacity: 1}, '4.25')
        .to(skills[2], {duration: 1, scale: 1, x: 0, y: 0, opacity: 1}, '5.75')
        .to(skills[1], {duration: 1, scale: 1, x: 0, y: 0, opacity: 1}, '6.75')
        .to(skills[0], {duration: 1, scale: 1, x: 0, y: 0, opacity: 1}, '7.25')
      ;

      tl.play();
    },
    initTarget() {
      const target = document.getElementById('target');
      const arrow = document.getElementById('arrow');
      const shadow = document.getElementById('shadow');

      gsap.set([arrow, shadow], {y: '-100vw', x: "200vw"});

      const tl = gsap.timeline({paused: true, yoyo: true, repeat: -1, repeatDelay: 2});
      tl
        .to(arrow, {duration: .1, x: 0, y: 0}, '0')
        .to(shadow, {duration: .1, x: 0, y: 0}, '0')
        .to(target, {duration: .2, x: -5, y: 5}, '0.1')
        .to(target, {duration: .2, x: 0, y: 0}, '0.3')
      ;

      tl.play();
    }
  }
}
</script>

<style lang="scss" scoped>
.animated-loader {
  height: 25vh;
  overflow: hidden;
  position: relative;
  #animated-resume, #animated-target {
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transform-origin: center 200%;
  }
  #animated-resume { 
    animation: rotate-loader 19s infinite; 
  }
  #animated-target {
    transform: translate(-50%, -50%) rotate(180deg);
    animation: rotate-2-loader 19s infinite; 
  }
}

@keyframes rotate-loader {
  0% { 
    transform: translate(-50%, -50%) rotate(0deg);
  }
  40% { 
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% { 
    transform: translate(-50%, -50%) rotate(-180deg);
  }
  90% { 
    transform: translate(-50%, -50%) rotate(-180deg);
  }
  100% { 
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes rotate-2-loader {
  0% { 
    transform: translate(-50%, -50%) rotate(180deg);
  }
  40% { 
    transform: translate(-50%, -50%) rotate(180deg);
  }
  50% { 
    transform: translate(-50%, -50%) rotate(0deg);
  }
  90% { 
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% { 
    transform: translate(-50%, -50%) rotate(-180deg);
  }
}
</style>