<template>
<div class="resume-dialog d-flex flex-row">
  <j-resume-form-helper class="d-flex flex-column he-100 wi-40 pr-2" :title="helper.title" :video="helper.video" :content="helper.content" :index="0"></j-resume-form-helper>
  <div class="d-flex flex-column he-100 wi-60 pl-2">
    <div class="list-form d-flex flex-column">
      <div class="mb-4 d-flex align-center">
        <img class="icon-img" :src="`/images/3d/${section}.png`" :alt="section"/>
        <h5 class="mb-0 ml-4 text-grey">{{title}} :</h5>
      </div>
      <div class="pa-4 rounded-t-lg white text-grey flex-grow-1">
        <div v-if="isLanguages">
          <h4 class="mb-0 font-weight-bold text-grey">Quelles langues maîtrisez-vous ?</h4>
          <p class="mb-0 text-caption">Tapez la langue de votre choix ou utilisez les choix du menu déroulant.</p>
          <div class="languages-section d-flex flex-column wi-80 mt-4 mx-auto">
            <v-form @submit.prevent="pushItem()" class="d-flex">
              <v-combobox v-model="newLanguage.title" :items="lang.languagesEx" ref="input" placeholder="Nouvelle langue" class="wi-100" dense hide-details></v-combobox>
              <v-select v-model="newLanguage.level" :items="lang.languageLevels" placeholder="Niveau de maîtrise" class="ml-4 wi-100" dense hide-details>
                <template v-slot:selection="{ item }">
                  {{shortenItem(item[0].toUpperCase() + item.substring(1))}}
                </template>
                <template v-slot:item="{ item }">
                  {{item[0].toUpperCase() + item.substring(1)}}
                </template>
              </v-select>
              <v-btn type="submit" :disabled="!newLanguage.title || !newLanguage.level" depressed small class="ml-4 secondary j-btn">ajouter</v-btn>
            </v-form>
            <draggable v-model="resumeEdit.languages" handle=".handle">
              <div class="d-flex mt-4" v-for="(language, index) in items" :key="index">
                <v-text-field v-model="resumeEdit.languages[index].title" placeholder="Langue" class="wi-50" dense hide-details>
                  <template v-slot:prepend>
                    <v-icon class="handle cursor-pointer">mdi-arrow-up-down-bold</v-icon>
                  </template>
                </v-text-field>
                <v-select v-model="resumeEdit.languages[index].level" :items="lang.languageLevels" placeholder="Niveau de maîtrise" class="ml-4 wi-50" dense hide-details>
                  <template v-slot:selection="{ item }">
                    {{shortenItem(item[0].toUpperCase() + item.substring(1))}}
                  </template>
                  <template v-slot:item="{ item }">
                    {{item[0].toUpperCase() + item.substring(1)}}
                  </template>
                </v-select>
                <v-icon class="ml-2" @click="removeItem(index)" color="alert">mdi-delete</v-icon>
              </div>
            </draggable>
          </div>
        </div>
        <div v-else-if="isInterests">
          <h4 class="mb-0 font-weight-bold text-grey">Quels sont vos passions et centres d'intérêt ?</h4>
          <p class="mb-0 text-caption">N'indiquez que des activités que vous pratiquez encore.<br/>Ex: sport collectif, instrument de musique, voyage à l'étranger ... </p>
          <div class="interests-section d-flex flex-column wi-80 mt-4 mx-auto">
            <v-form @submit.prevent="pushItem()" class="d-flex">
              <v-text-field v-model="newInterest.title" ref="input" placeholder="Nouveau hobby" clearable dense hide-details></v-text-field>
              <v-text-field v-model="newInterest.description" placeholder="Description supplémentaire" class="ml-4" clearable dense hide-details></v-text-field>
              <v-btn type="submit" :disabled="!newInterest.title" depressed small class="ml-4 secondary j-btn">ajouter</v-btn>
            </v-form>
            <div class="d-flex mt-4" v-for="(interest, index) in items" :key="index">
              <v-text-field v-model="items[index].title" dense hide-details></v-text-field>
              <v-text-field v-model="items[index].description" class="ml-4" dense hide-details>
                <template v-slot:append-outer>
                  <v-icon @click="removeItem(index)" color="alert">mdi-delete</v-icon>
                </template>
              </v-text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-actions white rounded-b-lg pb-2 d-flex flex-column">
      <v-divider></v-divider>
      <v-btn @click="$emit('close', true)" depressed class="red-gradient white_ j-btn">valider</v-btn>
      <v-btn text @click="$emit('close', false)" class="mt-2 j-btn grey--text">annuler</v-btn>
    </div>   
  </div>   
</div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  props: ['resumeEdit', 'lang', 'section', 'title'],
  name: 'resume-form-list',
  components: {
    draggable
  },
  data() {
    return {
      carouselIndex: 0,
      newLanguage: {
        title: '',
        level: ''
      },
      newInterest: {
        title: '',
        description: ''
      }
    }
  },
  methods: {
    shortenItem(stringItem){
      return stringItem.length > 10 ? stringItem.slice(0,10)+"..." : stringItem
    },
    pushItem() {
      if(this.isLanguages) {
        this.resumeEdit.languages.push(this.newLanguage)
        this.newLanguage = {title: '', level: ''}
        this.$refs.input.focus()
      }
      if(this.isInterests) {
        this.resumeEdit.interests.push(this.newInterest)
        this.newInterest = {title: '', description: ''}
        this.$refs.input.focus()
      }
    },
    removeItem(index) {
      if(!this.isComputings || this.items.length > 1) this.items.splice(index, 1)
    },
  },
  computed: {
    isLanguages() {
      return this.section === 'languages'
    },
    isInterests() {
      return this.section === 'interests'
    },
    items() {
      return this.resumeEdit[this.section]
    },
    helper() {
      let title, video, content
      if(this.isLanguages) {
        title = 'Comment mettre en avant vos langues ?'
        content = ['<h6 class="font-weight-bold text-grey">Langues :</h6><li>Déterminez les langues à inclure : Avant de commencer à énumérer toutes les langues que vous connaissez, il est important de déterminer celles qui sont pertinentes pour le poste que vous visez.</li><li>Évaluez votre niveau de compétence : Lorsque vous incluez une langue sur votre CV, il est important de préciser votre niveau de compétence. Les niveaux de compétence peuvent varier en fonction des normes de chaque pays, mais en général, il existe 4 niveaux de compétence : débutant, intermédiaire, avancé et langue maternelle.</li><br><h6 class="font-weight-bold text-grey">Voici quelques exemples :</h6><li>Débutant : notions, niveau A1</li><li>Intermédiaire : niveau A2/B1</li><li>Avancé : niveau B2/C1</li><li>Langue maternelle : langue maternelle, niveau C2</li><p>Soyez honnête : Il est important d’être honnête quant à votre niveau de compétence en langue. Si vous surestimez votre niveau de compétence, cela pourrait se retourner contre vous lors d’un entretien d’embauche ou dans votre travail.</p>']
      }
      else if(this.isInterests) {
        title = 'Comment parler de vos hobbies ?'
        video = 'https://player.vimeo.com/video/775855154#t=2m17s'
        content = ['<h6 class="font-weight-bold text-grey">Hobbies :</h6><li>Cette partie va permettre au recruteur d’en connaître davantage sur vous (passions, personnalité, goûts).</li><li>Cette rubrique n’est pas obligatoire, mais nous la conseillons.</li><li>Lorsque vous avez peu d’expériences professionnelles, nous vous conseillons de développer davantage cette rubrique. Elle vous permettra de mettre en avant vos softs skills et vos qualités.</li><li>Cette rubrique peut vous permettre de vous démarquer des autres candidats, il ne faut donc pas la négliger.</li><li>Indiquez vos voyages marquants, vos activités sportives, associatives, bénévolat, vos passions, vos passe-temps, ...</li><li>Indiquez des sports ou des activités que vous pratiquez régulièrement. Ne pas indiquer un sport que vous ne faites plus depuis plusieurs années.</li><li>Les activités choisies peuvent mettre en avant vos compétences.</li><div class="caption"><p class="mt-2 mb-0">Exemples :</p><li>capitaine d’une équipe de sport : leader / management</li><li>sport collectif : esprit d’équipe / entraide / écoute</li><li>sport individuel : ténacité / perfectionniste / autonomie</li><li>bénévolat : bon relationnel, dévouement, aide , état d’esprit</li><li>instrument : rigueur, ténacité, créativité, perfectionnisme</li><li>cuisine : créativité, organisation</li><li>voyages : ouverture d’esprit, curiosité, adaptabilité</li><li>Peut vous permettre de parler d’autres choses que du domaine professionnel (ex : votre dernier voyage marquant).</li><li>Soyez prêt à parler lors de l’entretien des hobbies indiqués sur le CV.</li></div>']
      }
      return {title, video, content}
    }
  }
}
</script>

<style lang="scss">
.list-form {
  height: 100%!important;
  padding-right: 16px;
  overflow-y: scroll;
  .languages-section .interests-section {
    min-height: 20vh;
  }
  .icon-img {
    height: 2rem;
  }
  .v-slide-group__prev, .v-slide-group__prev--disabled, .v-slide-group__next, .v-slide-group__next--disabled {
    min-width: 0;
    .v-icon {
      font-size: 36px;
    }
  }
}
</style>